import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Link from '@ttg-media/components/build/Link';
import Pager from '@ttg-media/components/build/Pager';
import Container from '@material-ui/core/Container';
import gql from 'graphql-tag';
import Box from '@material-ui/core/Box';
import Title from '@ttg-media/components/build/Title';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import { RequireAdmin } from '@ttg-media/components/build/auth';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import { useQuery } from '@apollo/client';
import HandleError from '@ttg-media/components/build/HandleError';
import LazyLoad from 'react-lazyload';
import { authorDetailsUrl, authorListUrl } from '../../urls';

import AuthorSearch from './AuthorSearch';
import HotAuthors from '../home/HotAuthors';
import ButtonDeleteAuthor from './ButtonDeleteAuthor';
import ButtonTransferAuthor from './ButtonTransferAuthor';
import PageHead from '../../components/PageHead';
import useQueryString from '../list/useQueryString';

const FETCH_AUTHORS = gql`
  query FetchAuthors($page: Int) {
    authors(page: $page) {
      total
      items {
        id
        lid
        slug
        name
        otherName
        mangasCount
        covers
      }
    }
  }
`;
function AuthorList({ data }) {
  const { items: authors } = data.authors;
  return (
    <List dense>
      {authors.map((author) => (
        <ListItem
          button
          key={author.slug}
          component={Link}
          to={authorDetailsUrl({ id: author.id })}
        >
          <ListItemAvatar>
            {author.covers.length > 0 && (
              <Avatar src={author.covers[0]} variant="rounded" />
            )}
          </ListItemAvatar>
          <ListItemText
            primary={author.name}
            secondary={<small>{author.mangasCount} truyện</small>}
          />
          <RequireAdmin>
            <ListItemSecondaryAction>
              <ButtonDeleteAuthor author={author} />
              <ButtonTransferAuthor author={author} />
            </ListItemSecondaryAction>
          </RequireAdmin>
        </ListItem>
      ))}
    </List>
  );
}
function AuthorListPageComponent() {
  const { page } = useQueryString();
  const { data, loading, error } = useQuery(FETCH_AUTHORS, {
    variables: { page },
  });
  const homePage = page === 1;
  if (loading || error) return <HandleError loading={loading} error={error} />;
  return (
    <Container>
      <PageHead title="Danh sách tác giả" />
      {homePage && (
        <LazyLoad>
          <Box mb={2}>
            <Title>Tác giả nổi bật</Title>
            <HotAuthors />
          </Box>
        </LazyLoad>
      )}
      <Box mb={2}>
        <AuthorSearch />
        <AuthorList data={data} />
      </Box>
      <Pager
        page={page}
        url={(p) => authorListUrl({ page: p })}
        totalPage={Math.ceil(data.authors.total / 20)}
      />
    </Container>
  );
}

export default AuthorListPageComponent;
