import gql from 'graphql-tag';

export default gql`
  query fetchPostingMangaFormData {
    languages {
      id
      slug
      name
      default
    }
    categories {
      id
      slug
      name
    }
    formats {
      id
      slug
      name
      default
    }
  }
`;
