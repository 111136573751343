import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useAuth } from '@ttg-media/components/build/auth/AuthContext';
import SimpleTable from '@ttg-media/components/build/SimpleTable';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import createApiClient from '../createApiClient';
import { managePageviews } from '../../../urls';
import UserLayout from '../../../components/Layout/UserLayout';

const columns = [
  {
    Header: 'Ngày',
    accessor: (d) => moment(d.date).format('DD/MM/yyyy'),
  },
  {
    Header: 'L.view thu nhập',
    accessor: 'pageviewMonetized',
  },
  {
    Header: 'L.view toàn bộ',
    accessor: 'pageview',
  },
];

const columnsMonthly = [
  {
    Header: 'Tháng',
    accessor: (d) => `${d.month}/${d.year}`,
  },
  {
    Header: 'L.view thu nhập',
    accessor: 'pageviewMonetized',
  },
  {
    Header: 'L.view toàn bộ',
    accessor: 'pageview',
  },
];
function Pageviews() {
  const { me } = useAuth();
  const now = new Date();
  const [period, setPeriod] = useState(
    `${now.getYear() + 1900}-${now.getMonth() + 1}`,
  );
  const [periodType, setPeriodType] = useState('daily');
  const { data, error, isLoading } = useQuery(
    ['pageview', periodType, period],
    () => {
      const [year, month] = period.split('-');
      const url =
        periodType === 'daily'
          ? `/api/pv/users/${me.lid}/daily/${month}/${year}`
          : `/api/pv/users/${me.lid}/monthly/${year}`;
      return createApiClient().get(url);
    },
  );
  const onSelectMonth = (event) => {
    setPeriod(event.target.value);
  };
  const onPeriodTypeChanged = (event) => setPeriodType(event.target.value);
  return (
    <UserLayout path={managePageviews}>
      <Box mb={1} display="flex" justifyContent="">
        <Box mr={0.5}>
          <TextField
            type="month"
            onChange={onSelectMonth}
            value={period}
            style={{ width: 150 }}
          />
        </Box>
        <Select value={periodType} onChange={onPeriodTypeChanged}>
          <MenuItem value="daily">Ngày</MenuItem>
          <MenuItem value="monthly">Tháng</MenuItem>
        </Select>
      </Box>
      <Paper>
        <SimpleTable
          columns={periodType === 'daily' ? columns : columnsMonthly}
          data={data?.data.items || []}
          error={error}
          loading={isLoading}
        />
      </Paper>
    </UserLayout>
  );
}
export default Pageviews;
