import _filter from 'lodash/filter';

export class ShortIdsFilter {
  static parse(text) {
    if (!text) return [];
    return _filter(
      text.split(',').map((s) => +s),
      (s) => s > 0,
    );
  }

  static toString(shortIds) {
    return shortIds.join(',');
  }
}
