import React from 'react';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import { RequireAdmin } from '@ttg-media/components/build/auth';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import CheckCircle from '@material-ui/icons/CheckCircle';
import RadioButtonUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import Link from '@ttg-media/components/build/Link';
import Grid from '@material-ui/core/Grid';
import ReviewMangaButton from '../actions/ButtonReview';
import ButtonUserReview from './ButtonUserReview';
import { compileUrl, mangaStatusPage } from '../../../../urls';
import { LongStoryLinks } from '../../../../modules/longStory/routes/longStoryLinks';
import TriggerMangaPostedEventButton from './TriggerMangaPostedEventButton';
import UndoReviewButton from './UndoReviewButton';
import RestoreButton from './RestoreButton';
import TaskList from './TaskList';
import EditMangaLayout from '../EditMangaLayout';
import handleAlt from '../../../../components/handleAlt';
import useMangaForEdit from '../useMangaForEdit';
import ButtonCreateLongStory from './ButtonCreateLongStory';

function Icon({ checked }) {
  return checked ? <CheckCircle color="primary" /> : <RadioButtonUnchecked />;
}
export default function MangaStatusPage() {
  const resp = useMangaForEdit();
  const alt = handleAlt(resp);
  if (alt) return alt;
  const { manga } = resp.data;
  const { canDownload } = manga;
  const downloadUrl = compileUrl('/download/:id', { id: manga.lid });
  return (
    <EditMangaLayout route={mangaStatusPage}>
      <Grid container spacing={1}>
        <Grid item md={8} sm={12} xs={12}>
          <Card>
            <List dense>
              <ListItem>
                <ListItemAvatar>
                  <Icon checked={manga.isReviewed} />
                </ListItemAvatar>
                <ListItemText
                  primary="Tình trạng duyệt"
                  secondary={
                    manga.isReviewed ? (
                      <Typography variant="caption" color="textSecondary">
                        Đã được duyệt
                      </Typography>
                    ) : (
                      <ButtonUserReview manga={manga} />
                    )
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <Icon checked={manga.version} />
                </ListItemAvatar>
                <ListItemText
                  primary="Tối ưu hoá trang truyện"
                  secondary={
                    <Typography
                      gutterBottom
                      variant="caption"
                      component="div"
                      color="textSecondary"
                    >
                      Hệ thống sẽ tự động tối ưu sau 5-10 phút
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <Icon checked={canDownload} />
                </ListItemAvatar>
                <ListItemText
                  primary="Đường dẫn tải truyện"
                  secondary={
                    <Typography variant="caption" gutterBottom component="div">
                      {canDownload ? (
                        <Link
                          component="a"
                          href={downloadUrl}
                          target="_blank"
                          size="small"
                        >
                          Tải xuống
                        </Link>
                      ) : (
                        'Chưa sẵn sàng'
                      )}
                    </Typography>
                  }
                />
              </ListItem>
              <Divider variant="fullWidth" />
            </List>
            <CardContent>
              {manga.longStory ? (
                <LongStoryLinks.EditMangas id={manga.longStory.lid}>
                  {manga.longStory.name}
                </LongStoryLinks.EditMangas>
              ) : (
                <div>
                  <LongStoryLinks.List
                    variant="link"
                    size="small"
                    color="primary"
                  >
                    Sửa truyện dài
                  </LongStoryLinks.List>
                  <ButtonCreateLongStory manga={manga} />
                </div>
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={4} sm={12} xs={12}>
          <TaskList anchor={manga.lid} />
        </Grid>
      </Grid>
      <RequireAdmin>
        <Box mt={2}>
          <ReviewMangaButton manga={manga} />{' '}
          <TriggerMangaPostedEventButton manga={manga} />{' '}
          <UndoReviewButton manga={manga} /> <RestoreButton manga={manga} />
        </Box>
      </RequireAdmin>
    </EditMangaLayout>
  );
}
