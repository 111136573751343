import React from 'react';
import AdminLayout from '../AdminLayout';
import AdminHomePage from './AdminHomePage';

export default function () {
  return (
    <AdminLayout title="Admin home">
      <AdminHomePage />
    </AdminLayout>
  );
}
