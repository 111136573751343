import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles({
  cover: {
    height: 70,
    width: '33.33%',
    paddingTop: '45.83%',
    display: 'inline-block',
  },
});

export function Covers({ covers }) {
  const css = useStyles();
  return covers.map((i) => (
    <div
      className={css.cover}
      style={{
        background: `url(${i}) no-repeat center`,
        backgroundSize: 'cover',
        borderRight: i < covers.length - 1 && '1px solid #2D2D2D',
      }}
      key={i}
    />
  ));
}
