import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { fetchMangaForEditGql } from './gql';

function useMangaForEdit(opts = {}) {
  const params = useParams();
  const id = parseInt(params.id, 10);
  return useQuery(fetchMangaForEditGql, {
    variables: { id },
    onCompleted: opts.onCompleted,
  });
}
export default useMangaForEdit;
