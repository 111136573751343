import React from 'react';
import Title from '@ttg-media/components/build/Title';
import Box from '@material-ui/core/Box';

export default function ({ title, children, right, noDivider }) {
  return (
    <Box mb={4}>
      <Title right={right} noDivider={noDivider}>
        {title}
      </Title>
      {children}
    </Box>
  );
}
