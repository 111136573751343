import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CloudUpload from '@material-ui/icons/CloudUpload';
import SearchIcon from '@material-ui/icons/Search';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Link from '@ttg-media/components/build/Link';
import { UserNavBar } from '@ttg-media/components/build/auth';
import Button from '@material-ui/core/Button';
import NotificationsCounter from '@ttg-media/notification/build/NotificationsCounter';
import Forum from '@material-ui/icons/Forum';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import People from '@material-ui/icons/People';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Launch from '@material-ui/icons/Launch';
import CategoryIcon from '@material-ui/icons/Category';
import AddIcon from '@material-ui/icons/Apps';
import AuthorIcon from '@material-ui/icons/AssignmentInd';
import TagIcon from '@material-ui/icons/LocalOffer';
import TranslatorIcon from '@material-ui/icons/SupervisedUserCircle';
import ContactSupport from '@material-ui/icons/ContactSupport';
import HotIcon from '@material-ui/icons/Whatshot';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import Email from '@material-ui/icons/Email';
import ExpandLess from '@material-ui/icons/ExpandLess';
import Facebook from '@material-ui/icons/Facebook';
import Share from '@material-ui/icons/Share';
import Twitter from '@material-ui/icons/Twitter';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ListSubheader from '@material-ui/core/ListSubheader';
import {
  CategorySlugs,
  authorListUrl,
  compileUrl,
  datingWebsite,
  mangaListUrl,
  postMangaPage,
  searchPage,
  tagListUrl,
  miscUrls,
  translatorRoute,
  categoryPageUrl,
} from '../../../urls';
import AdminMenu from './AdminMenu';
import { UserLinks } from './headerLinks';
import SearchField from '../../../pages/home/SearchField';

const css = makeStyles((theme) => ({
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));
function NavMenu({ mainIcon, label, items, variant = 'button', open }) {
  const s = css();
  const [anchorEl, setAnchorEl] = React.useState(open);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const handleToggle = (event) => {
    if (anchorEl) setAnchorEl(null);
    else setAnchorEl(event.currentTarget);
  };
  const isOpen = Boolean(anchorEl);
  switch (variant) {
    case 'button':
      return (
        <>
          <Button
            startIcon={mainIcon}
            onClick={handleClick}
            endIcon={<ExpandMore />}
          >
            {label}
          </Button>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {items.map((item) => (
              <MenuItem
                onClick={handleClose}
                component={item.component || Link}
                href={item.to}
                key={item.label}
                target="_blank"
                rel="noopener noreferrer"
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                {item.label}
              </MenuItem>
            ))}
          </Menu>
        </>
      );
    case 'drawer': {
      return (
        <>
          <ListItem onClick={handleToggle} button>
            <ListItemIcon>{mainIcon}</ListItemIcon>
            <ListItemText primary={label} />
            {isOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={isOpen} timeout="auto" unmountOnExit>
            <List dense className={s.nested}>
              {items.map((item) => (
                <ListItem
                  href={item.to}
                  to={item.to}
                  component={item.component || Link}
                  button
                >
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.label} />
                </ListItem>
              ))}
            </List>
          </Collapse>
        </>
      );
    }
    case 'list':
      return (
        <List
          component="nav"
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              {label}
            </ListSubheader>
          }
        >
          {items.map((item) => (
            <ListItem
              href={item.to}
              to={item.to}
              component={item.component || Link}
              button
              key={item.label}
            >
              {item.label}
            </ListItem>
          ))}
        </List>
      );
    default:
      throw new Error(`Variant ${variant} is not supported!`);
  }
}
export function OtherSites({ variant, open }) {
  const items = [
    {
      to: datingWebsite.homePage,
      label: 'Trang Kết bạn',
      component: 'a',
      icon: <People />,
    },
    {
      to: 'https://discord.gg/zxJ5RG4yZ4',
      label: 'Chatroom',
      component: 'a',
      icon: <Forum />,
    },
    {
      to: compileUrl(miscUrls.contactPage),
      label: 'Trợ giúp',
      component: 'a',
      icon: <ContactSupport />,
    },
  ];
  return (
    <NavMenu
      mainIcon={<Launch />}
      label="Liên kết"
      items={items}
      variant={variant}
      open={open}
    />
  );
}

export function Legals({ variant }) {
  const items = [
    {
      to: compileUrl(miscUrls.termOfService),
      label: 'Terms of service',
      component: 'a',
    },
    {
      to: compileUrl(miscUrls.privacyPolicy),
      label: 'Privacy Policy',
      component: 'a',
    },
    {
      to: compileUrl(miscUrls.dcmaNotice),
      label: 'DCMA Notice',
      component: 'a',
    },
  ];
  return (
    <NavMenu
      mainIcon={<Share />}
      label="Điều khoản"
      items={items}
      variant={variant}
    />
  );
}
export function Socials({ variant }) {
  const items = [
    {
      to: 'https://www.facebook.com/yeutruyentranhgay',
      label: 'Facebook',
      component: 'a',
      icon: <Facebook />,
    },
    {
      to: 'https://twitter.com/truyentranhgay',
      label: 'Twitter',
      icon: <Twitter />,
    },
    {
      to: 'mailto:admin@truyentranhgay.com',
      label: 'Email',
      component: 'a',
      icon: <Email />,
    },
  ];
  return (
    <NavMenu
      mainIcon={<Share />}
      label="Mạng xã hội"
      items={items}
      variant={variant}
    />
  );
}

export function Categories({ variant }) {
  const items = [
    {
      to: mangaListUrl({ category: 'bara' }),
      label: 'Bara',
    },
    {
      to: mangaListUrl({ category: 'yaoi' }),
      label: 'Yaoi',
    },
    {
      to: mangaListUrl({ category: 'furry' }),
      label: 'Furry',
    },
    {
      to: categoryPageUrl({ slug: CategorySlugs.truyenTranhDamMy }),
      label: 'Truyện tranh Đam mỹ',
    },
  ];
  return (
    <NavMenu
      mainIcon={<CategoryIcon />}
      label="Phân loại"
      items={items}
      variant={variant}
    />
  );
}

export function BrowseMenu({ variant, open }) {
  const items = [
    {
      to: mangaListUrl({ sort: 'hot' }),
      label: 'Truyện Hot',
      icon: <HotIcon />,
    },
    {
      to: tagListUrl(),
      label: 'Tag',
      icon: <TagIcon />,
    },
    {
      to: authorListUrl(),
      label: 'Tác giả',
      icon: <AuthorIcon />,
    },
    {
      to: compileUrl(translatorRoute.list),
      label: 'Nhóm dịch',
      icon: <TranslatorIcon />,
    },
    {
      to: compileUrl(searchPage),
      label: 'Tìm kiếm',
      icon: <SearchIcon />,
    },
  ];
  return (
    <NavMenu
      mainIcon={<AddIcon />}
      items={items}
      label="Duyệt theo"
      open={open}
      variant={variant}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flex: 1,
    display: 'block',
  },
  menuMobile: {
    display: 'block',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

function SideMenu() {
  const [anchor, setAnchor] = React.useState(null);

  const toggleDrawer = (open) => (event) =>
    setAnchor(open ? event.currentTarget : null);
  return (
    <>
      <IconButton onClick={toggleDrawer(true)} aria-label="Menu">
        <MenuIcon />
      </IconButton>
      <Drawer
        anchor="left"
        open={Boolean(anchor)}
        onClose={toggleDrawer(false)}
      >
        <List>
          <ListItem>
            <Button
              color="primary"
              style={{ color: '#000' }}
              variant="contained"
              to={compileUrl(postMangaPage)}
              component={Link}
              startIcon={<CloudUpload />}
              fullWidth
            >
              <Typography noWrap>Đăng truyện</Typography>
            </Button>
          </ListItem>
          <Divider />
          <Categories variant="drawer" />
          <BrowseMenu variant="drawer" open />
          <OtherSites variant="drawer" open />
          <Socials variant="drawer" />
        </List>
      </Drawer>
    </>
  );
}
function HeaderComponent() {
  const classes = useStyles();
  return (
    <div>
      <AppBar
        position="static"
        style={{ backgroundColor: '#2D2D2D', color: '#ddc' }}
      >
        <Toolbar style={{ padding: 0 }}>
          <SideMenu />
          <Typography variant="h5" className={classes.title} noWrap>
            <Link to="/">Truyện Tranh Gay</Link>
          </Typography>
          <SearchField />

          <div className={classes.menuMobile}>
            <OtherSites buttonClassName={classes.menuButton} />
            <Button
              color="primary"
              variant="contained"
              to={compileUrl(postMangaPage)}
              component={Link}
              startIcon={<CloudUpload style={{ color: '#333333' }} />}
              className={classes.menuButton}
            >
              <Typography style={{ color: '#333333' }}>Đăng truyện</Typography>
            </Button>
            <Categories buttonClassName={classes.menuButton} />
            <BrowseMenu buttonClassName={classes.menuButton} />
          </div>
          <UserNavBar
            before={NotificationsCounter}
            menuItems={(user) => <UserLinks user={user} />}
            adminMenuItems={AdminMenu}
          />
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default HeaderComponent;
