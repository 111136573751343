// @flow
import React from 'react';
import { useMutation } from '@apollo/client';
import { ButtonAction } from '../../../../components/ButtonAction';
import { UndoReviewManga } from '../gql';

export default function (props) {
  const mutation = useMutation(UndoReviewManga, {
    variables: { manga: props.manga.id },
  });
  return (
    <ButtonAction mutation={mutation}>
      Đặt lại trạng thái chờ duyệt
    </ButtonAction>
  );
}
