import React from 'react';
import Grid from '@material-ui/core/Grid';
import { AdminMangaItem } from './AdminMangaItem';

export default function AdminMangaList(props) {
  const { mangas } = props;
  return (
    <Grid container spacing={1}>
      {mangas.map((manga) => (
        <Grid item xs={12} sm={6} md={3} lg={3} key={manga.id}>
          <AdminMangaItem manga={manga} />
        </Grid>
      ))}
    </Grid>
  );
}
