import React from 'react';
import { useParams } from 'react-router-dom';
import MangaDetailsPage from '../../../manga/MangaDetailsPage';
import EditMangaLayout from '../EditMangaLayout';
import { mangaPreview } from '../../../../urls';

export default function () {
  const params = useParams();
  const id = parseInt(params.id, 10);
  return (
    <EditMangaLayout route={mangaPreview}>
      <MangaDetailsPage preview id={id} />
    </EditMangaLayout>
  );
}
