import axios from 'axios';

const createApiClient = () =>
  axios.create({
    baseURL: typeof window === 'undefined' ? null : window.env.restApiUrl,
    headers: {
      'Content-Type': 'application/json',
    },
  });
export default createApiClient;
