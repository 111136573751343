import React, { useState } from 'react';
import { compose } from 'redux';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import CardActions from '@material-ui/core/CardActions';
import withNotify from '@ttg-media/components/build/hoc/withNotify';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import StepUpload, { convertFileToGqlInput } from '../../post-manga/StepUpload';
import EditMangaLayout from '../EditMangaLayout';
import { mangaAddPage } from '../../../../urls';

const addPageMutation = gql`
  mutation addMangaPage($mangaId: Int, $images: [ImageInput]!) {
    addMangaPage(mangaId: $mangaId, images: $images) {
      id
    }
  }
`;

function AddMangaPageComponent(props) {
  const [images, setImages] = useState([]);
  const params = useParams();
  const id = parseInt(params.id, 10);

  const [mutation] = useMutation(addPageMutation);
  let newImages = [];
  const save = () => {
    props.exec(
      mutation({
        variables: {
          mangaId: id,
          images: images.map(convertFileToGqlInput),
        },
      }),
    );
  };

  const onFinish = (file) => {
    newImages = [...newImages, file];
    setImages([...images, ...newImages]);
  };

  return (
    <EditMangaLayout route={mangaAddPage}>
      <Card>
        <CardHeader title="Thêm trang truyện" />
        <CardContent>
          <StepUpload onFinish={onFinish} />
        </CardContent>
        <CardActions>
          <Button variant="outlined" onClick={save}>
            Thêm trang truyện ({images.length})
          </Button>
        </CardActions>
      </Card>
    </EditMangaLayout>
  );
}

export default compose(withNotify())(AddMangaPageComponent);
