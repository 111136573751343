import Async from 'react-select/async';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import _debounce from 'lodash/debounce';
import { ga4SendEvent } from '@ttg-media/components/build/analyticsHelpers';
import { mangaListUrl } from '../../urls';

function AuthorSearchItem({ author }) {
  return (
    <div>
      {author.value.name}
      <small className="badge badge-default ml-2">
        {author.value.mangasCount} truyện
      </small>
    </div>
  );
}

const searchAuthorElastic = (query) => {
  ga4SendEvent('search', {
    search_term: query,
  });
  // eslint-disable-next-line no-undef
  return axios(`${env.searchUrl}/api/search/ttg_authors`, {
    method: 'post',
    data: {
      query: {
        bool: {
          must: [
            {
              wildcard: { name: `${query}*` },
            },
          ],
        },
      },
      sort: [{ mangasCount: { order: 'desc' } }],
    },
  }).then((resp) => {
    if (resp.status === 200)
      return resp.data.hits.hits.map(({ _source: author }) => ({
        value: author.shortId,
        id: author.shortId,
        label: `${author.name} (${author.mangasCount} manga)`,
        author,
      }));
    return [];
  });
};
function AuthorSearch() {
  const navigate = useNavigate();
  const handleChange = (selection) =>
    navigate(mangaListUrl({ author: selection.id }));
  return (
    <Async
      placeholder="Tìm kiếm tác giả 🔍"
      loadOptions={useCallback(_debounce(searchAuthorElastic, 100), [])}
      onChange={handleChange}
      optionRenderer={(a) => <AuthorSearchItem author={a} />}
      styles={{
        option: (styles) => ({
          ...styles,
          color: '#000',
        }),
      }}
    />
  );
}

export default AuthorSearch;
