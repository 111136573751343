import Async from 'react-select/async';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { searchTags } from '../user/post-manga/MangaForm/actions';
import { mangaListUrl } from '../../urls';

function SearchBox(props) {
  const [value, setValue] = useState();
  const navigate = useNavigate();
  const onChange = (selection) => {
    setValue(selection);
    if (props.onChange) {
      props.onChange(selection);
    } else {
      navigate(
        mangaListUrl({
          tag: selection.value,
        }),
      );
    }
  };

  return (
    <div style={{ minWidth: 100 }}>
      <Async
        placeholder="Tìm kiếm tag 🔍"
        cacheOptions
        loadOptions={searchTags}
        autoload={false}
        value={value}
        onChange={onChange}
        styles={{
          option: (styles) => ({
            ...styles,
            color: '#000',
          }),
        }}
      />
    </div>
  );
}

export default SearchBox;
