import React from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import HandleError from '@ttg-media/components/build/HandleError';
import ErrorBoundary from '@ttg-media/components/build/hoc/SentryBoundary';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Title from '@ttg-media/components/build/Title';
import { MangaParts } from '../../../gql/fragments';
import { mangaListUrl } from '../../../urls';
import MangaListGroup from '../../../components/MangaListGroup/MangaListGroup';

const GET_AUTHOR_RECOMMENDATIONS = gql`
  query authorRecommendations($author: ID!) {
    authorRecommendations(author: $author) {
      items {
        ...MangaParts
      }
    }
  }
  ${MangaParts}
`;
export default function ({ author }) {
  const { loading, data, error } = useQuery(GET_AUTHOR_RECOMMENDATIONS, {
    variables: { author: author.lid },
  });
  if (loading || error) return <HandleError loading={loading} error={error} />;
  return (
    <div>
      <Box mb={2}>
        <Title>
          Truyện cùng tác giả{' '}
          <Link href={mangaListUrl({ author: author.id })}>{author.name}</Link>
        </Title>
      </Box>
      <ErrorBoundary>
        <MangaListGroup
          mangaList={{ mangas: data.authorRecommendations.items }}
          utmSource="author-reco"
          utmListing={{
            total_items: data.authorRecommendations.items.length,
            total_pages: 1,
            current_page: 1,
            offset: 1,
            listing_id: `author-reco-${author.id}`,
            listing_type: 'author-reco',
            listing_item_id: author.id,
          }}
        />
      </ErrorBoundary>
    </div>
  );
}
