import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';
import AlertTitle from '@material-ui/lab/AlertTitle';
import React from 'react';
import Link from '@ttg-media/components/build/Link';
import { compileUrl, searchPage } from '../../../urls';

export function Empty() {
  return (
    <Container>
      <Box py={2}>
        <Alert
          severity="warning"
          action={
            <Button
              startIcon={<SearchIcon />}
              component={Link}
              to={compileUrl(searchPage)}
            >
              Tìm truyện
            </Button>
          }
        >
          <AlertTitle>Lỗi trang truyện</AlertTitle>
          Truyện không có trang tryện nào. Bạn hãy tìm truyện khác để xem nhé :(
        </Alert>
      </Box>
    </Container>
  );
}
