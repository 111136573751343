import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import _find from 'lodash/find';

const useStyles = makeStyles({
  image: {
    maxWidth: '100%',
  },
});
export default function ({ title, page, onLoad, onError }) {
  const css = useStyles();
  const alt = `${title} - Trang ${page.order + 1}`;
  const src = _find(page.srcSet, (s) => s.type === 'image/jpeg');
  return (
    <picture>
      {page.srcSet.map(
        (set) => set.type && <source type={set.type} srcSet={set.srcSet} />,
      )}
      <img
        src={src?.srcSet || page.src}
        alt={alt}
        className={css.image}
        onLoad={onLoad}
        onError={onError}
        loading="lazy"
      />
    </picture>
  );
}
