import Button from '@material-ui/core/Button';
import Edit from '@material-ui/icons/Edit';
import React from 'react';
import { useAuth } from '@ttg-media/components/build/auth';
import Link from '@ttg-media/components/build/Link';
import { compileUrl } from '../../../urls';
import longStoryRoute from './longStoryUrls';

export const LongStoryLinks = {
  List: (props) => {
    const { variant, children } = props;
    const { me } = useAuth();
    if (!me) return <Button disabled>Truyện dài kỳ</Button>;
    const url = compileUrl(longStoryRoute.list, { page: 1, user: me.id });
    if (variant === 'link') {
      return (
        <Link to={url} {...props}>
          {children}
        </Link>
      );
    }
    return (
      <Button component={Link} to={url} {...props}>
        {children || 'Truyện dài kỳ'}
      </Button>
    );
  },

  Create: (props) => (
    <Button
      component={Link}
      to={compileUrl(longStoryRoute.create)}
      {...props}
      variant="outlined"
      id="create-long-story"
    >
      {props.children || 'Thêm'}
    </Button>
  ),
  Edit: (props) => {
    const { id } = props;
    return (
      <Button
        component={Link}
        to={compileUrl(longStoryRoute.edit, { id })}
        endIcon={<Edit />}
        {...props}
      >
        {props.children || 'Sửa'}
      </Button>
    );
  },
  EditMangas: (props) => {
    const { id } = props;
    return (
      <Button
        component={Link}
        to={compileUrl(longStoryRoute.editMangaOrder, { id })}
        endIcon={<Edit />}
        {...props}
      >
        {props.children || 'Sửa truyện'}
      </Button>
    );
  },
};
