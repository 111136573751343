import React from 'react';
import Divider from '@material-ui/core/Divider';
import MenuItem from '@material-ui/core/MenuItem';
import Link from '@ttg-media/components/build/Link';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { admin, compileUrl, manageAdminMangaFilters } from '../../../urls';

function AdminMenu() {
  return (
    <>
      <Divider />
      <MenuItem component={Link} to={compileUrl(admin.home)}>
        <ListItemIcon />
        Trang chủ Admin
      </MenuItem>
      <MenuItem
        component={Link}
        to={compileUrl(admin.manageManga, {
          filter: manageAdminMangaFilters.unreview,
        })}
      >
        <ListItemIcon />
        Duyệt truyện tranh
      </MenuItem>
    </>
  );
}

export default AdminMenu;
