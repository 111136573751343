import axios from 'axios';

export const getAnonymousId = () => {
  const userId = localStorage.getItem('ttg:auth:anonymousId');
  if (!userId) {
    const newUserId = Math.random().toString(16).slice(2);
    localStorage.setItem('ttg:auth:anonymousId', newUserId);
    return newUserId;
  }
  return userId;
};
export const sendCustomAnalyticsEvent = (eventName, payload) => {
  const userId = getAnonymousId();
  axios({
    method: 'POST',
    // eslint-disable-next-line no-undef
    url: `${env.logEndpoint}/${eventName}`,
    data: { ...payload, user_id: userId },
  });
};

export const FluentdEvents = {
  MANGA_LISTING: 'manga_listing.log',
};
