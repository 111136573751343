import React from 'react';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import { useAuth } from '@ttg-media/components/build/auth';
import CardHeader from '@material-ui/core/CardHeader';
import Container from '@material-ui/core/Container';
import UserTabs from './UserTabs';

export default function (props) {
  const { me } = useAuth();
  return me ? (
    <Container maxWidth="md">
      <Box mb={2}>
        <Card>
          <CardHeader title={me.displayName} subheader={`@${me.userName}`} />
          <UserTabs value={props.path} />
        </Card>
      </Box>
      <Box>{props.children}</Box>
    </Container>
  ) : (
    <Container maxWidth="md" />
  );
}
