import gql from 'graphql-tag';

export const DELETE_AUTHORS = gql`
  mutation DeleteAuthor($authors: [ID!]) {
    deleteAuthors(authors: $authors)
  }
`;

export const TRANSFER_AUTHOR = gql`
  mutation TransferAuthor($fromAuthors: [Int!], $toAuthors: [Int!]) {
    transferAuthor(fromAuthors: $fromAuthors, toAuthors: $toAuthors)
  }
`;
