// @flow
import React from 'react';
import LinkTg from '@ttg-media/components/build/Link';
import Link from '@material-ui/core/Link';
import { mangaListUrl } from '../../urls';

export default function render(props) {
  const { user } = props;
  return user ? (
    <Link component={LinkTg} to={mangaListUrl({ userName: user.userName })}>
      {/* <span> */}
      {/*  <Avatar */}
      {/*    src={ */}
      {/*      user.avatar?.src || */}
      {/*      `//robohash.org/${user.userName}.png?size=25x25&bgset=bg1` */}
      {/*    } */}
      {/*  />{' '} */}
      {/* </span> */}
      {user.displayName}
    </Link>
  ) : (
    <span />
  );
}
