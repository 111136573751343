const requiredMessage = 'Trường bắt buộc';
export const validate = (values) => {
  const errors = {};
  if (!values.title) {
    errors.title = requiredMessage;
  }
  if (!values.language) {
    errors.language = requiredMessage;
  }
  if (!values.language) {
    errors.language = requiredMessage;
  }
  if (!values.cover) {
    errors.cover = requiredMessage;
  }
  if (!values.images || values.images.length === 0) {
    errors.images = 'Truyên cần tối thiểu 1 trang truyện';
  }
  if (values.format === 'dai-ky-nhieu-chuong') {
    if (!values.longStory) {
      errors.longStory = requiredMessage;
    }
    if (!values.chapter) {
      errors.chapter = requiredMessage;
    }
  }
  return errors;
};

export const warn = (values) => {
  const warns = {};
  if (!values.authors) {
    warns.authors = 'Tác giả trống, thời gian review sẽ lâu hơn bình thường';
  }
  if (!values.tags) {
    warns.tags =
      'Tag trống, thời gian review sẽ lâu hơn bình thường. Truyện được tag đúng sẽ có nhiều lượt xem hơn';
  }
  return warns;
};
