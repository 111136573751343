
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Privacy policy`}</h1>
    <p>{`By accessing the website (“Site”) you accept the following Privacy Policy.`}</p>
    <ul>
      <li parentName="ul">{`The Site will not disclose the IP address or email address of any user except to the staff.`}</li>
      <li parentName="ul">{`The Site is allowed to make public everything else, including but not limited to: uploaded files, bookmarked posts, comments, and any edits made to the site.`}</li>
      <li parentName="ul">{`The Site does not sell, trade, store or collect any personal data via app , including but not limited to: Facebook, Twitter, Tumblr.`}</li>
    </ul>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;