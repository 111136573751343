import Container from '@material-ui/core/Container';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import React, { useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Pager from '@ttg-media/components/build/Pager';
import LongStoryListGroup from '../../../components/MangaListGroup/LongStoryListGroup';
import { PAGE_SIZE } from '../../../constants';
import {
  FluentdEvents,
  sendCustomAnalyticsEvent,
} from '../../../components/sendCustomAnalyticsEvent';

export default function (props) {
  const {
    page,
    data: { longStories },
    noFollow,
    utmSource,
    utmListing,
  } = props;
  useEffect(() => {
    if (utmListing) {
      const listingIds = utmListing.listing_id.split(',');
      listingIds.forEach((listingId) => {
        sendCustomAnalyticsEvent(FluentdEvents.MANGA_LISTING, {
          ...utmListing,
          experiment_id: utmListing.experiment_id,
          variant: utmListing.variant,
          listing_id: listingId,
        });
      });
    }
  }, [utmListing]);
  if (longStories.total === 0) {
    return (
      <Container maxWidth="sm">
        <Card>
          <CardContent>
            <Typography>Không tìm thấy truyện nào</Typography>
          </CardContent>
        </Card>
      </Container>
    );
  }
  return (
    <Container>
      <LongStoryListGroup
        items={longStories.items}
        utmSource={utmSource}
        utmListing={utmListing}
      />
      <Box my={2}>
        <Pager
          noFollow={noFollow}
          page={page}
          url={props.url}
          totalPage={Math.ceil(longStories.total / PAGE_SIZE)}
        />
      </Box>
    </Container>
  );
}
