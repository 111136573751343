import React, { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Fullscreen from '@material-ui/icons/Fullscreen';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

export function ImagePreview({ image }) {
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  return (
    <>
      <IconButton onClick={() => setOpen(true)} size="small">
        <Fullscreen />
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <img src={image.src} alt="" style={{ maxWidth: '100%' }} />
        </DialogContent>
      </Dialog>
    </>
  );
}
