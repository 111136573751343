import gql from 'graphql-tag';

const LongStoryPartsDisplay = gql`
  fragment LongStoryDisplay on LongStory {
    id
    title
    slug
    mangasCount
    updatedDate
    mangas {
      id
      slug
      title
      collectionOrder
      updatedDate
    }
  }
`;
const LongStoryParts = gql`
  fragment LongStoryFragments on LongStory {
    id
    lid
    title
    slug
    otherTitles
    displayName
    description
    source
    mangasCount

    mangas {
      id
      title
      slug
      chapter
      isDeleted
      isReviewed
      canDownload
      isNew
      pages {
        id
      }
      cover {
        src
      }
    }
    category {
      id
      name
      slug
    }
    language {
      id
      name
      slug
    }
    translator {
      id
      name
    }
    category {
      id
      name
      slug
    }
    tags {
      id
      name
    }
    authors {
      id
      name
    }
    updatedDate
    createdBy {
      id
      displayName
    }
    isDeleted
    isReviewed
    reviewedDate
    updatedDate
    createdDate
  }
`;

export const GET_LONG_STORIES = gql`
  query GetLongStories($user: ID!, $query: MangaFilter) {
    longStories: userLongStories(user: $user, query: $query) {
      total
      items {
        ...LongStoryFragments
      }
    }
  }
  ${LongStoryParts}
`;

export const GET_LONG_STORY_FOR_EDIT = gql`
  query GetLongStoryForEdit($id: ID!) {
    longStory: longStoryForEdit(id: $id) {
      ...LongStoryFragments
    }
  }
  ${LongStoryParts}
`;
export const UPDATE_LONG_STORY = gql`
  mutation UpdateLongStory($id: ID!, $longStory: LongStoryParams!) {
    longStory: editLongStory(id: $id, longStory: $longStory) {
      ...LongStoryFragments
    }
  }
  ${LongStoryParts}
`;

export const UPDATE_MANGA_ORDERS = gql`
  mutation UpdateMangasOrder($id: ID!, $mangas: [ID]!) {
    updateMangaOrder(id: $id, mangas: $mangas) {
      id
    }
  }
`;

export const GET_HOME_LONG_STORIES = gql`
  query LongStories($page: Int!) {
    longStories(page: $page) {
      total
      items {
        ...LongStoryPartsDisplay
      }
    }
  }
  ${LongStoryPartsDisplay}
`;
export const GET_LONG_STORY_DETAILS = gql`
  query GetLongStory($id: Int!) {
    longStory(id: $id) {
      id
      lid
      title
      slug
      description
      mangas {
        id
        slug
        title
        collectionOrder
      }
    }
  }
`;
export const GET_LONG_STORIES_FOR_HOME = gql`
  query GetLongStoriesForHome($query: MangaFilter) {
    longStories(query: $query) {
      total
      items {
        ...LongStoryFragments
      }
    }
  }
  ${LongStoryParts}
`;
export default LongStoryParts;
