import gql from 'graphql-tag';
import { LongStoryParts } from '../../gql/fragments';

export default gql`
  query GetLongStoryList($filter: MangaFilter) {
    longStories: longStoryList(filter: $filter) {
      items {
        ...LongStoryParts
      }
      total
    }
  }
  ${LongStoryParts}
`;
