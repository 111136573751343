import React from 'react';

import Pager from '@ttg-media/components/build/Pager';
import { withAuth } from '@ttg-media/components/build/auth';

import Box from '@material-ui/core/Box';
import { useQuery } from '@apollo/client';
import HandleError from '@ttg-media/components/build/HandleError';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { compileUrl, manageMyManga } from '../../../urls';
import MangaList from './AdminMangaList';
import query from './myMangaGql';

function ManageMyMangaComponent(props) {
  const { filter, page } = props;
  const { data, loading, error } = useQuery(query, {
    variables: { page, filter },
  });
  if (loading || error) return <HandleError error={error} loading={loading} />;
  const { mangas } = data.pagedMangas;
  return (
    <Card>
      <Box mb={1}>
        <MangaList mangas={mangas} />
      </Box>
      <CardContent>
        <Pager
          page={page}
          url={(p) => compileUrl(manageMyManga, { page: p })}
        />
      </CardContent>
    </Card>
  );
}

export default withAuth()(ManageMyMangaComponent);
