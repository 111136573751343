import _find from 'lodash/find';
import _sortBy from 'lodash/sortBy';
import _uniqBy from 'lodash/uniqBy';
import format from 'string-format';

export const iff = (cond, value) => (cond ? value : {});
export const idEquals = (id1, id2) => {
  if (!id1 || !id2) return false;
  return id1.toString() === id2.toString();
};

export function formatDate(dateStr) {
  const date = new Date(Number.parseInt(dateStr, 10));
  const dd = date.getDate();
  const mm = date.getMonth() + 1;
  const yyyy = date.getFullYear();
  return `${dd}/${mm}/${yyyy}`;
}

export function orderImageByFileName(images, fileNamePredicate) {
  return _sortBy(
    _uniqBy(images, (image) => fileNamePredicate(image).toLowerCase()),
    [
      (image) => fileNamePredicate(image).length,
      (image) => fileNamePredicate(image),
    ],
  );
}

export function isAdmin(user) {
  if (!user || !user.roles) return false;
  if (_find(user.roles, (r) => r === 'ADMIN')) return true;
  return false;
}
export function compileSeo(template, obj) {
  return {
    title: format(template.title, obj),
    h1: format(template.h1 || '', obj),
    description: format(template.description, obj),
    keywords: format(template.keywords, obj),
    alt: template.alt ? format(template.alt, obj) : '',
  };
}

export function uniqueId() {
  return Date.now().toString(36) + Math.random().toString(36).slice(2);
}
