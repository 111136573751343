import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles({
  img: { maxWidth: '100%', display: 'block', height: 'auto' },
});
function Cover(props) {
  const css = useStyles();
  const { manga } = props;
  return (
    <img
      src={manga.cover?.src}
      alt={manga.title}
      width="253"
      height="348"
      loading="lazy"
      className={css.img}
    />
  );
}

export default Cover;
