import React from 'react';
import { useMutation } from '@apollo/client';
import _compact from 'lodash/compact';
import { ButtonAction } from '../../../../components/ButtonAction';
import { ReviewManga } from '../gql';

export default function ReviewButtonComponent(props) {
  const mutation = useMutation(ReviewManga, {
    variables: {
      mangaIdList: _compact([props.manga?.id, ...(props.mangaIdList || [])]),
    },
  });
  return <ButtonAction mutation={mutation}>Duyệt lên trang chủ</ButtonAction>;
}
