import React from 'react';
import { Route } from 'react-router-dom';
import longStoryRoute from './longStoryUrls';
import LongStoryHome from './home';
import CreateLongStory from './create-long-story/CreateLongStory';
import EditMangaOrder from './edit-manga-order';
import EditLongStory from './edit-long-story';
import LongStoryList from './list';
import LongStoryDetailsPage from './details/LongStoryDetailsPage';
import ManageMangas from './manage-mangas';

export default [
  <Route element={<LongStoryHome />} path={longStoryRoute.home} />,
  <Route element={<CreateLongStory />} path={longStoryRoute.create} />,
  <Route element={<EditLongStory />} path={longStoryRoute.edit} />,
  <Route element={<EditMangaOrder />} path={longStoryRoute.editMangaOrder} />,
  <Route element={<ManageMangas />} path={longStoryRoute.mangas} />,
  <Route element={<LongStoryDetailsPage />} path={longStoryRoute.details} />,
  <Route element={<LongStoryList />} path={longStoryRoute.list} />,
];
