import React from 'react';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import MuiLink from '@material-ui/core/Link';
import Banners from '@ttg-media/blog/build/react/Banners';

export const SearchPageComponent = React.memo(() => {
  const GcseSearch = 'gcse:search';
  return (
    <Container maxWidth="md">
      <Box mb={2}>
        <Banners tag="search" />
      </Box>
      <Box mb={2}>
        <MuiLink
          component="a"
          href="https://cse.google.com/cse?cx=013169986422958461463:fi6ijnk9yjy"
        >
          Không hiển thị search? Mở tab mới
        </MuiLink>
      </Box>
      <script
        dangerouslySetInnerHTML={{
          __html: `
      (function() {
		    var cx = '013169986422958461463:fi6ijnk9yjy';
        var gcse = document.createElement('script');
        gcse.type = 'text/javascript';
        gcse.async = true;
        gcse.src = (document.location.protocol == 'https:' ? 'https:' : 'http:') +
            '//www.google.com/cse/cse.js?cx=' + cx;
        var s = document.getElementsByTagName('script')[0];
        s.parentNode.insertBefore(gcse, s);
      })();`,
        }}
      />
      <GcseSearch />
    </Container>
  );
});

export default SearchPageComponent;
