// @flow
import React from 'react';
import { compose } from 'redux';
import { withAuth } from '@ttg-media/components/build/auth';
import { useMutation } from '@apollo/client';
import Container from '@material-ui/core/Container';
import { useNavigate } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { convertFormValueToGqlInput } from './MangaForm/MangaForm.helpers';
import postMangaMutation from './MangaForm/gql/postMangaGql';
import { compileUrl, mangaStatusPage } from '../../../urls';
import { toastify } from '../../../components/toastify';
import CreateMangaForm from './MangaForm/MangaFormV2';

const convertToGqlInput = (file) => ({
  id: file.id || file.fileId, // "uppy-z89dzsr9x3z21/jpg-1e-image/jpeg-118091-1558754884000"
  fileName: file.fileName, // original name "z89dzsr9x3z21.jpg"
  bucket: file.bucket, // ttg-dev
  storage: file.storage, // minio
  fileKey: file.fileKey, // "1581766368145-z89dzsr9x3z21.jpg"
  baseFileName: file.baseFileName, // same as original name
  size: file.size, // 118091
  type: file.type, // "image/jpeg"
});

function PostMangaPageComponent() {
  const [postManga] = useMutation(postMangaMutation);
  const navigate = useNavigate();
  const onFormSubmit = async (formValues) => {
    const cover = formValues.images[0];
    const variables = {
      manga: convertFormValueToGqlInput(formValues),
      images: formValues.images.map(convertToGqlInput),
      cover: convertToGqlInput(cover),
    };
    return toastify(
      postManga({ variables }).then((resp) => {
        navigate(compileUrl(mangaStatusPage, { id: resp.data.manga.id }));
      }),
    );
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" gutterBottom>
        Đăng truyện mới
      </Typography>
      <CreateMangaForm onSubmit={onFormSubmit} />
    </Container>
  );
}

export default compose(withAuth())(PostMangaPageComponent);
