import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';
import _move from 'lodash-move';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import { useFormContext } from 'react-hook-form';
import IconButton from '@material-ui/core/IconButton';
import Delete from '@material-ui/icons/Delete';
import DragIndicator from '@material-ui/icons/DragIndicator';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import {
  usePopupState,
  bindTrigger,
  bindPopper,
} from 'material-ui-popup-state/hooks';
import Button from '@material-ui/core/Button';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MenuList from '@material-ui/core/MenuList';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import ButtonPreview from './ButtonPreviewV2';
import ButtonAutoSort from './ButtonAutoSortV2';
import SimpleStack from './SimpleStack';
import ButtonRemoveDuplication from './ButtonRemoveDuplication';

const DragHandle = SortableHandle(() => (
  <IconButton disableRipple size="small">
    <DragIndicator fontSize="small" />
  </IconButton>
));
function SplitButton(props) {
  const ps = usePopupState({
    id: `button-group-${props.id}`,
    variant: 'popper',
  });
  return (
    <>
      <ButtonGroup
        variant={props.variant}
        color={props.color}
        aria-label="split button"
      >
        {props.mainButton}
        <Button
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          {...bindTrigger(ps)}
          variant={props.variant}
          color={props.color}
        >
          <ArrowDropDown />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 999,
        }}
        {...bindPopper(ps)}
      >
        <Paper>
          <ClickAwayListener onClickAway={ps.close}>
            <MenuList id="split-button-menu" autoFocusItem>
              {props.items}
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Popper>
    </>
  );
}
const SortableItem = SortableElement((props) => {
  const { image } = props;
  return (
    <ListItem dense divider={!props.isLast}>
      <ListItemText>
        <SimpleStack spacing={0.5} alignItems="center">
          <IconButton onClick={props.onRemove} variant="outlined" size="small">
            <Delete fontSize="small" />
          </IconButton>
          <Avatar src={image.preview} alt="" variant="rounded" />
          <Typography variant="body2">{image.fileName}</Typography>
        </SimpleStack>
      </ListItemText>
      <ListItemSecondaryAction>
        <DragHandle />
      </ListItemSecondaryAction>
    </ListItem>
  );
});
export const SContainer = SortableContainer((props) => {
  const { items } = props;
  return (
    <List>
      {items.map((image, index) => (
        <SortableItem
          image={image}
          key={image.url}
          index={index}
          onRemove={() => props.onRemove(index)}
          isLast={index >= items.length - 1}
        />
      ))}
    </List>
  );
});

function SortableImages(props) {
  const form = useFormContext();
  const { fieldArray } = props;
  const { remove } = fieldArray;
  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const images = [...form.getValues('images')];
      const newPages = _move(images, oldIndex, newIndex);
      form.setValue('images', newPages);
    }
  };
  const uploadedImages = form.getValues('images') || [];
  return (
    <Box mt={1}>
      <Card variant="outlined">
        <CardContent>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            {props.left}
            <SimpleStack spacing={1} direction="row">
              {uploadedImages.length > 0 && (
                <Typography variant="caption">
                  {uploadedImages.length} ảnh
                </Typography>
              )}
              <SplitButton
                mainButton={<ButtonPreview disabled={!uploadedImages.length} />}
                items={[<ButtonAutoSort />, <ButtonRemoveDuplication />]}
              />
            </SimpleStack>
          </Box>
        </CardContent>
        <Divider />
        {uploadedImages.length > 0 ? (
          <SContainer
            id="sort-container"
            onSortEnd={onSortEnd}
            items={uploadedImages}
            useDragHandle
            axis="y"
            lockAxis="y"
            onRemove={(index) => remove(index)}
          />
        ) : (
          <Box
            py={2}
            alignItems="center"
            justifyContent="space-around"
            display="flex"
          >
            <Typography variant="caption">Chưa có ảnh được tải lên</Typography>
          </Box>
        )}
      </Card>
    </Box>
  );
}

export default SortableImages;
