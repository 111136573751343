import React from 'react';
import Link from '@ttg-media/components/build/Link';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import { fromNow } from '@ttg-media/components/build/utils';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { compileUrl, mangaStatusPage } from '../../urls';
import { ReviewMangaButton } from '../../components/Manga';
import MangaStats from '../../components/MangaListGroup/MangaStats';
import ButtonDeleteManga from '../user/edit-manga/actions/ButtonDeleteManga';
import { MangaStatus } from '../user/edit-manga/EditMangaLayout/MangaStatus';
import longStoryUrls from '../../modules/longStory/routes/longStoryUrls';
import ButtonCreateLongStory from '../user/edit-manga/status/ButtonCreateLongStory';

const css = makeStyles({
  media: {
    height: 348,
  },
});
export function AdminMangaItem({ manga }) {
  const editMangaUrl = compileUrl(mangaStatusPage, { id: manga.id });
  const s = css();
  return (
    <Card>
      <CardActionArea component={Link} to={editMangaUrl}>
        <CardMedia
          image={manga.cover?.src}
          title={manga.title}
          className={s.media}
        />
      </CardActionArea>
      <CardContent>
        <Typography>
          <Link to={editMangaUrl}>{manga.title}</Link>
        </Typography>
        <Typography variant="caption" color="textSecondary">
          {fromNow(manga.createdDate)}
        </Typography>
        <Typography>{manga.description}</Typography>
        <MangaStats manga={manga} />
        <MangaStatus manga={manga} />
      </CardContent>
      <CardContent>
        {manga.longStory ? (
          <>
            <Typography>
              <Link
                to={compileUrl(longStoryUrls.edit, { id: manga.longStory.lid })}
              >
                {manga.longStory.name}
              </Link>
            </Typography>
            <MangaStatus manga={manga.longStory} />
          </>
        ) : (
          <ButtonCreateLongStory manga={manga} />
        )}
      </CardContent>
      <CardActions>
        <ButtonDeleteManga manga={manga} purge />
        <ReviewMangaButton manga={manga} />
      </CardActions>
    </Card>
  );
}
