import React, { useState } from 'react';
import Pager from '@ttg-media/components/build/Pager';
import gql from 'graphql-tag';
import Box from '@material-ui/core/Box';
import { useQuery } from '@apollo/client';
import HandleError from '@ttg-media/components/build/HandleError';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { useNavigate } from 'react-router-dom';
import { admin, compileUrl } from '../../../urls';
import AdminMangaList from '../AdminMangaList';
import { MangaParts } from '../../../gql/fragments';
import { ManageManageTabs } from './ManageManageTabs';
import { ShortIdsFilter } from './ShortIdsFilter';

const query = gql`
  query mangaForAdmin($page: Int!, $filter: String, $shortIds: [Int]) {
    pagedManga: mangaForAdmin(
      page: $page
      filter: $filter
      shortIds: $shortIds
    ) {
      mangas {
        ...MangaParts
        description
        slug
        cover {
          src
        }
        isReviewed
        createdDate
      }
      count
      total
    }
  }
  ${MangaParts}
`;

export default function ManageMangaPageComponent(props) {
  const { filter, shortIds, page } = props;
  const [shortIdText, setShortIdsText] = useState(
    ShortIdsFilter.toString(shortIds),
  );
  const navigate = useNavigate();
  const { loading, error, data } = useQuery(query, {
    variables: { page, filter, shortIds },
  });
  if (loading || error) {
    return <HandleError error={error} loading={loading} />;
  }
  const { mangas, count } = data.pagedManga;
  const totalPage = Math.ceil(count / 12);

  return (
    <>
      <Box mb={2}>
        <ManageManageTabs filter={filter} />
        <Box>
          <TextField
            value={shortIdText}
            onChange={(e) => setShortIdsText(e.target.value)}
          />{' '}
          <Button
            onClick={() =>
              navigate(
                compileUrl(
                  admin.manageManga,
                  {
                    filter: 'all',
                  },
                  `?shortIds=${shortIdText}`,
                ),
              )
            }
          >
            Search
          </Button>
        </Box>
      </Box>
      <AdminMangaList mangas={mangas} />
      <Pager
        page={page}
        totalPage={totalPage}
        url={(p) => compileUrl(admin.manageManga, { page: p, filter })}
      />
    </>
  );
}
