import axios from 'axios';
import qs from 'qs';

const createStrapiBrowserClient = () => {
  if (typeof env !== 'undefined')
    return axios.create({
      // eslint-disable-next-line no-undef
      baseURL: `${env?.strapiUrl}/api`,
      paramsSerializer(params) {
        return qs.stringify(params, { arrayFormat: 'brackets' });
      },
    });
  throw new Error('Strapi client is only available in browser environment');
};
export default createStrapiBrowserClient;
