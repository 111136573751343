import React from 'react';
import Link from '@ttg-media/components/build/Link';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import withBrowser from '@ttg-media/components/build/hoc/withBrowser';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Search from '@material-ui/icons/Search';
import Box from '@material-ui/core/Box';

const FETCH_MANGA_COUNT = gql`
  query FetchMangaCount {
    counts: newMangaCount {
      translated
      raw
      cg
    }
  }
`;

function Statuses({ url, showCount }) {
  let counts;
  if (showCount) {
    const { loading, data, error } = useQuery(FETCH_MANGA_COUNT);
    counts = loading || error ? null : data.counts;
  }

  return (
    <Box mt={-5}>
      <Paper square>
        <Container>
          <Tabs variant="scrollable" scrollButtons="on">
            {[
              {
                slug: 'tieng-viet',
                label: <Typography variant="inherit">Tiếng Việt</Typography>,
                count: counts?.translated,
              },
              {
                slug: 'chua-dich',
                label: <Typography variant="inherit">Chưa dịch</Typography>,
                count: counts?.raw,
              },
              {
                slug: 'anh-le-cg',
                label: <Typography variant="inherit">Ảnh lẻ</Typography>,
                count: counts?.cg,
              },
              {
                slug: 'search',
                label: (
                  <Box display="flex" alignItems="center">
                    <Search fontSize="small" />
                    <Typography variant="inherit">Tìm kiếm</Typography>
                  </Box>
                ),
                url: '/search',
              },
              {
                slug: 'discord',
                label: (
                  <Box display="flex" alignItems="center">
                    <Box component="span" mr={0.5}>
                      <Typography variant="inherit">Chatroom</Typography>
                    </Box>
                    <img
                      src="https://img.shields.io/discord/690867637621882930?logo=discord"
                      alt=""
                    />
                  </Box>
                ),
                url: 'https://discord.gg/zxJ5RG4yZ4',
                target: '_blank',
              },
              {
                slug: 'dating',
                label: (
                  <Box display="flex" alignItems="center">
                    <Typography variant="inherit">Kết bạn</Typography>
                  </Box>
                ),
                url: 'https://discord.gg/zxJ5RG4yZ4',
                target: '_blank',
              },
            ].map((item) => (
              <Tab
                key={item.slug}
                label={item.label}
                component={Link}
                to={item.url || url(item.slug)}
                target={item.target}
              />
            ))}
          </Tabs>
        </Container>
      </Paper>
    </Box>
  );
}
export const StatusesBrowser = withBrowser()(Statuses);
export default Statuses;
