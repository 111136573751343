import { formValueSelector, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { validate, warn } from './validation';
import MangaForm from './MangaForm';

export const EDIT_MANGA_FORM = 'EDIT_MANGA_FORM';

const editMangaFormSelector = formValueSelector(EDIT_MANGA_FORM);

export const mapStateToPropsEditForm = (state) => ({
  format: editMangaFormSelector(state, 'format'),
  id: editMangaFormSelector(state, 'id'),
});

export default compose(
  connect(mapStateToPropsEditForm),
  reduxForm({
    form: EDIT_MANGA_FORM,
    validate,
    warn,
  }),
)(MangaForm);
