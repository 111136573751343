import React from 'react';
import Editor from '@ttg-media/components/build/FormControls/TextEditor';
import InputField from '@ttg-media/components/build/FormControls/InputField';
// import ImageField from '@ttg-media/components/build/FormControls/ImageField';

import { Field, reduxForm } from 'redux-form';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';

function TranslatorForm(props) {
  const { handleSubmit } = props;
  return (
    <form onSubmit={handleSubmit}>
      <Card>
        <CardContent>
          <Field
            name="name"
            type="text"
            label="Tên nhóm dịch"
            required
            component={InputField}
          />
          <Field
            name="about"
            type="text"
            label="Giới thiệu"
            component={Editor}
          />
          <Field
            name="homePage"
            type="text"
            label="Blog"
            component={InputField}
          />
          <Field
            name="facebook"
            type="text"
            label="Facebook"
            component={InputField}
          />
          <Field
            name="email"
            type="text"
            label="Email"
            component={InputField}
          />
          {/* <Field name="avatar" label="Ảnh đại diện" component={ImageField} /> */}
        </CardContent>
        <CardActions>
          <Button variant="contained" color="primary" type="submit">
            Đăng
          </Button>
        </CardActions>
      </Card>
    </form>
  );
}
export default reduxForm({
  form: 'TRANSLATOR_FORM',
})(TranslatorForm);
