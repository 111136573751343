import React, { useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { debounce } from 'lodash/function';
import Typography from '@material-ui/core/Typography';
import Link from '@ttg-media/components/build/Link';
import { ga4SendEvent } from '@ttg-media/components/build/analyticsHelpers';
import { longStoryDetailsUrl } from '../../urls';

function LongStorySearch() {
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const fetch = useMemo(
    () =>
      debounce(({ input }, cb) => {
        if (!input) return;
        const term = `${input.trim()}*`;
        ga4SendEvent('search', {
          search_term: input,
        });
        // eslint-disable-next-line no-undef
        axios(`${env.searchUrl}/api/search/ttg_longstories`, {
          method: 'post',
          data: {
            query: {
              bool: {
                should: [
                  {
                    match: {
                      slug: term,
                    },
                  },
                  {
                    match: {
                      title: term,
                    },
                  },
                ],
              },
            },
          },
        })
          .then((resp) => {
            if (resp?.status === 200)
              return resp.data.hits.hits.map(({ _source: item }) => ({
                id: item.shortId,
                ...item,
              }));
            return [];
          })
          .then(cb);
      }, 500),
    [],
  );
  useEffect(() => {
    fetch({ input: inputValue }, (items) => {
      setOptions(items);
    });
  }, [value, inputValue]);
  return (
    <Autocomplete
      id="search-long-story"
      sx={{ width: 300 }}
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option.title
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      onChange={(e, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField {...params} label="Tìm truyện" fullWidth />
      )}
      renderOption={(option, props) => (
        <li {...props}>
          <Link
            to={longStoryDetailsUrl({
              id: option.shortId,
              slug: option.slug,
            })}
          >
            <Typography variant="body2" color="text.secondary">
              {option.title}
            </Typography>
          </Link>
        </li>
      )}
    />
  );
}

export default LongStorySearch;
