import React from 'react';
import gql from 'graphql-tag';
import { useParams } from 'react-router-dom';
import MangaList from './components/MangaList';
import { MangaParts } from '../../gql/fragments';
import { compileUrl, favouriteRoute } from '../../urls';
import UserLayout from '../../components/Layout/UserLayout';

const query = gql`
  query favouriteMangaList($filter: MangaFilter!) {
    mangaList: favouriteManga(filter: $filter) {
      mangas {
        ...MangaParts
      }
      count
    }
  }
  ${MangaParts}
`;
export default function () {
  const params = useParams();
  const { slug } = params;
  const page = Number.parseInt(params.page, 10);
  return (
    <UserLayout path={favouriteRoute}>
      <MangaList
        user={slug}
        page={page}
        query={query}
        variables={{ filter: { userName: slug, page } }}
        url={(p) => compileUrl(favouriteRoute, { slug, page: p })}
        name="favourite"
      />
    </UserLayout>
  );
}
