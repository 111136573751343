import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { useFormContext } from 'react-hook-form';

function ButtonPreview() {
  const form = useFormContext();
  const images = form.getValues('images');
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  return (
    <>
      <Button onClick={() => setOpen(true)} variant="outlined">
        Kiểm tra
      </Button>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle title="Kiểm tra thứ tự ảnh" />
        <List>
          {images?.map((image, index) => (
            <ListItem key={image.url}>
              <Box display="flex" alignItems="center" flexDirection="column">
                <img src={image.preview} alt="" style={{ maxWidth: '100%' }} />
                <Typography>Trang {index + 1}</Typography>
                <Typography variant="caption">{image.fileName}</Typography>
              </Box>
            </ListItem>
          ))}
        </List>
      </Dialog>
    </>
  );
}

export default ButtonPreview;
