// @flow
import React from 'react';
import Link from '@ttg-media/components/build/Link';
import Container from '@material-ui/core/Container';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { useQuery } from '@apollo/client';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import List from '@material-ui/core/List';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { compileUrl } from '../../../urls';
import longStoryRoute from './longStoryUrls';
import { GET_LONG_STORY_FOR_EDIT } from './longStoryFragments';
import handleAlt from '../../../components/handleAlt';
import PageHead from '../../../components/PageHead';
import { MangaStatus } from '../../../pages/user/edit-manga/EditMangaLayout/MangaStatus';

function ListMenuItem({ item, selected }) {
  return (
    <ListItem
      value={item.id}
      component={Link}
      to={item.url}
      selected={selected}
    >
      <ListItemText primary={item.label} />
    </ListItem>
  );
}
function TabMenuItem({ item, selected }) {
  return (
    <Tab
      label={item.label}
      component={Link}
      selected={selected}
      to={item.url}
    />
  );
}
TabMenuItem.Wrapper = Tabs;
ListMenuItem.Wrapper = List;
function Menu({ items, selected, variant = 'list' }) {
  const MenuItem = { list: ListMenuItem, tab: TabMenuItem }[variant];
  return (
    <MenuItem.Wrapper>
      {items.map((i) => {
        const isSelected = i.id === selected;
        return <MenuItem item={i} isSelected={isSelected} />;
      })}
    </MenuItem.Wrapper>
  );
}
export default function (props) {
  const { route, id } = props;
  const resp = useQuery(GET_LONG_STORY_FOR_EDIT, {
    variables: { id },
  });
  const alt = handleAlt(resp);
  if (alt) return alt;
  const { longStory } = resp.data;
  const menuItems = [
    {
      id: longStoryRoute.edit,
      url: compileUrl(longStoryRoute.edit, { id }),
      label: 'Thông tin',
    },
    {
      id: longStoryRoute.editMangaOrder,
      url: compileUrl(longStoryRoute.editMangaOrder, { id }),
      label: 'Chương truyện',
    },
    {
      id: longStoryRoute.mangas,
      url: compileUrl(longStoryRoute.mangas, { id }),
      label: 'Truyện tranh',
    },
  ];
  return (
    <Container>
      <PageHead
        title={
          <Box>
            <Typography variant="h5">{longStory.title}</Typography>
            <Box ml={1}>
              <MangaStatus manga={longStory} />
            </Box>
          </Box>
        }
      />
      <Box mb={2}>
        <Menu items={menuItems} selected={route} variant="tab" />
      </Box>
      <Box>{props.children}</Box>
    </Container>
  );
}
