import React, { useState } from 'react';
import _move from 'lodash-move';
import CardActions from '@material-ui/core/CardActions';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { useParams } from 'react-router-dom';
import { ButtonAction } from '../../../../components/ButtonAction';
import { SortableList } from './SortableList';

import { mangaEditPageOrder } from '../../../../urls';
import EditMangaLayout from '../EditMangaLayout';
import useMangaForEdit from '../useMangaForEdit';
import handleAlt from '../../../../components/handleAlt';

const editMangaPageOrderGql = gql`
  mutation editMangaPageOrder($mangaId: Int!, $pageIds: [String]!) {
    editMangaPageOrder(mangaId: $mangaId, pageIds: $pageIds) {
      id
    }
  }
`;

export function ReorderMangaPageComponent() {
  const [pages, setPages] = useState([]);
  const params = useParams();
  const id = parseInt(params.id, 10);
  const mutation = useMutation(editMangaPageOrderGql, {
    variables: {
      mangaId: id,
      pageIds: pages.map((p) => p.id),
    },
  });
  const resp = useMangaForEdit({
    onCompleted: (data) => {
      setPages(data.manga.pages);
    },
  });
  const alt = handleAlt(resp);
  if (alt) return alt;
  const onSortEnd = ({ oldIndex, newIndex }) =>
    oldIndex !== newIndex && setPages(_move(pages, oldIndex, newIndex));

  return (
    <EditMangaLayout route={mangaEditPageOrder}>
      <SortableList
        items={pages}
        onSortEnd={onSortEnd}
        setPages={setPages}
        mangaId={id}
        useDragHandle
        axis="xy"
      />
      <CardActions>
        <ButtonAction variant="outlined" mutation={mutation}>
          Lưu thứ tự
        </ButtonAction>
      </CardActions>
    </EditMangaLayout>
  );
}

export default ReorderMangaPageComponent;
