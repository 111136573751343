import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import React from 'react';
import Link from '@ttg-media/components/build/Link';
import { authorDetailsUrl } from '../../../../urls';

export function AuthorsList({ authors }) {
  return (
    <Breadcrumbs separator="">
      {authors &&
        authors.map(
          (author) =>
            author && (
              <Link
                key={author.slug}
                to={authorDetailsUrl({ id: author.id })}
                color="primary"
              >
                {author.name}
              </Link>
            ),
        )}
    </Breadcrumbs>
  );
}
