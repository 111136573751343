import React from 'react';
import { Route } from 'react-router-dom';
import MangaDetailsPage from './MangaDetailsPage';
import * as url from '../../urls';
import { MangaNewPage } from '../list/new';
import FavouritePage from '../list/favourite';
import PostMangaPage from '../user/post-manga/PostMangaPage';
import MangaListPage from '../list/MangaListPage';
import TruyenTranhDamMy from '../list/truyen-tranh-dam-my';

export default [
  <Route element={<TruyenTranhDamMy />} path={url.ttdm} />,
  <Route element={<PostMangaPage />} path={url.postMangaPage} />,
  <Route element={<MangaDetailsPage />} path={url.mangaDetailsPage} />,
  <Route element={<MangaDetailsPage />} path={url.mangaDetailsPageWithParts} />,
  <Route
    element={<MangaNewPage sort="new" title="Truyện dịch mới" />}
    path={url.mangaHomeRoute}
  />,
  <Route
    element={<FavouritePage title="Favourite" />}
    path={url.favouriteRoute}
  />,
  <Route element={<MangaListPage />} path={url.mangaList} />,
];
