import React from 'react';
import withError from '@ttg-media/components/build/hoc/withError';
import { useQuery } from '@apollo/client';
import HandleError from '@ttg-media/components/build/HandleError';
import gql from 'graphql-tag';
import ErrorBoundary from '@ttg-media/components/build/hoc/SentryBoundary';
import Title from '@ttg-media/components/build/Title';
import { MangaParts } from '../../../gql/fragments';
import { Recommendations } from '../../../components/MangaListGroup/Recommendations';

const GET_RECOMMENDATIONS = gql`
  query GetRecommendations($id: Int!) {
    recommendations: mangaRecommendations(id: $id) {
      total
      items {
        ...MangaParts
      }
    }
  }
  ${MangaParts}
`;
function MangaRecommendationsComponent({ manga }) {
  const { loading, data, error } = useQuery(GET_RECOMMENDATIONS, {
    variables: { id: manga.id },
  });
  if (loading || error) return <HandleError />;
  const { recommendations } = data;
  return (
    recommendations.items?.length > 0 && (
      <div>
        <Title>Truyện gợi ý</Title>
        <ErrorBoundary>
          <Recommendations items={recommendations.items} name="reco" />
        </ErrorBoundary>
      </div>
    )
  );
}

export default withError()(MangaRecommendationsComponent);
