import React from 'react';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { ButtonAction } from '../../../../components/ButtonAction';

function ButtonCreateLongStoryFromManga({ manga }) {
  const mutation = useMutation(
    gql`
      mutation CreateLongStoryFromManga($manga: Int!) {
        createLongStoryFromManga(manga: $manga)
      }
    `,
    {
      variables: { manga: manga.id },
    },
  );

  return (
    <ButtonAction mutation={mutation} variant="button" size="small">
      Tạo nhanh truyện dài
    </ButtonAction>
  );
}
export default ButtonCreateLongStoryFromManga;
