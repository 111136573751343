import React from 'react';
import {
  AuthSuccessPage,
  LogoutCallbackPage,
} from '@ttg-media/components/build/auth';
import { Route } from 'react-router-dom';
import url from './authUrls';

export default [
  <Route path={url.authSuccessPage} element={<AuthSuccessPage />} />,
  <Route path={url.logoutCallback} element={<LogoutCallbackPage />} />,
];
