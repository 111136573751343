import Typography from '@material-ui/core/Typography';
import React from 'react';

export function Row({ label, children }) {
  return (
    <div>
      <Typography variant="caption">{label}</Typography>
      {children}
    </div>
  );
}
