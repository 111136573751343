import React, { useState } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import Typography from '@material-ui/core/Typography';
import { manageAdminMangaFilters } from '../../../urls';

const referenceDataQuery = gql`
  query referenceData {
    categories {
      id
      slug
      name
    }
    languages {
      id
      slug
      name
    }
  }
`;

export function SearchBar({ onSearch }) {
  const [filter, setFilter] = useState(manageAdminMangaFilters.all);
  const [category, setCategory] = useState('');
  const referenceDataResp = useQuery(referenceDataQuery);
  const [chaptersCount, setChaptersCount] = useState(null);
  const [language, setLanguage] = useState('');
  return (
    <Card>
      <CardContent>
        <Box display="flex" flexDirection="row">
          <Box mb={0.5} mr={1}>
            <Typography>Filter</Typography>
            <Select
              value={filter}
              onChange={(e) => {
                setFilter(e.target.value);
              }}
            >
              <MenuItem value={manageAdminMangaFilters.all}>All</MenuItem>
              <MenuItem value={manageAdminMangaFilters.reviewed}>
                Reviewed
              </MenuItem>
              <MenuItem value={manageAdminMangaFilters.unreview}>
                Unreviewed
              </MenuItem>
              <MenuItem value={manageAdminMangaFilters.deleted}>
                Deleted
              </MenuItem>
            </Select>
          </Box>
          <Box mb={0.5} mr={1}>
            <Typography>Category</Typography>
            <Select
              value={category}
              onChange={(e) => setCategory(e.target.value)}
            >
              <MenuItem value="">All</MenuItem>
              {(referenceDataResp.data?.categories || []).map((c) => (
                <MenuItem value={c.slug}>{c.name}</MenuItem>
              ))}
            </Select>
          </Box>
          <Box mb={0.5} mr={1}>
            <Typography>Chapters</Typography>
            <Select
              onChange={(e) => setChaptersCount(e.target.value)}
              value={chaptersCount}
            >
              <MenuItem value="">All</MenuItem>
              <MenuItem value="zero">Zero</MenuItem>
              <MenuItem value="one">One</MenuItem>
              <MenuItem value="many">Many</MenuItem>
            </Select>
          </Box>
          <Box mb={0.5} mr={1}>
            <Typography>Language</Typography>
            <Select
              value={language}
              onChange={(e) => setLanguage(e.target.value)}
            >
              <MenuItem value="">All</MenuItem>
              {(referenceDataResp.data?.languages || []).map((c) => (
                <MenuItem value={c.slug}>{c.name}</MenuItem>
              ))}
            </Select>
          </Box>
        </Box>
      </CardContent>
      <CardActions>
        <Button
          onClick={() =>
            onSearch({ filter, category, chaptersCount, language })
          }
          variant="outlined"
          color="primary"
        >
          Search
        </Button>
      </CardActions>
    </Card>
  );
}
