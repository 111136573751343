import React from 'react';
import Container from '@material-ui/core/Container';
import PageHead from '../../../../components/PageHead';
import LongStoriesPage from './LongStoriesPage';
import { usePager } from '../../../../components/usePager';

export default function () {
  const { page } = usePager();
  const title = 'Danh sách truyện dài';
  return (
    <>
      <Container>
        <PageHead title={title} page={page} />
      </Container>
      <LongStoriesPage page={page} />
    </>
  );
}
