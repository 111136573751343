import React from 'react';
import { useQuery } from '@apollo/client';
import HandleError from '@ttg-media/components/build/HandleError';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';
import {
  GET_LONG_STORY_FOR_EDIT,
  UPDATE_LONG_STORY,
} from '../longStoryFragments';
import { convertFromValues, EditLongStoryForm } from '../LongStoryForm';
import { convertRefsToForm } from '../../../../pages/helpers';
import DeleteLongStoryButton from './DeleteLongStoryButton';
import { useToastMutation } from '../../../../components/toastify';
import UpdateLongStoryCacheButton from './UpdateLongStoryCacheButton';

function EditLongStoryPage(props) {
  const { id, onCompleted } = props;
  const { data, loading, error } = useQuery(GET_LONG_STORY_FOR_EDIT, {
    variables: { id },
  });
  const [mutation] = useToastMutation(UPDATE_LONG_STORY, { onCompleted });
  if (loading || error) return <HandleError loading={loading} error={error} />;

  const onSubmit = (values) =>
    mutation({
      variables: {
        id,
        longStory: convertFromValues(values),
      },
    });

  const { longStory } = data;
  return (
    <div>
      <Card>
        <CardContent>
          <EditLongStoryForm
            onSubmit={onSubmit}
            initialValues={{
              ...convertRefsToForm(longStory),
              otherTitles: longStory.otherTitles.join('\n'),
            }}
          />
        </CardContent>
      </Card>
      <Box mt={2}>
        <DeleteLongStoryButton id={longStory.lid} onCompleted={onCompleted} />
        <UpdateLongStoryCacheButton
          id={longStory.lid}
          onCompleted={onCompleted}
        />
      </Box>
    </div>
  );
}

export default EditLongStoryPage;
