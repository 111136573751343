import _omit from 'lodash/omit';
import _isNil from 'lodash/isNil';

export function truncate(string, length = 20) {
  if (!string) return '';
  if (string.length > length)
    return `${string.slice(0, Math.max(0, length))}...`;
  return string;
}

export function canonicalRedirect(path, canonical) {
  if (path !== canonical) {
    return {
      redirect: canonical,
      status: 301,
    };
  }
  return null;
}

export const convertRefsToForm = (obj) =>
  _omit(
    {
      ...obj,
      authors:
        obj.authors?.map((a) => ({
          value: a.id,
          label: a.name,
        })) || [],
      tags: obj.tags?.map((t) => ({ value: t.id, label: t.name })) || [],
      category: obj.category?.slug,
      language: obj.language?.slug,
      translator: obj.translator && {
        value: obj.translator.id,
        label: obj.translator.name,
      },
      mangas: obj.mangas?.map((m) => ({ value: m.id, label: m.title })),
    },
    _isNil,
  );

export function convertSelectedToObj(i) {
  if (!i) return null;
  return {
    label: i.label,
    value: String(i.value),
    isNew: i.__isNew__,
  };
}
