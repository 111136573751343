import React from 'react';
import Async from 'react-select/async';
import Box from '@material-ui/core/Box';

export function SearchBox({ onSearch, placeholder = 'Tìm kiếm', onChange }) {
  return (
    <Box mb={1}>
      <Async
        placeholder={placeholder}
        cacheOptions
        loadOptions={onSearch}
        autoload={false}
        onChange={onChange}
      />
    </Box>
  );
}
