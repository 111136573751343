import React from 'react';
import { useQuery } from '@apollo/client';
import Typography from '@material-ui/core/Typography';
import Link from '@ttg-media/components/build/Link';
import { RequireAdmin } from '@ttg-media/components/build/auth';
import RowSelectionTable, {
  useRowSelectionTable,
} from '@ttg-media/components/build/RowSelectionTable';
import { GET_LONG_STORY_FOR_EDIT } from '../longStoryFragments';
import { mangaDetailsUrl } from '../../../../urls';
import { MangaStatus } from '../../../../pages/user/edit-manga/EditMangaLayout/MangaStatus';
import handleAlt from '../../../../components/handleAlt';
import ButtonReview from '../../../../pages/user/edit-manga/actions/ButtonReview';

const columns = [
  { Header: '#', accessor: (d) => d.chapter + 1 },
  {
    Header: 'Title',
    accessor: (d) => (
      <Typography>
        <Link to={mangaDetailsUrl(d)}>{d.title}</Link>
      </Typography>
    ),
  },
  { Header: 'Số trang', accessor: (d) => d.pages.length },
  { Header: 'Cover', accessor: (d) => (d.cover?.src ? 'Y' : '') },
  { Header: 'Download', accessor: (d) => (d.canDownload ? 'Y' : '') },
  {
    Header: 'Tình trạng',
    accessor: (d) => <MangaStatus manga={d} />,
  },
];

function ManageLongStoryMangas(props) {
  const { id } = props;
  const resp = useQuery(GET_LONG_STORY_FOR_EDIT, {
    variables: { id },
  });
  const table = useRowSelectionTable({
    columns,
    data: resp.data?.longStory.mangas || [],
  });
  const { selectedFlatRows } = table;
  const alt = handleAlt(resp);
  if (alt) return alt;
  const selectedMangaIdList = selectedFlatRows.map((i) => i.original.id);

  return (
    <div>
      <RequireAdmin>
        <ButtonReview mangaIdList={selectedMangaIdList} />
      </RequireAdmin>
      <RowSelectionTable
        columns={columns}
        data={resp.data?.longStory.mangas || []}
        tableProps={table}
      />
    </div>
  );
}

export default ManageLongStoryMangas;
