import React from 'react';
import { useFormContext } from 'react-hook-form';
import MenuItem from '@material-ui/core/MenuItem';
import _uniqBy from 'lodash/uniqBy';

function ButtonAutoSort(props) {
  const form = useFormContext();
  const autoSort = () => {
    form.setValue(
      'images',
      _uniqBy(form.getValues('images'), (i) => i.fileName),
    );
  };
  return (
    <MenuItem onClick={autoSort} disabled={props.disabled}>
      Xoá ảnh trùng
    </MenuItem>
  );
}

export default ButtonAutoSort;
