import React, { useMemo } from 'react';
import { Dashboard as DashboardComponent } from '@uppy/react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import Button from '@material-ui/core/Button';
import FormControlledField from '@ttg-media/components/build/FormControls/FormField/FormControlledField';
import CloudUpload from '@material-ui/icons/CloudUpload';
import { orderImageByFileName } from '../Step3OrderPages/orderImageByFileName';
import SortableImages from '../Step3OrderPages/SortableImages';
import createUppyClient from './uppy';

export function convert(file) {
  const resp = file.response.body;
  return {
    fileId: file.id, // "uppy-z89dzsr9x3z21/jpg-1e-image/jpeg-118091-1558754884000"
    id: file.id, // "uppy-z89dzsr9x3z21/jpg-1e-image/jpeg-118091-1558754884000"
    fileName: file.name, // original name "z89dzsr9x3z21.jpg"
    bucket: resp.bucket, // ttg-dev
    storage: resp.storage, // minio
    fileKey: resp.key, // "1581766368145-z89dzsr9x3z21.jpg"
    baseFileName: file.name, // same as original name
    url: resp.url, // "https://i3.truyentranhgay.com/100ppm-underboys-pantsu-danshi-rakugaki-hon-jp-miyu_e70ab33aa1db4511ad83d565197e321c.jpg"
    size: file.size, // 118091
    type: file.type, // "image/jpeg"
    preview: file.preview, // "blob:http://localhost:3000/2eab0721-d9d1-4ce1-a165-1100c9b035c7"
  };
}
function ButtonUpload(props) {
  const { append } = props.fieldArray;
  const uppyClient = useMemo(() => {
    const client = createUppyClient();
    client.on('complete', (result) => {
      const files = result.successful || [];
      const convertedFiles = files.map((file) => convert(file));
      const orderedFiles = orderImageByFileName(
        [...convertedFiles],
        (f) => f.fileName,
      );
      orderedFiles.forEach((f) => append(f));
    });
    return client;
  });

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        endIcon={<CloudUpload color="inherit" />}
        onClick={() => uppyClient.getPlugin('Dashboard').openModal()}
      >
        Tải lên
      </Button>
      <DashboardComponent uppy={uppyClient} inline={false} />
    </>
  );
}
export function Step1UploadComponent() {
  const form = useFormContext();
  const fieldArray = useFieldArray({ control: form.control, name: 'images' });

  return (
    <>
      <link
        href="https://transloadit.edgly.net/releases/uppy/v1.19.2/uppy.min.css"
        rel="stylesheet"
      />
      <FormControlledField
        label="Trang truyện"
        name="images"
        render={() => (
          <SortableImages
            fieldArray={fieldArray}
            left={<ButtonUpload fieldArray={fieldArray} />}
          />
        )}
      />
    </>
  );
}

export default Step1UploadComponent;
