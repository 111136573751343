import Typography from '@material-ui/core/Typography';
import React from 'react';

export function MangaStatus(props) {
  const { manga } = props;
  if (manga.isDeleted)
    return (
      <Typography size="small" variant="caption" color="secondary">
        Đã xoá
      </Typography>
    );
  if (!manga.isReviewed)
    return (
      <Typography size="small" variant="caption" color="secondary">
        Đợi duyệt
      </Typography>
    );
  if (manga.isReviewed)
    return (
      <Typography size="small" variant="caption" color="success">
        Đã duyệt
      </Typography>
    );
  return <div />;
}
