import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import gql from 'graphql-tag';
import { compose } from 'redux';
import { withAuth } from '@ttg-media/components/build/auth';
import SimpleMutationButton from '../SimpleMutationButton';

const CLEANUP_TAGS = gql`
  mutation CleanupTags {
    cleanupTags
  }
`;
const UPDATE_MANGA_CACHE_ALL = gql`
  mutation UpdateMangaCacheAll {
    updateMangaCacheAll
  }
`;
const UPDATE_LS_CACHE_ALL = gql`
  mutation uploadLongStoriesCacheAll {
    uploadLongStoriesCacheAll
  }
`;
const UpdateAuthorsCacheAll = gql`
  mutation UpdateAuthorsCacheAll {
    updateAuthorsCacheAll
  }
`;
const UpdateTagCacheAll = gql`
  mutation UpdateTagCacheAll {
    updateTagCacheAll
  }
`;
const TriggerPostedEventAll = gql`
  mutation TriggerPostedEventAll {
    triggerPostedEventAll
  }
`;
export default compose(withAuth())(() => (
  <div>
    <List>
      <ListItem>
        <SimpleMutationButton mutation={CLEANUP_TAGS}>
          Cleanup all tag
        </SimpleMutationButton>
      </ListItem>
      <ListItem>
        <SimpleMutationButton mutation={UPDATE_MANGA_CACHE_ALL}>
          Update all manga caches
        </SimpleMutationButton>
      </ListItem>
      <ListItem>
        <SimpleMutationButton mutation={UPDATE_LS_CACHE_ALL}>
          Update all long stories caches
        </SimpleMutationButton>
      </ListItem>
      <ListItem>
        <SimpleMutationButton mutation={UpdateAuthorsCacheAll}>
          Update all authors
        </SimpleMutationButton>
      </ListItem>
      <ListItem>
        <SimpleMutationButton mutation={UpdateTagCacheAll}>
          Update all tags
        </SimpleMutationButton>
      </ListItem>
      <ListItem>
        <SimpleMutationButton mutation={TriggerPostedEventAll}>
          Trigger posted event for all
        </SimpleMutationButton>
      </ListItem>
    </List>
  </div>
));
