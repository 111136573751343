import React from 'react';
import { FieldArray, Field } from 'redux-form';
import Button from '@material-ui/core/Button';
import InputField from '@ttg-media/components/build/FormControls/InputField';
import Editor from '@ttg-media/components/build/FormControls/TextEditor';
import AsyncCreatableSelect from '@ttg-media/components/build/FormControls/AsyncCreatableSelect';
import Box from '@material-ui/core/Box';
import { useQuery } from '@apollo/client';
import CircularProgress from '@material-ui/core/CircularProgress';
import { components } from 'react-select';
import Select from '@ttg-media/components/build/FormControls/Select';

import Typography from '@material-ui/core/Typography';
import { fromNow } from '@ttg-media/components/build/utils';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Chip from '@material-ui/core/Chip';
import fetchDataFormQuery from './gql/fetchFormDataGql';
import {
  convertToOptions,
  searchAuthors,
  searchLongStories,
  searchTags,
} from './actions';

function LongStorySearchBoxOption(props) {
  const { longStory } = props.data;
  if (!longStory) return <components.Option {...props} />;
  return (
    <components.Option {...props}>
      <ListItem dense button>
        <ListItemText
          primary={`#${longStory.id} - ${longStory.name}`}
          secondary={
            <Typography variant="caption" color="inherit">
              đăng bởi {longStory.createdBy?.userName || 'N/A'}{' '}
              {fromNow(longStory.createdDate)}
            </Typography>
          }
        />
        {longStory.isDeleted && (
          <Chip label="Đã xoá" size="small" color="secondary" />
        )}
        {longStory.isReviewed && <Chip label="Đợi duyệt" size="small" />}
      </ListItem>
    </components.Option>
  );
}
function TagSearchBoxOption(props) {
  const { tag } = props.data;
  if (!tag) return <components.Option {...props} />;
  return (
    <components.Option {...props}>
      <Box display="flex">
        <Typography>
          {tag.name} ({tag.mangasCount} truyện)
        </Typography>
      </Box>
    </components.Option>
  );
}

function MangaFormComponent(props) {
  const { handleSubmit, pristine, submitting, id, isOneShot } = props;
  const isEdit = Boolean(id);
  const isNew = !isEdit;
  const resp = useQuery(fetchDataFormQuery);
  const { data, loading } = resp;
  if (loading) return <p>Loading...</p>;
  const { categories, languages } = data;
  return (
    <form onSubmit={handleSubmit}>
      <Field
        name="title"
        type="text"
        label="Tên truyện"
        required
        component={InputField}
      />
      <Field
        name="description"
        label="Miêu tả nội dung truyện"
        component={Editor}
        expanded
      />

      <Field
        name="category"
        label="Thể loại"
        required
        loading={loading}
        inline
        items={convertToOptions(categories)}
        component={Select}
      />
      <Field
        name="language"
        label="Ngôn ngữ"
        required
        loading={loading}
        inline
        items={convertToOptions(languages)}
        component={Select}
      />
      <Field
        name="authors"
        type="text"
        label="Tác giả"
        multi
        defaultOptions
        loadOptions={searchAuthors}
        component={AsyncCreatableSelect}
      />
      <Field
        name="tags"
        type="text"
        label="Tag"
        multi
        defaultOptions
        loadOptions={searchTags}
        component={AsyncCreatableSelect}
        selectComponents={{ Option: TagSearchBoxOption }}
      />
      {isNew && (
        <Field
          hidden={isOneShot}
          name="longStory"
          label="Truyện dài"
          defaultOptions
          loadOptions={searchLongStories}
          component={AsyncCreatableSelect}
          selectComponents={{ Option: LongStorySearchBoxOption }}
        />
      )}
      <FieldArray name="images" component="div" required />
      <Field name="cover" component="div" required />
      <Box display="flex" justifyContent="flex-end">
        <Button
          variant="contained"
          type="submit"
          color="primary"
          disabled={pristine || submitting}
          endIcon={submitting ? <CircularProgress size={20} /> : <span />}
        >
          Đăng
        </Button>
      </Box>
    </form>
  );
}

export default MangaFormComponent;
