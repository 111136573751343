import gql from 'graphql-tag';

export default gql`
  query mangasSearch($term: String!) {
    items: mangasWithoutLongStorySearch(term: $term) {
      id
      slug
      title
      createdDate
      cover {
        src
      }
    }
  }
`;
