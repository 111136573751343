import gql from 'graphql-tag';
import { MangaDetailsParts, MangaParts } from '../../../gql/fragments';

export const CREATE_DOWNLOAD = gql`
  mutation CreateDownload($mangaId: Int!) {
    createDownload(mangaId: $mangaId) {
      ...MangaParts
    }
  }
  ${MangaParts}
`;

export const PURGE_CACHE = gql`
  mutation PurgeMangaCache($id: Int!) {
    purgeMangaCache(id: $id)
  }
`;

export const OPTIMIZE = gql`
  mutation OptimizeManga($manga: Int!) {
    optimizeManga(manga: $manga) {
      id
    }
  }
`;

export const UPDATE_MANGA_STATS = gql`
  mutation UpdateMangaStats($id: Int!) {
    updateStats(id: $id)
  }
`;

export const UPDATE_AUTHOR_STATS = gql`
  mutation UpdateAuthorStats($type: String!) {
    updateAuthorStats(type: $type)
  }
`;

export const FIX_MANGA_PAGES_S3 = gql`
  mutation FixMangaPages($manga: Int!) {
    fixMangaPage(manga: $manga) {
      success
      hasError
      errorMessage
      skip
      details
    }
  }
`;

export const deleteMangaMutation = gql`
  mutation deleteManga($manga: Int!, $purge: Boolean) {
    manga: deleteManga(manga: $manga, purge: $purge) {
      id
    }
  }
`;

export const fetchMangaForEditGql = gql`
  query GetMangaForEdit($id: Int!) {
    manga: mangaForEdit(id: $id) {
      ...MangaDetailsParts
      downloadUrl
      minioStatus
      version
      isReviewed
      isDeleted
      createdDate
      reviewedDate
    }
  }
  ${MangaDetailsParts}
`;
export const deleteMangaPagesGql = gql`
  mutation DeleteMangaPages($mangaId: Int, $pageIds: [String]!) {
    deleteMangaPages(mangaId: $mangaId, pageIds: $pageIds) {
      id
    }
  }
`;
export const QuickReview = gql`
  mutation QuickReview($manga: Int!) {
    quickReview(manga: $manga)
  }
`;
export const RestoreManga = gql`
  mutation restoreManga($manga: Int!) {
    manga: undoDeleteManga(manga: $manga) {
      id
    }
  }
`;
export const ReviewManga = gql`
  mutation reviewMangaList($mangaIdList: [Int]) {
    reviewMangaList(mangaIdList: $mangaIdList)
  }
`;
export const UndoReviewManga = gql`
  mutation undoReviewManga($manga: Int!) {
    manga: undoReviewManga(manga: $manga) {
      id
    }
  }
`;

export const TriggerMangaPostedEvent = gql`
  mutation triggerMangaPostedEvent($manga: Int!) {
    triggerMangaPostedEvent(manga: $manga)
  }
`;
