import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Link from '@ttg-media/components/build/Link';
import Avatar from '@ttg-media/components/build/Avatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Favorite from '@material-ui/icons/Favorite';
import List from '@material-ui/icons/List';
import Launch from '@material-ui/icons/Launch';
import CloudUpload from '@material-ui/icons/CloudUpload';
import ListItemText from '@material-ui/core/ListItemText';
import SettingsIcon from '@material-ui/icons/Settings';
import PlaylistPlay from '@material-ui/icons/PlaylistPlay';
import {
  compileUrl,
  datingWebsite,
  favouriteRoute,
  longStoryRoute,
  manageMyManga,
  manageProfile,
  mangaListUrl,
  postMangaPage,
} from '../../../urls';

export function UserLinks(props) {
  const { user } = props;
  return [
    <MenuItem
      component={Link}
      to={mangaListUrl({ userName: user.userName })}
      key={1}
    >
      <ListItemIcon>
        <Avatar user={user} />
      </ListItemIcon>

      <ListItemText
        secondary={user.email}
        primary={user.displayName || user.userName}
      />
    </MenuItem>,
    <MenuItem to={compileUrl(postMangaPage)} component={Link} key={2}>
      <ListItemIcon>
        <CloudUpload color="primary" />
      </ListItemIcon>
      Đăng truyện
    </MenuItem>,
    <MenuItem
      component="a"
      href={datingWebsite.dating({
        id: user.shortId,
        userName: user.userName,
      })}
      key={3}
    >
      <ListItemIcon>
        <Launch />
      </ListItemIcon>
      Trang kết bạn
    </MenuItem>,
    <MenuItem
      component={Link}
      to={compileUrl(favouriteRoute, { slug: user.userName })}
      key={4}
    >
      <ListItemIcon>
        <Favorite />
      </ListItemIcon>
      Truyện ưa thích
    </MenuItem>,
    <MenuItem
      component={Link}
      to={compileUrl(manageMyManga, { userId: user.id })}
      key={5}
    >
      <ListItemIcon>
        <List />
      </ListItemIcon>
      Quản lý truyện tranh
    </MenuItem>,
    <MenuItem
      component={Link}
      to={compileUrl(longStoryRoute.list, { user: user.id, page: 1 })}
      key={5}
    >
      <ListItemIcon>
        <PlaylistPlay />
      </ListItemIcon>
      Quản lý truyện dài
    </MenuItem>,
    <MenuItem
      component={Link}
      to={compileUrl(manageProfile.edit, { id: user.id })}
      key={6}
      name="btnUserMenuItemManageProfile"
    >
      <ListItemIcon>
        <SettingsIcon />
      </ListItemIcon>
      Thay đổi thông tin
    </MenuItem>,
  ];
}
