import gql from 'graphql-tag';

export default gql`
  query tagsSearch($term: String!) {
    items: tagsSearch(term: $term) {
      id
      slug
      name
      mangasCount
    }
  }
`;
