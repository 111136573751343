import React from 'react';
import Container from '@material-ui/core/Container';
import { Helmet } from 'react-helmet';
import { useQuery } from '@apollo/client';
import PageHead from '../../components/PageHead';
import LongStoryListPage from './components/LongStoryListPage';
import GET_LONG_STORIES from './GET_LONG_STORIES';
import HandleError from '../../components/handleError';
import { categoryPageUrl, CategorySlugs } from '../../urls';
import useQueryString from './useQueryString';

const seo = {
  title: 'Truyện tranh đam mỹ',
  h1: 'Truyện tranh đam mỹ',
  metaDescription: `Truyện tranh đam mỹ chỉ một loại tiểu thuyết tình cảm, có nguồn gốc từ
        Trung Quốc. Tiểu thuyết này tập trung khai thác những câu chuyện tình
        cảm của các cặp đôi nam nam hoặc đồng tính luyến ái nam`,
  metaKeyword: 'Truyện tranh đam mỹ',
};
export default function () {
  const { page } = useQueryString();
  const { data, error, loading } = useQuery(GET_LONG_STORIES, {
    variables: {
      filter: {
        category: 'yaoi',
        chaptersCount: 'many',
        language: 'tieng-viet',
        page,
      },
    },
  });

  return (
    <Container>
      <Helmet>
        <title>{seo.title} - Truyện tranh Gay</title>
        <meta name="description" content={seo.metaDescription} />
        <meta name="keyword" content={seo.metaKeyword} />
      </Helmet>
      <PageHead breadcrumbs={['Truyện tranh đam mỹ']} title={seo.h1} />
      <p>
        Truyện tranh đam mỹ chỉ một loại tiểu thuyết tình cảm, có nguồn gốc từ
        Trung Quốc. Tiểu thuyết này tập trung khai thác những câu chuyện tình
        cảm của các cặp đôi nam nam hoặc đồng tính luyến ái nam. Thể loại này
        khi vào Nhật Bản được gọi là Yaoi và Shōnen-ai. Còn ở Đài Loan tiểu
        thuyết này được gọi là Boy’s Love.
      </p>
      {error || loading ? (
        <HandleError error={error} loading={loading} />
      ) : (
        <LongStoryListPage
          data={data}
          page={page}
          url={(p) =>
            categoryPageUrl({ slug: CategorySlugs.truyenTranhDamMy, page: p })
          }
        />
      )}
    </Container>
  );
}
