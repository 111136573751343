import React from 'react';
import { useParams } from 'react-router-dom';
import EditLongStoryLayout from '../EditLongStoryLayout';
import ManageLongStoryMangas from './ManageLongStoryMangas';
import longStoryRoute from '../longStoryUrls';

export default function () {
  const params = useParams();
  const { id } = params;
  const title = 'Sửa thông tin bộ truyện';
  return (
    <EditLongStoryLayout title={title} route={longStoryRoute.mangas} id={id}>
      <ManageLongStoryMangas id={id} />
    </EditLongStoryLayout>
  );
}
