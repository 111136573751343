class NotFoundError extends Error {
  static errorName = 'NotFoundError';

  constructor(message) {
    super(message);
    this.name = NotFoundError.errorName;
  }
}

export default NotFoundError;
