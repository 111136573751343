import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import Container from '@material-ui/core/Container';
import HandleError from '@ttg-media/components/build/HandleError';
import PageHead from '@ttg-media/components/build/PageHead';
import Typography from '@material-ui/core/Typography';
import Profile from '@ttg-media/components/build/Profile';
import Grid from '@material-ui/core/Grid';
import { compose } from 'redux';
import { withAuth } from '@ttg-media/components/build/auth';
import { useParams } from 'react-router-dom';
import TranslatorForm from '../coms/TranslatorForm';
import { EDIT_TRANSLATOR, GET_TRANSLATOR } from '../translatorGql';

export default compose(withAuth())(() => {
  const params = useParams();
  const { id } = params;
  const { loading, data, error } = useQuery(GET_TRANSLATOR, {
    variables: { id },
  });
  const [edit] = useMutation(EDIT_TRANSLATOR);
  const onSubmit = ({ name, about, homePage, avatar, facebook, email }) =>
    edit({
      variables: {
        id,
        translator: { name, about, homePage, avatar, facebook, email },
      },
    });
  if (loading || error)
    return (
      <Container>
        <HandleError loading={loading} error={error} />
      </Container>
    );
  const { translator } = data;
  return (
    <Container>
      <PageHead
        title={translator.name}
        breadcrumbs={[<Typography>Sửa thông tin nhóm dịch</Typography>]}
      />
      <Grid container spacing={2}>
        <Grid item lg={4} md={4} sm={12} xs={12}>
          <Profile
            name={translator.name}
            contacts={{
              blog: translator.homePage,
              facebook: translator.facebook,
              email: translator.email,
            }}
            description={translator.about}
          />
        </Grid>
        <Grid item lg={8} md={8} sm={12} xs={12}>
          <TranslatorForm initialValues={translator} onSubmit={onSubmit} />
        </Grid>
      </Grid>
    </Container>
  );
});
