import React from 'react';
import { useParams } from 'react-router-dom';
import ManageMangaPage from './ManageMangaPage';
import AdminLayout from '../AdminLayout';
import { admin } from '../../../urls';
import useQueryString from '../../list/useQueryString';
import { ShortIdsFilter } from './ShortIdsFilter';

const title = 'Quản lý truyện tranh';
export default (function () {
  const params = useParams();
  const { page, filter } = params;
  const { shortIds } = useQueryString();
  return (
    <AdminLayout title={title} route={admin.manageManga}>
      <ManageMangaPage
        page={Number.parseInt(page, 10) || 1}
        limit={20}
        filter={filter}
        shortIds={ShortIdsFilter.parse(shortIds)}
      />
    </AdminLayout>
  );
});
