// eslint-disable-next-line import/no-relative-packages
import createApolloClient from '../../../../core/createApolloClient';
import searchAuthorsQuery from './gql/searchAuthorsGql';
import searchTagsQuery from './gql/searchTagsGql';
import searchMangaQuery from './gql/searchMangasGql';
import searchLongStoriesQuery from './gql/searchLongStoriesGql';
import { SEARCH_TRANSLATOR } from './postMangaGql';

const client = createApolloClient();
export const search = ({ term, query, mapper }) =>
  client
    .query({
      query,
      variables: { term },
    })
    .then((response) => {
      const {
        data: { items },
      } = response;
      if (items) {
        return mapper ? items.map((i) => mapper(i)) : items;
      }
      return [];
    });
export const searchAuthors = (term) =>
  search({
    term,
    query: searchAuthorsQuery,
    mapper: (author) => ({
      value: author.id,
      label: `${author.name} (${author.mangasCount} manga)`,
      author,
    }),
  });

export const searchAuthorsNew = (term) =>
  client
    .query({
      query: searchAuthorsQuery,
      variables: { term },
    })
    .then((response) => {
      const {
        data: { items },
      } = response;
      return (
        items?.map((author) => ({
          value: author,
          label: `${author.name} (${author.mangasCount} manga)`,
          author,
        })) || []
      );
    });

export const searchTags = (term) =>
  search({
    term,
    query: searchTagsQuery,
    mapper: (tag) => ({
      value: tag.id || tag.shortId,
      label: `${tag.name} (${tag.mangasCount} truyện)`,
      tag,
    }),
  });

export const searchMangas = (term) =>
  search({
    term,
    query: searchMangaQuery,
    mapper: (manga) => ({
      value: manga.id,
      label: manga.title,
    }),
  });

export const searchLongStories = (term) =>
  search({
    term,
    query: searchLongStoriesQuery,
    mapper: (ls) => ({
      value: ls.id,
      label: ls.name,
      longStory: ls,
    }),
  });

export const searchTranslators = (term) =>
  search({ term, query: SEARCH_TRANSLATOR });

export const convertToOptions = (list) =>
  list.map(({ slug, name }) => ({ key: slug, value: slug, label: name }));
