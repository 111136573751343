import React from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Contact from '@ttg-media/components/build/Contact';

export default function () {
  return (
    <Container>
      <Typography title="Liên hệ / Trợ giúp" />
      <Typography>
        Các bạn có lỗi có thể liên hệ thông tin ở dưới để được trợ giúp
      </Typography>
      <Contact />
    </Container>
  );
}
