import React from 'react';
import { useParams } from 'react-router-dom';
import EditProfilePage from './EditProfilePage';
import EditProfileLayout from '../EditProfileLayout';
import { manageProfile } from '../../../../urls';

export default function () {
  const params = useParams();
  const id = Number.parseInt(params.id, 10).toString(); // todo must be int not string when edit profile
  return (
    <EditProfileLayout route={manageProfile.edit} id={id}>
      <EditProfilePage id={id} />
    </EditProfileLayout>
  );
}
