import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ExpandMore from '@material-ui/icons/ExpandMore';

export function ServerChooser({ onChange, options }) {
  const [selection, setSelection] = useState(options[0]);
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClose = (item) => {
    setAnchorEl(null);
    if (item.label) {
      setSelection(item);
      onChange(item.value);
    }
  };
  const handleClick = (e) => setAnchorEl(e.target);

  return (
    <>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        variant="outlined"
        size="small"
        endIcon={<ExpandMore />}
      >
        {selection.label}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {options.map((option) => (
          <MenuItem
            onClick={() => handleClose(option)}
            selected={selection.value === option.value}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
