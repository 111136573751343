import React from 'react';
import { useParams } from 'react-router-dom';
import LongStoryListPage from './LongStoryListPage';
import longStoryRoute from '../longStoryUrls';
import UserLayout from '../../../../components/Layout/UserLayout';

export default function action() {
  const params = useParams();
  const { user } = params;
  return (
    <UserLayout path={longStoryRoute.list}>
      <p>Truyện dài</p>
      <LongStoryListPage user={user} />
    </UserLayout>
  );
}
