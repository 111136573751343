import * as React from 'react';
import Container from '@material-ui/core/Container';
import { withAuth } from '@ttg-media/components/build/auth';
import Typography from '@material-ui/core/Typography';
import { useParams } from 'react-router-dom';
import Link from '@ttg-media/components/build/Link';
import BackIcon from '@material-ui/icons/ArrowBack';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import {
  compileUrl,
  longStoryDetailsUrl,
  mangaDetailsPage,
} from '../../../../urls';
import PageHead from '../../../../components/PageHead';
import handleAlt from '../../../../components/handleAlt';
import useMangaForEdit from '../useMangaForEdit';
import EditMangaSideMenu from './EditMangaSideMenu';
import { MangaStatus } from './MangaStatus';

function EditMangaLayout(props) {
  const params = useParams();
  const id = parseInt(params.id, 10);
  const resp = useMangaForEdit();
  const alt = handleAlt(resp);
  if (alt) return alt;
  const { manga } = resp.data;
  return (
    <Container>
      <PageHead
        title={
          <>
            <Typography variant="h4">{manga.title}</Typography>
            <MangaStatus manga={manga} />
          </>
        }
        breadcrumbs={[
          manga.longStory && (
            <Link to={longStoryDetailsUrl(manga.longStory)}>
              {manga.longStory.name}
            </Link>
          ),
        ]}
        right={
          <Button
            button
            component={Link}
            to={compileUrl(mangaDetailsPage, { ...manga })}
            name="manga-details-link"
            endIcon={<BackIcon />}
          >
            Trang truyện
          </Button>
        }
      />
      <EditMangaSideMenu manga={manga} id={id} route={props.route} />
      <Box>{props.children}</Box>
    </Container>
  );
}

export default withAuth()(EditMangaLayout);
