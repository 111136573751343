import React, { useEffect } from 'react';
import withError from '@ttg-media/components/build/hoc/withError';
import { GridLayout } from './layouts/GridLayout';
import { CarouselLayout } from './layouts/CarouselLayout';
import { MangaListItem } from './MangaListItem';
import {
  FluentdEvents,
  sendCustomAnalyticsEvent,
} from '../sendCustomAnalyticsEvent';

const LAYOUTS = {
  grid: GridLayout,
  carousel: CarouselLayout,
};
function MangaListGroupComponent(props) {
  const { variant = 'grid', utmSource, utmListing } = props;
  const mangas = props.mangaList?.mangas;
  const Layout = LAYOUTS[variant];
  useEffect(() => {
    if (utmListing)
      sendCustomAnalyticsEvent(FluentdEvents.MANGA_LISTING, utmListing);
    return null;
  }, [utmListing]);
  return (
    <Layout
      data={mangas}
      makeKey={(i) => i.id}
      render={(manga) => (
        <MangaListItem
          manga={manga}
          detailsPageUrlFactory={props.detailsPageUrlFactory}
          utmSource={utmSource}
          utmListing={utmListing}
        />
      )}
    />
  );
}

export default withError()(MangaListGroupComponent);
