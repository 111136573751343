import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Skeleton from '@material-ui/lab/Skeleton';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import Repeater from '@ttg-media/components/build/Repeater';

export function MangaListGroupLoading() {
  return (
    <Grid container spacing={1}>
      <Repeater
        n={1}
        render={(k) => (
          <Grid item lg={2} md={3} sm={4} xs={6} key={k}>
            <Card>
              <Skeleton variant="rect" height={200} />
              <CardContent>
                <Typography component="h2">
                  <Skeleton />
                </Typography>
                <Skeleton width="80%" />
              </CardContent>
            </Card>
          </Grid>
        )}
      />
    </Grid>
  );
}
