import Uppy from '@uppy/core';
import Vietnam from '@uppy/locales/lib/vi_VN';
import XHRUpload from '@uppy/xhr-upload';
import { getItem } from '@ttg-media/components/build/auth/AuthContext/localStorageClient';
import Dashboard from '@uppy/dashboard';

function createUppyClient() {
  const uppyClient = new Uppy({
    showProgressDetails: true,
    autoProceed: true,
    locale: Vietnam,
    restrictions: {
      maxFileSize: 1.049e7,
      maxNumberOfFiles: 150,
      allowedFileTypes: ['image/*'],
    },
  });
  uppyClient.use(Dashboard, {
    height: 470,
    theme: 'dark',
    note: 'Giới hạn tối đa 150 ảnh/ truyện. Mỗi ảnh dung lượng 10 Mb. Nếu truyện dài hơn vui lòng chia thành truyện dài kỳ bạn nhé 😅',
    closeAfterFinish: true,
    closeModalOnClickOutside: true,
    proudlyDisplayPoweredByUppy: false,
  });

  uppyClient.use(XHRUpload, {
    endpoint: '/upload',
    fieldName: 'file',
    headers: {
      authorization: `Bearer ${getItem('ttg:auth')?.id_token}`,
    },
  });
  return uppyClient;
}
export default createUppyClient;
