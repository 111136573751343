import React from 'react';
import Container from '@material-ui/core/Container';
import { useQuery } from '@apollo/client';
import Box from '@material-ui/core/Box';
import { useParams } from 'react-router-dom';
import { compileUrl } from '../../urls';
import PageHead from '../../components/PageHead';
import LongStoryListPage from './components/LongStoryListPage';
import GET_LONG_STORIES from './GET_LONG_STORIES';
import handleError from '../../components/handleError';
import * as url from '../../urls';

export function MangaNewPage(props) {
  const { title, sort } = props;
  const params = useParams();
  const page = parseInt(params.page, 10) || 1;

  const { data, error, loading } = useQuery(GET_LONG_STORIES, {
    variables: {
      filter: {
        sort,
        page,
      },
    },
  });

  return (
    handleError({ error, loading }) || (
      <Box>
        <Container>
          <PageHead title={title} />
        </Container>
        <LongStoryListPage
          data={data}
          page={page}
          utmSource="new"
          url={(p) => compileUrl(url.mangaHomeRoute, { page: p })}
        />
      </Box>
    )
  );
}
