import React from 'react';
import { Route } from 'react-router-dom';
import * as url from '../../urls';
import ManageMyManga from './manage-my-manga';
import EditProfile from './manage-profile/edit';
import Pageviews from './pageview/Pageviews';
import MangaStatusPage from './edit-manga/status/MangaStatusPage';
import EditMangaInfoPage from './edit-manga/edit-info/EditMangaInfoPage';
import ReorderMangaPage from './edit-manga/order-page/ReorderMangaPage';
import AddMangaPage from './edit-manga/add-page/AddMangaPage';
import ChangeMangaCover from './edit-manga/edit-cover/ChangeMangaCover';
import PreviewPage from './edit-manga/preview/PreviewPage';

export default [
  <Route element={<MangaStatusPage />} path={url.mangaStatusPage} />,
  <Route element={<EditMangaInfoPage />} path={url.mangaEditPage} />,
  <Route element={<ReorderMangaPage />} path={url.mangaEditPageOrder} />,
  <Route element={<AddMangaPage />} path={url.mangaAddPage} />,
  <Route element={<ChangeMangaCover />} path={url.mangaEditCover} />,
  <Route element={<PreviewPage />} path={url.mangaPreview} />,
  <Route element={<ManageMyManga />} path={url.manageMyManga} />,

  <Route element={<EditProfile />} path={url.manageProfile.edit} />,
  <Route element={<Pageviews />} path={url.managePageviews} />,
];
