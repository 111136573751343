import React from 'react';
import { compose } from 'redux';
import Link from '@ttg-media/components/build/Link';
import withNotify from '@ttg-media/components/build/hoc/withNotify';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { useAuth } from '@ttg-media/components/build/auth/AuthContext';
import { compileUrl, longStoryRoute, mangaStatusPage } from '../../../urls';
import ButtonConvertOrphanManga from './ButtonConvertOrphanManga';

function MangaRow({ manga }) {
  const { me } = useAuth();
  return (
    <ListItem key={manga.id} button>
      <ListItemIcon>
        <Avatar src={manga.cover.src} variant="rounded" />
      </ListItemIcon>
      <ListItemText
        primary={
          <Link to={compileUrl(mangaStatusPage, { id: manga.id })}>
            {manga.title}
          </Link>
        }
        secondary={
          manga.longStory ? (
            <Typography variant="caption" color="textSecondary">
              {manga.longStory.title} - Chương {manga.chapter + 1}
              {manga.longStory.mangasCount}
            </Typography>
          ) : (
            <div>
              <Typography variant="caption" color="textSecondary">
                <i>Không có truyện dài</i>
              </Typography>
              {' · '}
              <ButtonConvertOrphanManga manga={manga.id} />
              {' · '}
              <Link
                to={compileUrl(longStoryRoute.list, { page: 1, user: me.id })}
              >
                <Typography variant="caption" color="primary">
                  Thêm vào truyện dài
                </Typography>
              </Link>
            </div>
          )
        }
      />
    </ListItem>
  );
}

export function MangaListComponent(props) {
  const { mangas } = props;
  return (
    <List>
      {mangas.map((manga) => (
        <MangaRow manga={manga} key={manga.id} />
      ))}
    </List>
  );
}

export default compose(withNotify())(MangaListComponent);
