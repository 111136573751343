import React from 'react';
import { Route } from 'react-router-dom';
import * as url from '../../urls';
import AdminHome from './home';
import AdminManageManga from './manage-manga';
import AdminListUser from './list-user';
import AdminListManga from './AdminListManga';

export default [
  <Route element={<AdminHome />} path={url.admin.home} />,
  <Route element={<AdminManageManga />} path={url.admin.manageManga} />,
  <Route element={<AdminListUser />} path={url.admin.listUser} />,
  <Route element={<AdminListManga />} path={url.admin.listManga} />,
];
