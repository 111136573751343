import gql from 'graphql-tag';

const TranslatorFragments = gql`
  fragment TranslatorFragments on Translator {
    id
    name
    slug
    about
    facebook
    email
    homePage
  }
`;
export const GET_TRANSLATOR = gql`
  query GetTranslator($id: ID!) {
    translator(id: $id) {
      ...TranslatorFragments
    }
  }
  ${TranslatorFragments}
`;

export const GET_TRANSLATORS = gql`
  query GetTranslators {
    translators {
      items {
        ...TranslatorFragments
      }
    }
  }
  ${TranslatorFragments}
`;

export const EDIT_TRANSLATOR = gql`
  mutation EditTranslator($id: ID!, $translator: TranslatorInput!) {
    editTranslator(id: $id, translator: $translator) {
      ...TranslatorFragments
    }
  }
  ${TranslatorFragments}
`;
