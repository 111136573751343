import React from 'react';
import { useQuery } from '@apollo/client';
import HandleError from '@ttg-media/components/build/HandleError';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Link from '@ttg-media/components/build/Link';
import { useParams } from 'react-router-dom';
import { RequireOwner } from '@ttg-media/components/build/auth';
import { GET_LONG_STORY_DETAILS } from '../longStoryFragments';
import { compileUrl, longStoryRoute, mangaDetailsUrl } from '../../../../urls';
import PageHead from '../../../../components/PageHead';
import NotFoundError from '../../../../data/errors/NotFoundError';

export default function () {
  const params = useParams();
  const { loading, error, data } = useQuery(GET_LONG_STORY_DETAILS, {
    variables: { id: parseInt(params.id, 10) },
  });
  if (loading || error) return <HandleError error={error} loading={loading} />;
  const { longStory } = data;
  if (!longStory) throw new NotFoundError();
  return (
    <Container maxWidth="sm">
      <PageHead
        title={longStory.title}
        breadcrumbs={[<Typography>Truyện dài</Typography>]}
        right={
          <RequireOwner>
            <Link
              to={compileUrl(longStoryRoute.edit, { id: longStory.lid })}
              variant="contained"
            >
              Sửa
            </Link>
          </RequireOwner>
        }
      />
      <Typography>{longStory.description}</Typography>
      <List>
        {longStory.mangas.map((manga, index) => (
          <ListItem
            button
            component={Link}
            to={mangaDetailsUrl(manga)}
            color="primary"
          >
            <Typography>
              Chương {manga.collectionOrder || index + 1} » {manga.title}
            </Typography>
          </ListItem>
        ))}
      </List>
    </Container>
  );
}
