import gql from 'graphql-tag';

export default gql`
  query longStoriesSearch($term: String!) {
    items: longStoriesSearch(term: $term) {
      name
      slug
      id
      createdDate
      mangasCount
      isDeleted
      isReviewed
      createdBy {
        userName
      }
    }
  }
`;
