import React, { useState } from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Pagination from '@material-ui/lab/Pagination';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import CardContent from '@material-ui/core/CardContent';
import Link from '@ttg-media/components/build/Link';
import { AdminTable as Table } from './AdminTable';
import { longStoryDetailsUrl, manageAdminMangaFilters } from '../../../urls';
import HandleError from '../../../components/handleError';
import { SearchBar } from './SearchBar';
import EditLongStoryPage from '../../../modules/longStory/routes/edit-long-story/EditLongStoryPage';
import DeleteLongStoryButton from '../../../modules/longStory/routes/edit-long-story/DeleteLongStoryButton';

const query = gql`
  query longStoriesForAdmin(
    $page: Int!
    $filter: String
    $category: String
    $chaptersCount: String
    $language: String
  ) {
    pagedManga: longStoriesForAdmin(
      page: $page
      filter: $filter
      category: $category
      chaptersCount: $chaptersCount
      language: $language
    ) {
      items {
        id
        lid
        slug
        title
        displayName
        reviewedDate
        isNew
        mangasCount
        createdDate
        cover {
          src
        }
        language {
          id
          name
        }
        createdBy {
          id
          userName
          displayName
        }
        tags {
          id
          slug
          name
          mangasCount
        }
        authors {
          id
          slug
          name
          mangasCount
        }
        description
        slug
        cover {
          src
        }
        isReviewed
        createdDate
        category {
          id
          name
        }
      }
      total
    }
  }
`;
function TextRow(props) {
  const { label, value } = props;
  return (
    <Box>
      <Typography variant="caption" color="textSecondary">
        {label}:{' '}
      </Typography>
      {value}
    </Box>
  );
}
const columns = ({ select, onDeleted }) => [
  {
    Header: 'Action',
    id: 'action',
    accessor: (d) => <Button onClick={() => select(d.lid)}>Edit</Button>,
  },
  {
    Header: 'Cover',
    accessor: (d) => <img src={d.cover.src} alt="" height={100} />,
  },
  {
    Header: 'Title',
    accessor: (d) => (
      <Box>
        <p>
          <Link to={longStoryDetailsUrl(d)} target="_blank">
            {d.title}
          </Link>
        </p>
        <TextRow
          label="Author"
          value={d.authors?.map((a) => (
            <Box mr={0.5} component="span">
              {a.name} ({a.mangasCount})
            </Box>
          ))}
        />
      </Box>
    ),
  },
  {
    Header: 'Description',
    accessor: (d) => (
      <Box>
        <p>{d.description}</p>
        <TextRow
          label="Tag"
          value={d.tags?.map((t) => (
            <Box mr={0.5} component="span">
              #{t.name} ({t.mangasCount})
            </Box>
          ))}
        />
      </Box>
    ),
  },
  { Header: 'Chapters', accessor: 'mangasCount' },
  {
    Header: 'Info',
    id: 'info',
    accessor: (d) => (
      <Box>
        <TextRow label="Category" value={d.category.name} />
        <TextRow label="Language" value={d.language.name} />
        <TextRow label="Uploader" value={d.createdBy?.displayName} />
      </Box>
    ),
  },
  {
    Header: 'Delete',
    id: 'delete',
    accessor: (d) => (
      <Box>
        <DeleteLongStoryButton id={d.lid} onCompleted={onDeleted} />
      </Box>
    ),
  },
];

function AdminListManga() {
  const [editingMangaId, setEditingMangaId] = useState(null);
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState({
    filter: manageAdminMangaFilters.all,
  });

  const { data, loading, error, refetch } = useQuery(query, {
    variables: { ...filter, page },
    fetchPolicy: 'network-only',
  });
  const [open, setOpen] = useState(false);
  return (
    <div>
      <Container maxWidth="lg">
        <Box mb={2}>
          <SearchBar onSearch={(search) => setFilter(search)} />
        </Box>
        {loading || error ? (
          <HandleError error={error} loading={loading} />
        ) : (
          <Card>
            <CardContent>
              <Box mb={2} display="flex" flexDirection="flex-end">
                <Typography>{data.pagedManga.total} items</Typography>
              </Box>
              <Table
                data={data.pagedManga.items || []}
                columns={columns({
                  select: (id) => {
                    setOpen(true);
                    setEditingMangaId(id);
                  },
                  onDeleted: refetch,
                })}
              />
              <Pagination
                onChange={(e, p) => setPage(p)}
                page={page}
                count={Math.ceil(data.pagedManga.total / 12)}
                defaultPage={1}
              />
            </CardContent>
          </Card>
        )}
      </Container>
      <SwipeableDrawer
        anchor="right"
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
      >
        {editingMangaId && (
          <EditLongStoryPage id={editingMangaId} onCompleted={refetch} />
        )}
      </SwipeableDrawer>
    </div>
  );
}

export default AdminListManga;
