import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import AsyncCreatableSelect from '@ttg-media/components/build/FormControls/AsyncCreatableSelect';
import Box from '@material-ui/core/Box';
import { useQuery } from '@apollo/client';
import CircularProgress from '@material-ui/core/CircularProgress';
import { components } from 'react-select';

import Typography from '@material-ui/core/Typography';
import { fromNow } from '@ttg-media/components/build/utils';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Chip from '@material-ui/core/Chip';
import { FormProvider, useForm } from 'react-hook-form';
import InputFormField from '@ttg-media/components/build/FormControls/InputFormField';
import AsyncCreatableSelectFormField from '@ttg-media/components/build/FormControls/AsyncCreatableSelectFormField';
import { joiResolver } from '@hookform/resolvers/joi';
import joi from 'joi';

import ValidationErrorSummary from '@ttg-media/components/build/FormControls/ValidationErrorSummary';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import BaseFormControl from '@ttg-media/components/build/FormControls/BaseFormControl';
import RadioGroupFormField from '@ttg-media/components/build/FormControls/RadioGroupFormField';
import fetchDataFormQuery from './gql/fetchFormDataGql';
import {
  convertToOptions,
  searchAuthors,
  searchLongStories,
  searchTags,
} from './actions';
import { Step1UploadComponent } from '../StepUploadV2';

const fieldNameMapper = {
  title: 'Tên truyện',
  category: 'Thể loại',
  language: 'Ngôn ngữ',
  authors: 'Tác giả',
  tag: 'Tag',
  longStory: 'Truyện dài',
  images: 'Trang truyện',
};
function LongStorySearchBoxOption(props) {
  const { longStory } = props.data;
  if (!longStory) return <components.Option {...props} />;
  return (
    <components.Option {...props}>
      <ListItem dense button>
        <ListItemText
          primary={`#${longStory.id} - ${longStory.name}`}
          secondary={
            <Typography variant="caption" color="inherit">
              đăng bởi {longStory.createdBy?.userName || 'N/A'}{' '}
              {fromNow(longStory.createdDate)}
            </Typography>
          }
        />
        {longStory.isDeleted && (
          <Chip label="Đã xoá" size="small" color="secondary" />
        )}
        {longStory.isReviewed && <Chip label="Đợi duyệt" size="small" />}
      </ListItem>
    </components.Option>
  );
}
function TagSearchBoxOption(props) {
  const { tag } = props.data;
  if (!tag) return <components.Option {...props} />;
  return (
    <components.Option {...props}>
      <Box display="flex">
        <Typography>
          {tag.name} ({tag.mangasCount} truyện)
        </Typography>
      </Box>
    </components.Option>
  );
}
const FormValidationSchema = joi.object({
  title: joi.string().required(),
  description: joi.string(),
  category: joi.string().required(),
  language: joi.string().required(),
  authors: joi.array().items(
    joi.object({
      label: joi.string(),
      value: joi.any(),
      __isNew__: joi.bool(),
      author: joi.any(),
    }),
  ),
  tags: joi.array().items(
    joi.object({
      label: joi.string(),
      value: joi.any(),
      __isNew__: joi.bool(),
      tag: joi.any(),
    }),
  ),
  longStory: joi.object({
    label: joi.string(),
    value: joi.any(),
    __isNew__: joi.bool(),
    longStory: joi.any(),
  }),
  images: joi
    .array()
    .items(
      joi.object({
        id: joi.string(),
        fileId: joi.string(),
        bucket: joi.string().required(),
        storage: joi.string().required(),
        fileKey: joi.string().required(),
        baseFileName: joi.string().required(),
        size: joi.number().required(),
        type: joi.string().required(),
        fileName: joi.string(),
        preview: joi.string(),
        url: joi.string(),
      }),
    )
    .min(1)
    .max(150)
    .messages({
      'array.min': 'Yêu cầu ít nhất 1 trang truyện',
    }),
});
function CreateMangaFormComponent(props) {
  const { submitting } = props;
  const [isLongStory, setLongStory] = useState(false);
  const form = useForm({
    resolver: joiResolver(FormValidationSchema),
  });
  const resp = useQuery(fetchDataFormQuery);
  const { register } = form;
  const { data, loading } = resp;
  if (loading) return <p>Loading...</p>;
  const { categories, languages } = data;
  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(props.onSubmit)}>
        <InputFormField
          name="title"
          type="text"
          label="Tên truyện"
          {...register('title')}
          required={false}
        />
        <InputFormField
          name="description"
          multiline
          label="Miêu tả nội dung truyện"
          {...register('description')}
        />
        <RadioGroupFormField
          name="category"
          label="Thể loại"
          loading={loading}
          items={convertToOptions(categories)}
          row
          {...register('category')}
        />
        <RadioGroupFormField
          name="language"
          label="Ngôn ngữ"
          loading={loading}
          row
          items={convertToOptions(languages)}
          {...register('language')}
        />
        <AsyncCreatableSelectFormField
          name="authors"
          type="text"
          label="Tác giả"
          multi
          defaultOptions
          loadOptions={searchAuthors}
          component={AsyncCreatableSelect}
          {...register('authors')}
        />
        <AsyncCreatableSelectFormField
          name="tags"
          type="text"
          label="Tag"
          multi
          defaultOptions
          loadOptions={searchTags}
          component={AsyncCreatableSelect}
          selectComponents={{ Option: TagSearchBoxOption }}
          {...register('tags')}
        />
        <BaseFormControl>
          <FormControlLabel
            control={
              <Switch
                checked={isLongStory}
                onChange={(e, checked) => setLongStory(checked)}
                name="isLongStory"
              />
            }
            label="Truyện dài kỳ"
          />
        </BaseFormControl>

        {isLongStory && (
          <AsyncCreatableSelectFormField
            name="longStory"
            label="Chọn truyện dài kỳ"
            defaultOptions
            loadOptions={searchLongStories}
            component={AsyncCreatableSelect}
            selectComponents={{ Option: LongStorySearchBoxOption }}
            {...register('longStory')}
            helperText="Khi để trống, hệ thống sẽ tự động tạo mới truyện dài có tên trùng tên truyện"
          />
        )}
        <Step1UploadComponent />
        <Box display="flex" justifyContent="flex-end">
          <Button
            variant="contained"
            type="submit"
            color="primary"
            disabled={submitting}
            endIcon={submitting ? <CircularProgress size={20} /> : <span />}
          >
            Đăng
          </Button>
        </Box>
        <ValidationErrorSummary fieldNameMapper={fieldNameMapper} />
      </form>
    </FormProvider>
  );
}

export default CreateMangaFormComponent;
