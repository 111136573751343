import React, { Component } from 'react';
import { compose } from 'redux';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { BrowseMenu, Categories, Legals, OtherSites, Socials } from '../Header';

export class FooterComponent extends Component {
  render() {
    // const { config } = this.props;
    return (
      <Box py={3} px={1} component="footer">
        <Divider variant="inset" />
        <Container>
          <Grid container>
            <Grid item xs={12} md={5}>
              <Box pt={2}>
                <Typography color="textSecondary">
                  Truyện Tranh Gay là cộng đồng các bạn đam mê truyện tranh lớn
                  nhất Việt Nam. Website đóng vai trò kết nối nhóm dịch và các
                  bạn yêu thích đọc truyện tranh. Bản quyền truyện đăng trên TTG
                  thuộc về nhóm dịch và tác giả
                </Typography>
                <Typography variant="caption" color="textSecondary">
                  Truyện tranh Gay &copy; 2017 - 2020 &middot; Version:{' '}
                  {/* {config.appReleaseVersion} */}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={1}>
              <BrowseMenu variant="list" />
            </Grid>
            <Grid item xs={12} md={1}>
              <Categories variant="list" />
            </Grid>
            <Grid item xs={12} md={1}>
              <OtherSites variant="list" />
            </Grid>
            <Grid item xs={12} md={1}>
              <Socials variant="list" />
            </Grid>
            <Grid item xs={12} md={2}>
              <Legals variant="list" />
            </Grid>
          </Grid>
        </Container>
      </Box>
    );
  }
}

export default compose()(FooterComponent);
