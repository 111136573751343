import gql from 'graphql-tag';

export default gql`
  query myManga($page: Int!, $filter: String) {
    pagedMangas: myManga(page: $page, filter: $filter) {
      count
      mangas {
        id
        slug
        title
        isDeleted
        isReviewed
        cover {
          src
        }
        stats {
          pagesCount
          viewsCount
          commentsCount
        }
        authors {
          value: id
          slug
          label: name
        }
        tags {
          value: id
          slug
          label: name
        }
        isReviewed
        chapter
        isDeleted
        createdDate
        language {
          label: name
        }
        format {
          label: name
        }
        category {
          label: name
        }
        longStory {
          label: name
          title
          mangasCount
        }
        createdBy {
          id
          userName
          displayName
          avatar {
            id
            src
          }
        }
      }
    }
  }
`;
