import React from 'react';
import { Dashboard as DashboardComponent } from '@uppy/react';
import { connect } from 'react-redux';
import { arrayPush, formValueSelector } from 'redux-form';
import { compose } from 'redux';
import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
import { NEW_MANGA_FORM } from '../MangaForm/CreateMangaForm';
import getUppyClient from './uppyClient';

export function convert(file) {
  const resp = file.response.body;
  return {
    id: file.id, // "uppy-z89dzsr9x3z21/jpg-1e-image/jpeg-118091-1558754884000"
    fileName: file.name, // original name "z89dzsr9x3z21.jpg"
    bucket: resp.bucket, // ttg-dev
    storage: resp.storage, // minio
    fileKey: resp.key, // "1581766368145-z89dzsr9x3z21.jpg"
    baseFileName: file.name, // same as original name
    url: resp.url, // "https://i3.truyentranhgay.com/100ppm-underboys-pantsu-danshi-rakugaki-hon-jp-miyu_e70ab33aa1db4511ad83d565197e321c.jpg"
    size: file.size, // 118091
    type: file.type, // "image/jpeg"
    preview: file.preview, // "blob:http://localhost:3000/2eab0721-d9d1-4ce1-a165-1100c9b035c7"
  };
}

export function Step1UploadComponent(props) {
  const uppyClient = getUppyClient();
  const { images } = props;
  if (uppyClient)
    uppyClient.on('complete', (result) => {
      const files = result.successful || [];
      files.forEach((file) => {
        const convertedFile = convert(file);
        props.arrayPush(NEW_MANGA_FORM, 'images', convertedFile);
        if (props.onFinish) {
          props.onFinish(convertedFile);
        }
      });
    });

  return (
    <>
      <link
        href="https://transloadit.edgly.net/releases/uppy/v1.19.2/uppy.min.css"
        rel="stylesheet"
      />
      <DashboardComponent
        uppy={uppyClient}
        theme="dark"
        note="Giới hạn tối đa 150 ảnh/ truyện. Mỗi ảnh dung lượng 10 Mb. Nếu truyện dài hơn vui lòng chia thành truyện dài kỳ bạn nhé 😅"
      />
      {(!images || images.length === 0) && (
        <Box mt={2}>
          <Alert severity="warning" variant="outlined">
            Cần ít nhất 1 trang truyện
          </Alert>
        </Box>
      )}
    </>
  );
}

export const convertFileToGqlInput = (file) => ({
  id: file.id, // "uppy-z89dzsr9x3z21/jpg-1e-image/jpeg-118091-1558754884000"
  fileName: file.fileName, // original name "z89dzsr9x3z21.jpg"
  bucket: file.bucket, // ttg-dev
  storage: file.storage, // minio
  fileKey: file.fileKey, // "1581766368145-z89dzsr9x3z21.jpg"
  baseFileName: file.baseFileName, // same as original name
  size: file.size, // 118091
  type: file.type, // "image/jpeg"
});

const newMangaFormSelector = formValueSelector(NEW_MANGA_FORM);

export function mapStateToProps(state) {
  const images = newMangaFormSelector(state, 'images');
  const cover = newMangaFormSelector(state, 'cover');
  return { images, cover };
}
export default compose(connect(mapStateToProps, { arrayPush }))(
  Step1UploadComponent,
);
