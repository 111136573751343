import React, { useEffect } from 'react';
import ScrollUpButton from 'react-scroll-up-button';
import LazyLoad from 'react-lazyload';
import SeoHeaders from '@ttg-media/components/build/SeoHeaders';

import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Title from '@ttg-media/components/build/Title';
import { useQuery } from '@apollo/client';
import HandleError from '@ttg-media/components/build/HandleError';
import Pixel from '@ttg-media/components/build/PageViewPixel';
import { useParams } from 'react-router-dom';
import { ga4SendEvent } from '@ttg-media/components/build/analyticsHelpers';
import Comments from '@ttg-media/comment/build/Comments/Comments';
import gql from 'graphql-tag';
import Pages from './components/Pages';
import seoTemplates from '../../seoTemplate.json';
import MangaInfo from './components/Manga/MangaInfo';
import MangaRecommendations from './components/MangaRecommendations';
import { compileSeo } from '../../utils';
import AuthorRecommendations from './components/AuthorRecommendations';
import UserRecommendations from './components/UserRecommendations';

import MangaReport from './components/MangaReport';
import useQueryString from '../list/useQueryString';
import { sendCustomAnalyticsEvent } from '../../components/sendCustomAnalyticsEvent';
import { MangaDetailsParts } from '../../gql/fragments';

const FETCH_MANGA_QUERY = gql`
  query manga($id: Int!) {
    manga(id: $id) {
      ...MangaDetailsParts
    }
    longStory: longStoryByManga(mangaId: $id) {
      id
      lid
      title
      slug
      description
      mangas {
        id
        lid
        slug
        title
      }
    }
  }

  ${MangaDetailsParts}
`;

export default function MangaDetailsPage() {
  const params = useParams();
  const query = useQueryString();
  const { part, pagesPerPart, preview } = params;
  const id = parseInt(params.id, 10);
  useEffect(() => {
    if (typeof window !== 'undefined') {
      ga4SendEvent('manga_read', { event_category: 'manga' });
      if (query.listing_id) {
        const listingIds = query.listing_id.split(',');
        listingIds.forEach((listingId) => {
          sendCustomAnalyticsEvent('manga.log', {
            event_action: 'manga_read',
            manga_id: id,
            source: query.from,
            listing_id: listingId,
            experiment_id: query.experiment_id,
            variant: query.variant,
          });
        });
      } else if (query.from) {
        sendCustomAnalyticsEvent('manga.log', {
          event_action: 'manga_read',
          manga_id: id,
          source: query.from,
          experiment_id: query.experiment_id,
          variant: query.variant,
        });
      }
    }
    return null;
  }, []);
  const { data, loading, error } = useQuery(FETCH_MANGA_QUERY, {
    variables: { id },
  });
  if (loading || error) return <HandleError loading={loading} error={error} />;
  const { manga, longStory } = data;
  if (!manga || manga.isDeleted || manga.dcmaViolation) {
    throw new Error('NotFoundError');
  }
  const author = manga.authors?.[0];
  return (
    <>
      <Pixel id={manga.lid} type="manga" />
      <SeoHeaders seo={compileSeo(seoTemplates.manga, manga)} />
      <Box mb={2}>
        <MangaInfo manga={manga} preview={preview} longStory={longStory} />
      </Box>

      <Pages manga={manga} part={part} pagesPerPart={pagesPerPart} />
      <Box display="flex" justifyContent="space-around">
        <MangaReport manga={manga} />
      </Box>
      <MangaInfo manga={manga} variant="compact" longStory={longStory} />
      <ScrollUpButton ToggledStyle={{ left: 16 }} />
      <Container maxWidth="md">
        <Box>
          <LazyLoad>
            {author ? (
              <Box mb={4}>
                <AuthorRecommendations author={author} />
              </Box>
            ) : (
              <div />
            )}
          </LazyLoad>
          <LazyLoad>
            <Box mb={4}>
              {manga.createdBy ? (
                <UserRecommendations user={manga.createdBy} />
              ) : (
                <div />
              )}
            </Box>
          </LazyLoad>
          <LazyLoad>
            <Box mb={4}>
              <MangaRecommendations manga={manga} />
            </Box>
          </LazyLoad>
        </Box>
        <LazyLoad>
          <Box mb={4}>
            <Title>Bình luận</Title>
            {manga.createdBy ? (
              <Comments
                threadId={manga.lid}
                threadOwner={manga.createdBy.lid}
                threadType="MANGA"
              />
            ) : (
              <div />
            )}
          </Box>
        </LazyLoad>
      </Container>
    </>
  );
}
