import React from 'react';
import { useQuery } from '@apollo/client';
import HandleError from '@ttg-media/components/build/HandleError';
import Link from '@ttg-media/components/build/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { fromNow } from '@ttg-media/components/build/utils';
import Pager from '@ttg-media/components/build/Pager';
import { GET_LONG_STORIES } from '../longStoryFragments';
import { LongStoryLinks } from '../longStoryLinks';
import { compileUrl } from '../../../../urls';
import { usePager } from '../../../../components/usePager';
import longStoryUrls from '../longStoryUrls';

export default function ({ user }) {
  const { page } = usePager();
  const { loading, data, error } = useQuery(GET_LONG_STORIES, {
    variables: { user, query: { page } },
  });
  if (loading || error) return <HandleError loading={loading} error={error} />;
  const {
    longStories: { items, total },
  } = data;
  return (
    <Container>
      <LongStoryLinks.Create />
      <List>
        {items.map((longStory) => (
          <ListItem
            key={longStory.id}
            button
            component={Link}
            to={compileUrl(longStoryUrls.edit, { id: longStory.lid })}
          >
            <ListItemText
              primary={longStory.title}
              secondary={
                <Typography variant="caption" color="textSecondary">
                  {longStory.mangasCount} chương
                  {' · '}
                  {fromNow(longStory.updatedDate)}
                </Typography>
              }
            />
          </ListItem>
        ))}
      </List>
      <Pager
        page={page}
        totalPage={Math.round(total / 24)}
        url={(p) => compileUrl(longStoryUrls.list, { page: p, user })}
      />
    </Container>
  );
}
