import React from 'react';
import { useAuth } from '@ttg-media/components/build/auth/AuthContext';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Slide from '@material-ui/core/Slide';
import Typography from '@material-ui/core/Typography';
import BackIcon from '@material-ui/icons/ArrowBack';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import MangaReportForm from './MangaReportForm';
import createStrapiBrowserClient from '../../../../components/createStrapiBrowserClient';
import handleMutationError from '../../../../components/handleMutationError';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    paddingTop: theme.spacing(8),
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));
export default function (props) {
  const { me } = useAuth();

  const { mutateAsync: mutate } = useMutation((values) =>
    createStrapiBrowserClient().post('/reports', {
      data: {
        description: values.description,
        errorType: values.errorType,
        reportedByEmail: me?.email || values.email,
        reportedItemId: props.manga.lid,
        reportedBy: me?.lid,
        reportedItemType: 'manga',
        createdDate: Date.now(),
      },
    }),
  );
  const [open, setOpen] = React.useState(false);
  const css = useStyles();
  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleSubmit = (values) =>
    mutate(values)
      .then(() => {
        toast.success(
          'Thành công! Hệ thống đã tạo yêu cầu hỗ trợ và gửi đến cho admin. Cám ơn bạn đã báo lỗi',
        );
        setOpen(false);
      })
      .catch(handleMutationError);
  return (
    <div>
      <Button
        onClick={handleClickOpen}
        size="small"
        startIcon={<ErrorOutline />}
        name="report-error"
      >
        Báo lỗi
      </Button>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        className={css.appBar}
      >
        <AppBar color="inherit">
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <BackIcon />
            </IconButton>
            <Typography variant="h6" className={css.title}>
              Báo lỗi
            </Typography>
          </Toolbar>
        </AppBar>
        <Container maxWidth="md">
          <MangaReportForm
            onSubmit={handleSubmit}
            initialValues={{ email: me?.email }}
          />
        </Container>
      </Dialog>
    </div>
  );
}
