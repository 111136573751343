import React from 'react';
import Container from '@material-ui/core/Container';
import Dcma from './dcma-notice.md';

export default function () {
  return (
    <Container maxWidth="md">
      <Dcma />
    </Container>
  );
}
