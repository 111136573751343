import React from 'react';
import { useMutation } from '@apollo/client';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { QuickReview } from '../gql';
import { ButtonAction } from '../../../../components/ButtonAction';

function Component({ manga }) {
  const mutation = useMutation(QuickReview, {
    variables: { manga: manga.id },
  });

  return (
    <ButtonAction
      mutation={mutation}
      variant="text"
      size="small"
      component={Link}
    >
      <Typography variant="caption">Duyệt nhanh</Typography>
    </ButtonAction>
  );
}
export default Component;
