import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import React from 'react';
import Link from '@ttg-media/components/build/Link';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import { compileUrl, manageProfile } from '../../../urls';

export default function ({ route, id, children }) {
  return (
    <Container maxWidth="md">
      <Grid container spacing={1}>
        <Grid item md={3} sx={12} sm={12}>
          <Card>
            <List>
              <ListItem
                button
                component={Link}
                to={compileUrl(manageProfile.edit, { id })}
                selected={route === manageProfile.edit}
              >
                <ListItemText primary="Thông tin cá nhân" />
              </ListItem>
              <ListItem
                button
                component={Link}
                to={compileUrl(manageProfile.changePassword, { id })}
                selected={route === manageProfile.changePassword}
              >
                <ListItemText primary="Đổi mật khẩu" />
              </ListItem>
            </List>
          </Card>
        </Grid>
        <Grid item md={9} sx={12} sm={12}>
          <Paper>{children}</Paper>
        </Grid>
      </Grid>
    </Container>
  );
}
