import LocalStorageDriver from '@ttg-media/components/build/localStorageDriver';
import _find from 'lodash/find';

const experiments = {
  TagOne: { id: 'tag-exp-1', variantsCount: 3 },
  Tag2: { id: 'tag-exp-2', variantsCount: 2 },
  Author1: { id: 'author-exp-1', variantsCount: 2 },
};
export default experiments;

export const getExperimentVariant = (experimentId) => {
  if (!experimentId) return null;
  if (typeof window === 'undefined') return null;
  const experimentKey = `ttg:experiments:${experimentId}`;
  const variant = LocalStorageDriver.getItem(experimentKey);
  if (!variant) {
    const foundExperiment = _find(experiments, { id: experimentId });
    const newVariant = Math.floor(
      Math.random() * foundExperiment.variantsCount,
    );
    LocalStorageDriver.setItem(experimentKey, newVariant);
    return newVariant;
  }
  return parseInt(variant, 10);
};
export const setExperimentVariant = (experimentId, variant) => {
  const experimentKey = `ttg:experiments:${experimentId}`;

  return LocalStorageDriver.setItem(experimentKey, variant);
};
