import React from 'react';

import { NotificationsList } from '@ttg-media/notification/build/NotificationsCounter/NotificationsList';
import Container from '@material-ui/core/Container';

export default function () {
  return (
    <Container>
      <NotificationsList />
    </Container>
  );
}
