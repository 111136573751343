import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import React from 'react';
import Link from '@ttg-media/components/build/Link';
import { admin, compileUrl, manageAdminMangaFilters } from '../../../urls';

export function ManageManageTabs({ filter }) {
  return (
    <Tabs value={filter}>
      <Tab
        value={manageAdminMangaFilters.unreview}
        component={Link}
        to={compileUrl(admin.manageManga, {
          filter: manageAdminMangaFilters.unreview,
        })}
        label="Đợi duyệt"
      />
      <Tab
        component={Link}
        value={manageAdminMangaFilters.deleted}
        to={compileUrl(admin.manageManga, {
          filter: manageAdminMangaFilters.deleted,
        })}
        label="Đã xoá"
      />
      <Tab
        component={Link}
        value={manageAdminMangaFilters.all}
        to={compileUrl(admin.manageManga, {
          filter: manageAdminMangaFilters.all,
        })}
        label="Toàn bộ"
      />
    </Tabs>
  );
}
