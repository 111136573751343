import React from 'react';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import Field from 'redux-form/lib/Field';
import AsyncCreatableSelect from '@ttg-media/components/build/FormControls/AsyncCreatableSelect';
import { reduxForm } from 'redux-form';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useMutation } from '@apollo/client';
import withNotify from '@ttg-media/components/build/hoc/withNotify';
import { searchAuthors } from '../user/post-manga/MangaForm/actions';
import { TRANSFER_AUTHOR } from './authorGql';

const TransferAuthorForm = reduxForm({
  form: 'TRANSFER_AUTHOR',
})((props) => {
  const { handleSubmit } = props;
  return (
    <form onSubmit={handleSubmit}>
      <Field
        name="fromAuthors"
        type="text"
        label="Transfer from"
        required
        multi
        loadOptions={searchAuthors}
        component={AsyncCreatableSelect}
      />
      <Field
        name="toAuthors"
        type="text"
        label="To"
        required
        multi
        loadOptions={searchAuthors}
        component={AsyncCreatableSelect}
      />

      <Box display="flex" justifyContent="flex-end">
        <Button variant="contained" type="submit" color="primary">
          Đăng
        </Button>
      </Box>
    </form>
  );
});

function ButtonTransferTag(props) {
  const { author, exec } = props;
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [mutate] = useMutation(TRANSFER_AUTHOR);
  const onSubmit = ({ fromAuthors, toAuthors }) => {
    exec(
      mutate({
        variables: {
          fromAuthors: fromAuthors
            ? fromAuthors.map((t) => t.value)
            : [author.id],
          toAuthors: toAuthors.map((t) => t.value),
        },
      }),
    );
  };
  return (
    <>
      <Button onClick={handleClickOpen}>Transfer</Button>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          {author.id} - {author.name}
        </DialogTitle>
        <DialogContent>
          <TransferAuthorForm onSubmit={onSubmit} />
        </DialogContent>
      </Dialog>
    </>
  );
}

export default withNotify()(ButtonTransferTag);
