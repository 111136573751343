import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import HandleError from '@ttg-media/components/build/HandleError';
import Pager from '@ttg-media/components/build/Pager';
import Container from '@material-ui/core/Container';

import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { MangaParts } from '../../../gql/fragments';
import MangaListGroup from '../../../components/MangaListGroup/MangaListGroup';
import { MangaListGroupLoading } from '../../../components/MangaListGroup/MangaListGroupLoading';

const GET_MANGA_LIST = gql`
  query GetMangaList($filter: MangaFilter) {
    mangaList(filter: $filter) {
      mangas {
        ...MangaParts
      }
      count
    }
  }
  ${MangaParts}
`;
export default function MangaList(props) {
  const {
    page,
    status,
    category,
    author,
    tag,
    sort,
    userName,
    translator,
    name,
  } = props;
  const { data, loading, error } = useQuery(props.query || GET_MANGA_LIST, {
    variables: props.variables || {
      filter: {
        category,
        translator,
        author,
        tag,
        status,
        sort,
        userName,
        page,
      },
      page,
    },
  });
  if (error || loading)
    return (
      <HandleError
        loading={loading}
        error={error}
        placeholder={<MangaListGroupLoading />}
      />
    );

  const { mangaList } = data;
  return (
    <Container>
      {mangaList.count > 0 ? (
        <>
          <MangaListGroup
            mangaList={mangaList}
            name={name}
            page={page}
            limit={12}
          />
          <Box my={2}>
            <Pager
              page={page}
              url={props.url}
              totalPage={Math.ceil(mangaList.count / 12)}
            />
          </Box>
        </>
      ) : (
        <Box display="flex" justifyContent="center" p={3}>
          <Typography color="textSecondary">Không có truyện nào</Typography>
        </Box>
      )}
    </Container>
  );
}
