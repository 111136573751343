import React from 'react';
import Container from '@material-ui/core/Container';
import Tos from './terms-of-service.md';

export default function () {
  return (
    <Container maxWidth="md">
      <Tos />
    </Container>
  );
}
