import React from 'react';
import { useParams } from 'react-router-dom';
import EditLongStoryPage from './EditLongStoryPage';
import EditLongStoryLayout from '../EditLongStoryLayout';
import longStoryRoute from '../longStoryUrls';

export default function action() {
  const params = useParams();
  const { id } = params;
  const title = 'Sửa thông tin bộ truyện';
  return (
    <EditLongStoryLayout title={title} route={longStoryRoute.edit} id={id}>
      <EditLongStoryPage id={id} />
    </EditLongStoryLayout>
  );
}
