import React from 'react';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import HandleError from '@ttg-media/components/build/HandleError';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Link from '@ttg-media/components/build/Link';
import Typography from '@material-ui/core/Typography';
import { kFormatter } from '@ttg-media/components/build/utils';
import { elipsis } from '@ttg-media/components/build/SeoHeaders';
import CardActionArea from '@material-ui/core/CardActionArea';
import Box from '@material-ui/core/Box';
import CardMedia from '@material-ui/core/CardMedia';
import Carousel from '@ttg-media/components/build/Carousel';
import { mangaListUrl } from '../../urls';
import LoadingCard from './LoadingCard';
import { Covers } from './Covers';

const query = gql`
  query HotTags {
    authors: hotAuthors {
      total
      items {
        id
        slug
        name
        mangasCount
        pageView
        covers
      }
    }
  }
`;
export default function () {
  const { loading, data, error } = useQuery(query);
  if (loading || error)
    return (
      <HandleError
        loading={loading}
        error={error}
        placeholder={
          <Carousel total={10}>
            {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map(() => (
              <LoadingCard />
            ))}
          </Carousel>
        }
      />
    );
  const { items } = data.authors;
  return (
    <Carousel total={10}>
      {items.map((author) => (
        <Box key={author.id} pr={1}>
          <Card>
            <CardMedia>
              <Covers covers={author.covers} />
            </CardMedia>
            <CardActionArea
              component={Link}
              to={mangaListUrl({ author: author.id })}
            >
              <CardContent>
                <Typography variant="h6" component="h2" align="center">
                  {elipsis(author.name, 13)}
                </Typography>
                <Typography color="textSecondary" align="center">
                  <small>
                    {author.mangasCount} truyện{' · '}
                    {kFormatter(author.pageView)} view
                  </small>
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Box>
      ))}
    </Carousel>
  );
}
