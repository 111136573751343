import Uppy from '@uppy/core';
import Vietnam from '@uppy/locales/lib/vi_VN';
import GoogleDrive from '@uppy/google-drive';
import XHRUpload from '@uppy/xhr-upload';
import { getItem } from '@ttg-media/components/build/auth/AuthContext/localStorageClient';

function createUppyClient() {
  const uppyClient = new Uppy({
    showProgressDetails: true,
    autoProceed: false,
    locale: Vietnam,
    restrictions: {
      maxFileSize: 1.049e7,
      maxNumberOfFiles: 150,
      allowedFileTypes: ['image/*'],
    },
  });

  uppyClient.use(GoogleDrive, {
    companionUrl: 'https://companion.uppy.io',
  });
  const token = getItem('ttg:auth')?.id_token;
  uppyClient.use(XHRUpload, {
    endpoint: '/upload',
    fieldName: 'file',
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
  return uppyClient;
}
export default createUppyClient;
