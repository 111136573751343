import React from 'react';
import CardContent from '@material-ui/core/CardContent';
import Skeleton from '@material-ui/lab/Skeleton';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import Box from '@material-ui/core/Box';
import { Covers } from './Covers';

const onePx =
  'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==\n';
export default function () {
  return (
    <Card style={{ maxWidth: 300, width: '100%' }}>
      <CardMedia>
        <Covers covers={[onePx, onePx, onePx]} />
      </CardMedia>
      <CardContent>
        <Box display="flex" justifyContent="space-around">
          <Skeleton width={180} />
        </Box>
        <Box display="flex" justifyContent="space-around">
          <small>
            <Skeleton width={240} />
          </small>
        </Box>
      </CardContent>
    </Card>
  );
}
