import React, { useState } from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import LazyLoad from 'react-lazyload';
import List from '@material-ui/core/List';
import Switch from '@material-ui/core/Switch';
import { toast } from 'react-toastify';
import experiments, {
  getExperimentVariant,
  setExperimentVariant,
} from '../../experiments';

export function ExperimentItem({ id }) {
  const [variant, setVariant] = useState(getExperimentVariant(id) === 1);

  const toggle = (e) => {
    setExperimentVariant(id, e.target.checked ? 1 : 0);
    setVariant(e.target.checked);
    toast.success('Cập nhật thành công. Tải lại trang để thấy thay đổi');
  };
  return <Switch checked={variant} onChange={toggle} />;
}
export default function () {
  return (
    <Container>
      <Typography>Ab testing</Typography>
      <LazyLoad>
        <List>
          {Object.keys(experiments).map((key) => {
            const exp = experiments[key];
            return (
              <ListItem>
                <ListItemText primary={exp.id} />
                <ListItemSecondaryAction>
                  <ExperimentItem id={exp.id} key={exp.id} />
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </List>
      </LazyLoad>
    </Container>
  );
}
