import React from 'react';
import Container from '@material-ui/core/Container';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import withNotify from '@ttg-media/components/build/hoc/withNotify';
import PageHead from '@ttg-media/components/build/PageHead';
import { convertFromValues, CreateLongStoryForm } from '../LongStoryForm';
import { compileUrl } from '../../../../urls';
import myHistory from '../../../../myHistory';
import { LongStoryLinks } from '../longStoryLinks';
import longStoryRoute from '../longStoryUrls';

const CREATE_LONG_STORY_GQL = gql`
  mutation CreateLongStory($longStory: LongStoryParams!) {
    longStory: createLongStory(longStory: $longStory) {
      id
      lid
    }
  }
`;
function CreateLongStory(props) {
  const [mutation, { loading, error }] = useMutation(CREATE_LONG_STORY_GQL);
  const { exec } = props;
  const onSubmit = (values) =>
    exec(
      mutation({ variables: { longStory: convertFromValues(values) } }).then(
        (resp) => {
          const { lid } = resp.data.longStory;
          return myHistory.push(compileUrl(longStoryRoute.edit, { id: lid }));
        },
      ),
    );
  return (
    <Container>
      <PageHead
        title="Tạo mới truyện dài"
        breadcrumbs={[<LongStoryLinks.List variant="link" />]}
      />
      <CreateLongStoryForm
        onSubmit={onSubmit}
        loading={loading}
        error={error}
      />
    </Container>
  );
}
export default withNotify()(CreateLongStory);
