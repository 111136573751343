import React from 'react';
import { DELETE_AUTHORS } from './authorGql';
import SimpleMutationButton from '../admin/SimpleMutationButton';

export default function ({ author }) {
  return (
    <SimpleMutationButton
      mutation={DELETE_AUTHORS}
      variables={{ authors: [author.lid] }}
    >
      Xoá
    </SimpleMutationButton>
  );
}
