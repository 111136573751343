import React from 'react';
import { useQuery } from '@apollo/client';
import HandleError from '@ttg-media/components/build/HandleError';
import { GET_LONG_STORIES_FOR_HOME } from '../longStoryFragments';
import MangaList from '../../../../pages/list/components/MangaListBare';
import { longStoryDetailsUrl, longStoryHomeUrl } from '../../../../urls';

function LongStoriesPage({ page }) {
  const { data, loading, error } = useQuery(GET_LONG_STORIES_FOR_HOME, {
    variables: { query: { page } },
  });
  if (loading || error) return <HandleError loading={loading} error={error} />;
  const { longStories } = data;
  return (
    <MangaList
      page={page}
      data={{
        mangaList: {
          mangas: longStories.items,
          count: longStories.total,
        },
      }}
      url={longStoryHomeUrl}
      detailsPageUrlFactory={longStoryDetailsUrl}
    />
  );
}

export default LongStoriesPage;
