import React from 'react';
import { useParams } from 'react-router-dom';
import ListUserPage from './ListUserPage';
import AdminLayout from '../AdminLayout';
import { admin } from '../../../urls';

export default function action() {
  const params = useParams();
  const page = Number.parseInt(params.page || 1, 10);
  const user = Number.parseInt(page.user, 10);
  return (
    <AdminLayout title="Quản lý người dùng" route={admin.listUser}>
      <ListUserPage page={page} user={user} />
    </AdminLayout>
  );
}
