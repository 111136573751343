import Grid from '@material-ui/core/Grid';
import React from 'react';

export function GridLayout({ data, makeKey, render }) {
  return (
    <Grid container spacing={2}>
      {data?.map((item, index) => (
        <Grid item lg={2} md={2} sm={4} xs={6} key={makeKey(item)}>
          {render(item, index)}
        </Grid>
      ))}
    </Grid>
  );
}
