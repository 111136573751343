
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`DMCA Notice`}</h1>
    <p>{`Since we respect artist and content owner rights, it is the site’ policy to respond to alleged infringement notices that comply with the Digital Millennium Copyright Act of 1998 (“DMCA”). If you believe that your copyrighted work has been copied in a way that constitutes copyright infringement and is accessible via the Service, please notify MyReadingManga’ copyright agent as set forth in the DMCA. For your complaint to be valid under the DMCA, you must provide the following information in writing:`}</p>
    <ul>
      <li parentName="ul">{`An electronic or physical signature of a person authorized to act on behalf of the copyright owner;`}</li>
      <li parentName="ul">{`Identification of the copyrighted work that you claim has been infringed;`}</li>
      <li parentName="ul">{`Identification of the material that is claimed to be infringing and where it is located on the Service;`}</li>
      <li parentName="ul">{`Information reasonably sufficient to permit MyReadingManga to contact you, such as your address, telephone number, and, e-mail address;`}</li>
      <li parentName="ul">{`A statement that you have a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent, or law; and`}</li>
      <li parentName="ul">{`A statement, made under penalty of perjury, that the above information is accurate, and that you are the copyright owner or are authorized to act on behalf of the owner.
The above information must be submitted via contact with admin.`}</li>
    </ul>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;