import React from 'react';
import gql from 'graphql-tag';

import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import Container from '@material-ui/core/Container';
import LazyLoad from 'react-lazyload';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import _compact from 'lodash/compact';
import Link from '@ttg-media/components/build/Link';
import PageHead from '../../components/PageHead';
import { tagDetailsUrl, tagListUrl } from '../../urls';
import LongStoryListPage from '../list/components/LongStoryListPage';
import handleError from '../../components/handleError';
import GET_LONG_STORIES from '../list/GET_LONG_STORIES';
import useQueryString from '../list/useQueryString';
import { PAGE_SIZE } from '../../constants';
import QuickFilters from './QuickTagFilters';
import ExperimentSwitcher from '../ab-testing/ExperimentSwitcher';

const GET_TAG_DETAILS_GQL = gql`
  query GetLongStoryList($tagId: Int!) {
    tagInfo: tag(id: $tagId) {
      tag {
        slug
        name
      }
    }
  }
`;

function TagDetailsPage(props) {
  const { experimentId, variant } = props;

  const params = useParams();
  const query = useQueryString();
  const { page, language, category } = query;
  const id = parseInt(params.id, 10);
  const listingIdSubSection =
    category || language
      ? _compact([`tag-${id}`, category, language]).join('-')
      : null;
  const tagResp = useQuery(GET_TAG_DETAILS_GQL, {
    variables: {
      tagId: id,
    },
  });
  const { data, error, loading } = useQuery(GET_LONG_STORIES, {
    variables: {
      filter: {
        tag: id,
        page,
        status: language,
        category,
      },
    },
  });

  const alt = handleError(tagResp) || handleError({ error, loading });
  if (alt) return alt;
  const { tag } = tagResp.data.tagInfo;
  const utmListing = {
    total_items: data.longStories.total,
    total_pages: Math.ceil(data.longStories.total / PAGE_SIZE),
    current_page: page,
    offset: (page - 1) * PAGE_SIZE + 1,
    listing_id: _compact([`tag-${id}`, listingIdSubSection]).join(','),
    listing_type: 'tag',
    listing_item_id: id,
    experiment_id: experimentId,
    variant,
  };
  return (
    <Container>
      <Box mb={2}>
        <ExperimentSwitcher experimentId={props.experimentId} />
      </Box>
      <PageHead
        title={`Tag ${tag.name}`}
        right={
          <Typography variant="caption">
            {data.longStories.total} truyện
          </Typography>
        }
        breadcrums={[
          <Link to={tagListUrl()}>Danh sách tag</Link>,
          <Typography>{tag.name}</Typography>,
        ]}
      />
      <LazyLoad>
        <QuickFilters
          language={language}
          category={category}
          id={id}
          tag={tag}
          variant={variant}
        />
      </LazyLoad>
      <Box mt={2}>
        <LongStoryListPage
          data={data}
          page={page}
          url={(p) => tagDetailsUrl({ id }, { page: p, language, category })}
          utmSource="tag"
          utmListing={utmListing}
        />
      </Box>
    </Container>
  );
}

export default TagDetailsPage;
