import React from 'react';
import Button from '@material-ui/core/Button';
import { gql } from '@apollo/client/core';
import { useAuth } from '@ttg-media/components/build/auth/AuthContext';
import CircularProgress from '@material-ui/core/CircularProgress';
import { compileUrl } from '../../../../urls';
import myHistory from '../../../../myHistory';
import longStoryRoute from '../longStoryUrls';
import { useToastMutation } from '../../../../components/toastify';

const DELETE_LONGSTORY = gql`
  mutation DeleteLongStory($id: ID!) {
    deleteLongStory(id: $id) {
      id
      lid
    }
  }
`;
export default function (props) {
  const { id } = props;
  const [deleteLongStory, { loading }] = useToastMutation(DELETE_LONGSTORY, {
    variables: { id },
  });
  const { me } = useAuth();
  const onCompleted =
    props.onCompleted ||
    (() =>
      myHistory.push(
        compileUrl(longStoryRoute.list, { user: me.id, page: 1 }),
      ));
  const onClick = () => deleteLongStory().then(onCompleted);

  return (
    <Button
      color="secondary"
      variant="outlined"
      onClick={onClick}
      startIcon={loading ? <CircularProgress size={20} /> : ''}
    >
      Xoá
    </Button>
  );
}
