import Box from '@material-ui/core/Box';
import React from 'react';
import Carousel from '@ttg-media/components/build/Carousel';

export function CarouselLayout({ data, makeKey, render }) {
  return (
    <Carousel total={data?.length}>
      {data?.map((item, index) => (
        <Box key={makeKey(item)} pr={1}>
          {render(item, index)}
        </Box>
      ))}
    </Carousel>
  );
}
