import React from 'react';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import HandleError from '@ttg-media/components/build/HandleError';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Link from '@ttg-media/components/build/Link';
import Typography from '@material-ui/core/Typography';
import { kFormatter } from '@ttg-media/components/build/utils';
import Box from '@material-ui/core/Box';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import Repeater from '@ttg-media/components/build/Repeater';
import Carousel from '@ttg-media/components/build/Carousel';
import { mangaListUrl } from '../../urls';
import LoadingCard from './LoadingCard';
import { Covers } from './Covers';

const query = gql`
  query HotTags {
    tags: hotTags {
      total
      items {
        id
        slug
        name
        mangasCount
        pageView
        covers
      }
    }
  }
`;

export default function () {
  const { loading, data, error } = useQuery(query);
  if (loading || error)
    return (
      <HandleError
        loading={loading}
        error={error}
        placeholder={
          <Repeater n={1} render={(key) => <LoadingCard key={key} />} />
        }
      />
    );
  const tags = data.tags.items;
  return (
    <Carousel total={tags?.length}>
      {tags?.map((tag) => (
        <Box key={tag.id} pr={1}>
          <Card>
            <CardMedia>
              <Covers covers={tag.covers} />
            </CardMedia>
            <CardActionArea component={Link} to={mangaListUrl({ tag: tag.id })}>
              <CardContent>
                <Typography variant="h6" component="h2" align="center">
                  #{tag.name}
                </Typography>
                <Typography color="textSecondary" align="center">
                  <small>
                    {tag.mangasCount} truyện{' · '}
                    {kFormatter(tag.pageView)} view
                  </small>
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Box>
      ))}
    </Carousel>
  );
}
