import React from 'react';
import Box from '@material-ui/core/Box';
import Header from './Header';
import Footer from './Footer';

function LayoutComponent(props) {
  return (
    <div>
      <Header path={props.path} hideSecondaryNav={props.hideSecondaryNav} />
      <Box pt={props.noPadding ? 0 : 5} pb={5}>
        {props.children}
      </Box>

      <Footer />
    </div>
  );
}

export default LayoutComponent;
