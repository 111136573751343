import Button from '@material-ui/core/Button';
import CloudDownload from '@material-ui/icons/CloudDownload';
import React from 'react';
import { compileUrl } from '../../urls';

export function DownloadButton({ manga, size }) {
  return (
    <Button
      disabled={!manga.canDownload}
      component="a"
      href={compileUrl('/download/:id', { id: manga.lid })}
      startIcon={<CloudDownload />}
      rel="nofollow"
      size={size}
    >
      {manga.stats?.downloadsCount}
    </Button>
  );
}
