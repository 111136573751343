import React from 'react';
import { DELETE_TAGS } from './tagGql';
import SimpleMutationButton from '../admin/SimpleMutationButton';

export default function ({ tag }) {
  return (
    <SimpleMutationButton
      mutation={DELETE_TAGS}
      variables={{ tags: [tag.lid] }}
    >
      Xoá
    </SimpleMutationButton>
  );
}
