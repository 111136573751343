import React from 'react';
import Link from '@ttg-media/components/build/Link';
import CardMedia from '@material-ui/core/CardMedia';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { fromNow, kFormatter } from '@ttg-media/components/build/utils';
import { ga4SendEvent } from '@ttg-media/components/build/analyticsHelpers';
import { mangaDetailsUrl } from '../../urls';
import { LongStoryParts } from '../../gql/fragments';

const useStyles = makeStyles({
  media: {
    height: 0,
    paddingTop: '137.55%', // 16:9,
  },
});

function LongStoryListItem(props) {
  const { longStory, utmSource, utmListing } = props;
  if (longStory.latestChapters.length === 0) {
    console.error('long story is empty', longStory.id);
    return <span />;
  }
  const css = useStyles();
  const { reviewedDate } = longStory;
  const { latestChapters: mangas } = longStory;
  const manga = mangas[0];
  const mangaUrl = manga
    ? mangaDetailsUrl(manga, {
        utmSource,
        utmListingId: utmListing?.listing_id,
        utmListing,
      })
    : '';
  const sendTrackingEvents = () => {
    ga4SendEvent('select_content', {
      content_type: 'manga',
      item_id: manga?.id,
    });
  };
  return (
    <Card>
      <CardActionArea
        component={Link}
        to={mangaUrl}
        onClick={sendTrackingEvents}
      >
        <CardMedia
          image={longStory.cover?.src}
          alt={longStory.title}
          title={longStory.title}
          className={css.media}
        />
      </CardActionArea>
      <CardHeader
        title={
          <Typography component="h3">
            <Link to={mangaUrl} onClick={sendTrackingEvents}>
              <small>{longStory.displayName}</small>
              {mangas.length > 1 && (
                <Typography
                  variant="caption"
                  component="span"
                  color="textSecondary"
                >
                  {' '}
                  / {manga.displayName}
                </Typography>
              )}
            </Link>
          </Typography>
        }
        subheader={
          <Typography
            variant="caption"
            color="textSecondary"
            gutterBottom={false}
          >
            <span>{reviewedDate ? fromNow(reviewedDate) : ''}</span>
            {' · '}
            {manga.stats?.viewsCount >= 1000 && (
              <span>{kFormatter(manga.stats?.viewsCount)} views</span>
            )}
          </Typography>
        }
      />
    </Card>
  );
}
LongStoryListItem.fragments = {
  LongStoryParts,
};
export default LongStoryListItem;
