import React from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { compose } from 'redux';
import { withAuth } from '@ttg-media/components/build/auth';
import AdminSideBar from './AdminSideBar';

export default compose(withAuth())((props) => (
  <Container>
    <Box
      mb={1}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <Typography component="h1" variant="h6">
        {props.title}
      </Typography>
      <AdminSideBar route={props.route} />
    </Box>
    {props.children}
  </Container>
));
