import gql from 'graphql-tag';

export const GET_TAG = gql`
  query GetTag($id: Int) {
    tagInfo: tag(id: $id) {
      tag {
        id
        lid
        name
        slug
        mangasCount
        description
        sections
      }
      info {
        description
        status
        createdBy
      }
    }
  }
`;

export const GET_TAG_BY_SLUG = gql`
  query GetTagBySlug($slug: String!) {
    tag: tagBySlug(slug: $slug) {
      id
      lid
      name
      slug
      mangasCount
      description
      sections
    }
  }
`;
export const DELETE_TAGS = gql`
  mutation DeleteTag($tags: [ID!]) {
    deleteTags(tags: $tags)
  }
`;

export const EDIT_TAG = gql`
  mutation EditTag($id: Int!, $tag: TagInput!) {
    editTag(id: $id, tag: $tag) {
      id
    }
  }
`;

export const TRANSFER_TAG = gql`
  mutation TransferTag($fromTags: [Int!], $toTags: [Int!]) {
    transferTag(fromTags: $fromTags, toTags: $toTags)
  }
`;
export const FETCH_TAG = gql`
  query FetchTag($page: Int) {
    tags(page: $page) {
      total
      items {
        id
        lid
        slug
        name
        otherName
        mangasCount
        covers
      }
    }
  }
`;
