import withNotify from '@ttg-media/components/build/hoc/withNotify';
import { useMutation } from '@apollo/client';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import React from 'react';
import gql from 'graphql-tag';
import Box from '@material-ui/core/Box';
import EditMangaForm from '../../post-manga/MangaForm/UpdateMangaForm';
import ButtonDeleteManga from '../actions/ButtonDeleteManga';
import { convertFormValueToGqlInput } from '../../post-manga/MangaForm/MangaForm.helpers';
import { MangaParts } from '../../../../gql/fragments';
import { convertRefsToForm } from '../../../helpers';
import EditMangaLayout from '../EditMangaLayout';
import { mangaEditPage } from '../../../../urls';
import useMangaForEdit from '../useMangaForEdit';
import handleAlt from '../../../../components/handleAlt';

const editMangaMutation = gql`
  mutation UpdateManga($id: Int!, $manga: MangaInput!) {
    updateManga(id: $id, manga: $manga) {
      ...MangaParts
      description
      isNew
      isDeleted
      dcmaViolation
    }
  }
  ${MangaParts}
`;

const EditMangaInfoPage = withNotify()(({ exec }) => {
  const [mutation] = useMutation(editMangaMutation);
  const resp = useMangaForEdit();
  const alt = handleAlt(resp);
  if (alt) return alt;
  const { manga } = resp.data;
  const onFormSubmit = (formValues) => {
    exec(
      mutation({
        variables: {
          id: formValues.id,
          manga: convertFormValueToGqlInput(formValues),
        },
      }),
    );
  };

  return (
    <EditMangaLayout route={mangaEditPage}>
      <Card>
        <CardContent>
          <EditMangaForm
            onSubmit={onFormSubmit}
            initialValues={convertRefsToForm(manga)}
          />
        </CardContent>
      </Card>
      <Box pt={2} display="flex" justifyContent="flexEnd">
        <ButtonDeleteManga manga={manga} />
      </Box>
    </EditMangaLayout>
  );
});

export default EditMangaInfoPage;
