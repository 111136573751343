import responsiveFontSizes from '@material-ui/core/styles/responsiveFontSizes';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';

const theme = responsiveFontSizes(
  createMuiTheme({
    palette: {
      type: 'dark',
      common: {
        black: '#000000',
        white: '#7a7a7a',
      },
      primary: {
        main: '#f9aa33',
        contrastText: '#111111',
      },
      secondary: {
        main: '#FF5E55',
        contrastText: '#ffffff',
      },
      text: {
        primary: '#ddc',
        secondary: 'rgba(255, 255, 255, 0.7)',
        icon: '#9b9b8f',
      },
      background: {
        paper: '#2D2D2D',
        default: '#111111',
      },
    },
    typography: {
      fontFamily: [
        'Open Sans',
        '-apple-system',
        'Roboto',
        'Helvetica neue',
        'Helvetica',
        'sans-serif',
      ],
      button: {
        textTransform: 'inherit',
      },
    },
    overrides: {
      MuiSvgIcon: {
        root: {
          color: '#9b9b8f',
        },
      },
      MuiInput: {
        underline: {
          '&:before': {
            borderBottom: 'none',
          },
        },
        formControl: {
          'label + &': {
            marginTop: 8,
          },
        },
        root: {
          borderRadius: 4,
          position: 'relative',
          backgroundColor: 'rgba(255, 255, 255, 0.15)',
          border: '0',
          color: 'currentColor',
          width: 'auto',
          padding: '4px 12px',
          '&:focus': {
            borderBottom: 'none',
          },
          '&:hover': {
            borderBottom: 'none',
          },
        },
        inputMultiline: {
          paddingTop: 'inherit',
          paddingBottom: 'inherit',
        },
      },
    },
  }),
);

export default theme;
