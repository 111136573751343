import Box from '@material-ui/core/Box';
import React from 'react';

function SimpleStack(props) {
  const { children, ...rest } = props;
  return (
    <Box
      {...rest}
      display="flex"
      flexDirection={props.direction}
      alignItems="center"
    >
      {props.children.map((child, index) => {
        const isLast = index >= props.children.length;
        return <Box mr={isLast ? undefined : props.spacing}>{child}</Box>;
      })}
    </Box>
  );
}
export default SimpleStack;
