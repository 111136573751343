/* eslint-disable react/prop-types */
// @flow
import React from 'react';
import Link from '@ttg-media/components/build/Link';
import HandleError from '@ttg-media/components/build/HandleError';
import Avatar from '@ttg-media/components/build/Avatar';
import Pager from '@ttg-media/components/build/Pager';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import Card from '@material-ui/core/Card';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import Edit from '@material-ui/icons/Edit';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import { fromNow } from '@ttg-media/components/build/utils';
import { UserSearchBox } from '../UserSearch';
import { admin, compileUrl, manageProfile } from '../../../urls';

const query = gql`
  query GetAllUsers($page: Int, $user: Int) {
    users: getAllUsers(page: $page, user: $user) {
      total
      items {
        id
        userName
        email
        displayName
        createdDate
      }
    }
  }
`;
export default function ({ page, user }) {
  const { loading, data, error } = useQuery(query, { variables: { user } });
  if (loading || error) return <HandleError error={error} loading={loading} />;
  const {
    users: { total, items },
  } = data;
  return (
    <div>
      <Card>
        <CardContent>
          <UserSearchBox />
        </CardContent>
        <List>
          {items.map((u) => (
            <ListItem
              button
              component={Link}
              to={compileUrl(manageProfile.edit, { id: u.id })}
              key={u.id}
            >
              <ListItemIcon>
                <Avatar user={u} size="sm" />
              </ListItemIcon>
              <ListItemText
                primary={u.email}
                secondary={`${u.userName} - ${u.displayName} - ${fromNow(
                  u.createdDate,
                )}`}
              />
              <ListItemSecondaryAction>
                <IconButton
                  component={Link}
                  to={compileUrl(manageProfile.edit, { id: u.id })}
                >
                  <Edit />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
        <CardContent>
          <Pager
            page={page}
            url={(p) => compileUrl(admin.listUser, { page: p })}
            total={total}
          />
        </CardContent>
      </Card>
    </div>
  );
}
