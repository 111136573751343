import React from 'react';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Pager from '@ttg-media/components/build/Pager';
import Container from '@material-ui/core/Container';
import MangaListGroup from '../../../components/MangaListGroup/MangaListGroup';

export default function MangaList(props) {
  const { page } = props;
  const { mangaList } = props.data;
  return (
    <Container>
      {mangaList.count > 0 ? (
        <MangaListGroup
          mangaList={mangaList}
          detailsPageUrlFactory={props.detailsPageUrlFactory}
        />
      ) : (
        <Card>
          <CardContent>
            <Typography>Không tìm thấy truyện nào</Typography>
          </CardContent>
        </Card>
      )}
      <Box my={2}>
        <Pager
          page={page}
          url={props.url}
          totalPage={Math.round(mangaList.count / 12)}
        />
      </Box>
    </Container>
  );
}
