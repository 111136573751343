import _sortBy from 'lodash/sortBy';
import _uniqBy from 'lodash/uniqBy';

export function orderImageByFileName(images, fileNamePredicate) {
  return _sortBy(
    _uniqBy(images, (image) => fileNamePredicate(image).toLowerCase()),
    [
      (image) => fileNamePredicate(image).length,
      (image) => fileNamePredicate(image),
    ],
  );
}
export default orderImageByFileName;
