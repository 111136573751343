import React from 'react';
import { compose } from 'redux';
import { withAuth } from '@ttg-media/components/build/auth';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import HandleError from '@ttg-media/components/build/HandleError';
import UserForm from './UserForm';
import { UserFragment } from './UserFragment';

const GET_USER = gql`
  query GetUser($id: String) {
    user: userForEdit(user: $id) {
      ...UserFragment
      about
      coverPhoto
    }
  }
  ${UserFragment}
`;
function EditProfilePage({ id }) {
  const { data, loading, error } = useQuery(GET_USER, {
    variables: { id },
  });
  if (loading || error) return <HandleError loading={loading} error={error} />;
  return <UserForm id={id} defaultValues={data.user} />;
}
export default compose(withAuth())(EditProfilePage);
