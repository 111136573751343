import React from 'react';
import { useFormContext } from 'react-hook-form';
import MenuItem from '@material-ui/core/MenuItem';
import _sortBy from 'lodash/sortBy';

function ButtonAutoSort(props) {
  const form = useFormContext();
  const autoSort = () => {
    form.setValue(
      'images',
      _sortBy(form.getValues('images'), [
        ((i) => i.fileName.length, (i) => i.fileName),
      ]),
    );
  };
  return (
    <MenuItem onClick={autoSort} disabled={props.disabled}>
      Sắp xếp tự động
    </MenuItem>
  );
}

export default ButtonAutoSort;
