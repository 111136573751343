// @flow
import React from 'react';
import { useMutation } from '@apollo/client';
import { ButtonAction } from '../../../../components/ButtonAction';
import { RestoreManga } from '../gql';

export default function (props) {
  const mutation = useMutation(RestoreManga, {
    variables: { manga: props.manga.id },
  });
  return <ButtonAction mutation={mutation}>Phục hồi truyện</ButtonAction>;
}
