import React from 'react';
import { useQuery } from '@apollo/client';
import HandleError from '@ttg-media/components/build/HandleError';
import Link from '@ttg-media/components/build/Link';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Avatar from '@material-ui/core/Avatar';
import PageHead from '@ttg-media/components/build/PageHead';
import Markdown from '@ttg-media/components/build/Markdown';
import { GET_TRANSLATORS } from '../translatorGql';
import { mangaListUrl } from '../../../urls';

function TranslatorList() {
  const { data, loading, error } = useQuery(GET_TRANSLATORS);
  if (loading || error) return <HandleError />;
  return (
    <>
      <PageHead title="Nhóm dịch" />
      <Paper>
        <List dense>
          {data.translators.items.map((t) => (
            <ListItem
              key={t.id}
              button
              component={Link}
              to={mangaListUrl({ translator: t.id })}
            >
              <ListItemIcon>
                <Avatar src={t.avatar?.src} />
              </ListItemIcon>
              <ListItemText
                primary={t.name}
                secondary={<Markdown source={t.about} />}
              />
            </ListItem>
          ))}
        </List>
      </Paper>
    </>
  );
}
export default function () {
  return (
    <Container maxWidth="md">
      <TranslatorList />
    </Container>
  );
}
