import gql from 'graphql-tag';

export default gql`
  mutation postManga(
    $manga: MangaInput!
    $images: [ImageInput]!
    $cover: ImageInput!
  ) {
    manga: postManga(manga: $manga, images: $images, cover: $cover) {
      id
    }
  }
`;
