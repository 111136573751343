import React from 'react';
import Link from '@ttg-media/components/build/Link';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import _some from 'lodash/some';
import Box from '@material-ui/core/Box';
import {
  compileUrl,
  mangaAddPage,
  mangaEditCover,
  mangaEditPage,
  mangaEditPageOrder,
  mangaStatusPage,
} from '../../../../urls';
import EditPagesSubMenu from '../EditPagesSubMenu';

function EditMangaNavbarComponent(props) {
  const { id, route } = props;
  const checkSelection = (r) => r === route;
  const checkSelections = (...routes) => _some(routes, (r) => r === route);
  return (
    <>
      <Tabs scrollButtons>
        <Tab
          label="Trạng thái duyệt"
          component={Link}
          selected={checkSelection(mangaStatusPage)}
          to={compileUrl(mangaStatusPage, { id })}
        />
        <Tab
          label="Sửa"
          component={Link}
          selected={checkSelection(mangaEditPage)}
          to={compileUrl(mangaEditPage, { id })}
        />
        <Tab
          label="Sửa ảnh"
          selected={checkSelection(mangaEditPageOrder)}
          component={Link}
          to={compileUrl(mangaEditPageOrder, { id })}
        />
      </Tabs>
      {checkSelections(mangaEditPageOrder, mangaEditCover, mangaAddPage) && (
        <Box mt={1}>
          <EditPagesSubMenu route={route} id={id} />
        </Box>
      )}
    </>
  );
}

export default EditMangaNavbarComponent;
