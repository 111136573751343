import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import Link from '@ttg-media/components/build/Link';
import CardMedia from '@material-ui/core/CardMedia';
import CardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import { fromNow } from '@ttg-media/components/build/utils';
import Cover from '../Manga/Cover';
import { mangaDetailsUrl } from '../../urls';

const useStyles = makeStyles({
  coverRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
});
export function MangaListItem(props) {
  const { manga, utmSource, utmListing } = props;
  const { reviewedDate, displayName } = manga;
  const detailsPageUrlFactory = props.detailsPageUrlFactory || mangaDetailsUrl;
  const url = detailsPageUrlFactory(manga, {
    utmSource,
    utmListingId: utmListing?.listing_id,
    utmListing,
  });
  const css = useStyles();

  return (
    <Card>
      <CardActionArea component={Link} to={url}>
        <CardMedia className={css.coverRoot}>
          <Cover manga={manga} />
        </CardMedia>
        <CardHeader
          title={<Typography component="h3">{displayName}</Typography>}
          subheader={
            <Typography variant="caption" color="textSecondary">
              {reviewedDate ? fromNow(reviewedDate) : ''}
            </Typography>
          }
        />
      </CardActionArea>
    </Card>
  );
}
