import React from 'react';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import gql from 'graphql-tag';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Link from '@ttg-media/components/build/Link';
import GET_LONG_STORIES from '../../list/GET_LONG_STORIES';
import handleError from '../../../components/handleError';
import useQueryString from '../../list/useQueryString';
import LongStoryListPage from '../../list/components/LongStoryListPage';
import { authorDetailsUrl, authorListPage, compileUrl } from '../../../urls';
import { PAGE_SIZE } from '../../../constants';
import PageHead from '../../../components/PageHead';
import ExperimentSwitcher from '../../ab-testing/ExperimentSwitcher';

const FETCH_AUTHOR_GQL = gql`
  query ($id: Int!) {
    author(id: $id) {
      id
      name
      slug
    }
  }
`;
function AuthorDetailsPage(props) {
  const { experimentId, variant } = props;
  const params = useParams();
  const id = parseInt(params.id, 10);
  const { page } = useQueryString();
  const authorResp = useQuery(FETCH_AUTHOR_GQL, {
    variables: {
      id,
    },
  });
  const resp = useQuery(GET_LONG_STORIES, {
    variables: {
      filter: {
        author: id,
        page,
      },
    },
  });
  const alt = handleError(resp) || handleError(authorResp);
  if (alt) return alt;
  const { data } = resp;
  const { author } = authorResp.data;
  const utmListing = {
    total_items: data.longStories.total,
    total_pages: Math.ceil(data.longStories.total / PAGE_SIZE),
    current_page: page,
    offset: (page - 1) * PAGE_SIZE + 1,
    listing_id: `author-${id}`,
    listing_type: 'author',
    listing_item_id: id,
    experiment_id: experimentId,
    variant,
  };
  return (
    <Container>
      <Box mb={1}>
        <ExperimentSwitcher experimentId={props.experimentId} />
      </Box>
      <PageHead
        title={`Tác giả ${author.name}`}
        right={
          <Typography variant="caption">
            {data.longStories.total} truyện
          </Typography>
        }
        breadcrumbs={[
          <Link color="primary" to={compileUrl(authorListPage)}>
            Danh sách tác giả
          </Link>,
          <Typography>{author.name}</Typography>,
        ]}
      />

      <LongStoryListPage
        data={data}
        page={page}
        url={(p) => authorDetailsUrl({ id }, { page: p })}
        utmSource="author"
        utmListing={utmListing}
      />
    </Container>
  );
}
export default AuthorDetailsPage;
