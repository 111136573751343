import React from 'react';
import LazyLoad from 'react-lazyload';
import { getExperimentVariant } from '../experiments';

export const withExperiment = (opts) => (WrappedComponent) =>
  function (props) {
    const variant = getExperimentVariant(opts.experimentId);
    const Component = opts.variants ? opts.variants[variant] : WrappedComponent;
    return (
      <LazyLoad>
        <Component
          {...props}
          variant={variant}
          experimentId={opts.experimentId}
        />
      </LazyLoad>
    );
  };
