import React from 'react';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import useNotify from '@ttg-media/components/build/hoc/useNotify';

const CONVERT_ORPHAN_MANGA = gql`
  mutation ConvertOrphanManga($manga: Int!) {
    convertOrphanMangaToLongStory(manga: $manga) {
      id
    }
  }
`;
export default function (props) {
  const { manga } = props;
  const { exec } = useNotify();
  const [mutate] = useMutation(CONVERT_ORPHAN_MANGA, {
    variables: { manga },
  });
  const onClick = () => exec(mutate());
  return (
    <ButtonBase>
      <Typography variant="caption" color="primary" onClick={onClick}>
        Tạo truyện dài
      </Typography>
    </ButtonBase>
  );
}
