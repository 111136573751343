// @flow
import React from 'react';
import { useMutation } from '@apollo/client';

import { deleteMangaMutation } from '../gql';
import { ButtonAction } from '../../../../components/ButtonAction';

export default function (props) {
  const { manga } = props;
  const mutation = useMutation(deleteMangaMutation, {
    variables: { manga: manga.id, purge: props.purge },
  });
  return (
    <ButtonAction
      disabled={manga.isDeleted}
      mutation={mutation}
      color="secondary"
      id="delete-manga-button"
    >
      Xoá truyện
    </ButtonAction>
  );
}
