import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import ViewIcon from '@material-ui/icons/Visibility';
import PagesCountIcon from '@material-ui/icons/Image';
import Comment from '@material-ui/icons/Comment';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { kFormatter } from '@ttg-media/components/build/utils';
import Box from '@material-ui/core/Box';
import loadable from '@loadable/component';
import SentimentSatisfiedAlt from '@material-ui/icons/SentimentSatisfiedAlt';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import Popover from '@material-ui/core/Popover';
import LikeButton from '../../pages/manga/components/Manga/LikeButton';
import { DownloadButton } from './DownloadButton';

const Comments = loadable(() =>
  import('@ttg-media/comment/build/Comments/Comments'),
);

function ReactionButton({ manga, size }) {
  const { stats = {} } = manga;
  return (
    <PopupState>
      {(popupState) => (
        <span>
          <Button
            size={size}
            startIcon={<SentimentSatisfiedAlt />}
            {...bindTrigger(popupState)}
          >
            {!stats
              ? 0
              : stats.commentsCount + stats.likesCount + stats.downloadsCount}
          </Button>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <LikeButton manga={manga} size={size} />
            <CommentButton manga={manga} disabled size={size} />
            <DownloadButton manga={manga} size={size} />
          </Popover>
        </span>
      )}
    </PopupState>
  );
}
function CommentButton({ manga, disabled, size }) {
  const [showComments, setShowComment] = useState(false);
  return (
    <>
      <Button
        disabled={disabled}
        onClick={() => setShowComment(true)}
        startIcon={<Comment />}
        size={size}
      >
        {manga.stats?.commentsCount}
      </Button>
      {showComments && (
        <Dialog
          onClose={() => setShowComment(false)}
          aria-labelledby="simple-dialog-title"
          open={showComments}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle id="simple-dialog-title">Bình luận</DialogTitle>
          <DialogContent>
            <Comments
              threadId={manga.lid}
              threadOwner={manga.createdBy.lid}
              threadType="MANGA"
            />
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}

function MangaStats({
  size,
  more,
  hidePage,
  manga,
  disabled,
  hideComment,
  showLike,
  showInteraction,
  showView = true,
}) {
  const { stats } = manga;
  return (
    <Box display="flex" flexDirection="row">
      {showView && (
        <Button startIcon={<ViewIcon />} disabed={disabled} size={size}>
          {kFormatter(stats?.viewsCount)}
        </Button>
      )}

      {!hidePage && (
        <Button startIcon={<PagesCountIcon />} disabed={disabled} size={size}>
          {stats?.pagesCount}
        </Button>
      )}
      {!hideComment && (
        <CommentButton manga={manga} disabled={disabled} size={size} />
      )}

      {(more || showLike) && (
        <LikeButton manga={manga} disabled={disabled} size={size} />
      )}
      {more && <DownloadButton manga={manga} disabled={disabled} size={size} />}
      {showInteraction && <ReactionButton size={size} manga={manga} />}
    </Box>
  );
}
export default MangaStats;
