import React from 'react';
import Button from '@material-ui/core/Button';
import gql from 'graphql-tag';
import { compose } from 'redux';
import { useMutation, useQuery } from '@apollo/client';
import withNotify from '@ttg-media/components/build/hoc/withNotify';
import { withMe } from '@ttg-media/components/build/auth';
import FavoriteOutlined from '@material-ui/icons/FavoriteOutlined';
import history from '../../../../myHistory';

import { MangaParts } from '../../../../gql/fragments';

const ADD_TO_FAV = gql`
  mutation AddtoFavourite($mangaId: Int!) {
    likeManga(mangaId: $mangaId) {
      ...MangaParts
    }
  }
  ${MangaParts}
`;
const CHECK_LIKE = gql`
  query CheckLike($mangaId: Int!) {
    like: checkLike(mangaId: $mangaId)
  }
`;
function LikeButton({ exec, me, manga, size }) {
  let isLike = null;
  let likeColor;
  const [like] = useMutation(ADD_TO_FAV);
  const mangaId = manga.id;
  const { data, loading, error } = useQuery(CHECK_LIKE, {
    variables: { mangaId },
    skip: !process.env.BROWSER,
  });
  if (error) return error.message;
  if (!loading) {
    isLike = data?.like;
    if (isLike === true) likeColor = 'primary';
    else if (isLike === false) likeColor = 'inherit';
    else likeColor = 'inherit';
  }
  const onClick = () => {
    if (!me) {
      history.push('/login');
    } else {
      exec(
        like({
          variables: { mangaId },
          refetchQueries: [{ query: CHECK_LIKE, variables: { mangaId } }],
        }),
      );
    }
  };
  return (
    <Button
      onClick={onClick}
      startIcon={<FavoriteOutlined />}
      color={likeColor}
      disabled={loading}
      size={size}
      aria-label="like button"
    >
      {manga.stats?.likesCount || 0}
    </Button>
  );
}
export default compose(withNotify(), withMe())(LikeButton);
