import React from 'react';
import Chip from '@material-ui/core/Chip';
import Box from '@material-ui/core/Box';
import Link from '@ttg-media/components/build/Link';
import {
  compileUrl,
  mangaAddPage,
  mangaEditCover,
  mangaEditPage,
} from '../../../urls';

export default function (props) {
  const { id, route } = props;
  const checkSelection = (r) => r === route;
  const selection = (r) =>
    checkSelection(r) ? { variant: 'contained' } : { variant: 'outlined' };
  return (
    <Box>
      <Chip
        label="Sửa thứ tự"
        component={Link}
        variant="contained"
        to={compileUrl(mangaEditPage, { id })}
      />{' '}
      <Chip
        {...selection(mangaEditPage)}
        component={Link}
        to={compileUrl(mangaEditCover, { id })}
        label="Chọn ảnh bìa"
      />{' '}
      <Chip
        label="Thêm trang truyện"
        component={Link}
        {...selection(mangaEditPage)}
        to={compileUrl(mangaAddPage, { id })}
      />
    </Box>
  );
}
