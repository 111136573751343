import gql from 'graphql-tag';

export default gql`
  query pages($manga: Int!, $server: Int!) {
    pages(manga: $manga, server: $server) {
      id
      order
      src
      fileName
      srcSet {
        id
        type
        srcSet
      }
    }
  }
`;
