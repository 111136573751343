import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Link from '@ttg-media/components/build/Link';
import { useAuth } from '@ttg-media/components/build/auth';
import Paper from '@material-ui/core/Paper';
import {
  compileUrl,
  favouriteRoute,
  longStoryRoute,
  manageMyManga,
  managePageviews,
} from '../../urls';

function UserTabs({ value }) {
  const { me } = useAuth();
  if (!me) return <div />;
  const getSelected = () => {
    if (value === favouriteRoute) return 0;
    if (value === manageMyManga) return 1;
    if (value === managePageviews) return 2;
    if (value === longStoryRoute.list) return 3;
    return null;
  };
  return (
    <Paper>
      <Tabs textColor="primary" indicatorColor="primary" value={getSelected()}>
        <Tab
          label="Đã lưu"
          component={Link}
          to={compileUrl(favouriteRoute, { slug: me.userName })}
        />
        <Tab label="Đã đăng" component={Link} to={compileUrl(manageMyManga)} />
        <Tab
          label="Lượt xem"
          component={Link}
          to={compileUrl(managePageviews)}
        />
        <Tab
          label="Truyện dài"
          component={Link}
          to={compileUrl(longStoryRoute.list, { user: me.id, page: 1 })}
        />
      </Tabs>
    </Paper>
  );
}

export default UserTabs;
