import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import HandleError from '@ttg-media/components/build/HandleError';
import { fromNow } from '@ttg-media/components/build/utils';
import Paper from '@material-ui/core/Paper';
import ListItemText from '@material-ui/core/ListItemText';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import ListAlt from '@material-ui/icons/ListAlt';
import Box from '@material-ui/core/Box';
import CardHeader from '@material-ui/core/CardHeader';
import Button from '@material-ui/core/Button';
import { useQuery } from 'react-query';
import createStrapiBrowserClient from '../../../../components/createStrapiBrowserClient';
import handleError from '../../../../components/handleError';

function formatName(name) {
  if (name === 'CREATE_COVER') {
    return 'Bước 1 - Tối ưu ảnh bìa';
  }
  if (name === 'FINALIZE_UPLOAD_FILES') {
    return 'Bước 2 - Xử lý ảnh';
  }

  if (name === 'OPTIMIZE_IMAGES') {
    return 'Bước 3 - Tối ưu ảnh';
  }
  if (name === 'REPLICATE_IMAGES') {
    return 'Bước 4 - Tải ảnh lên server dự phòng';
  }
  if (name === 'BACKUP_IMAGES') {
    return 'Bước 5 - Sao lưu ảnh gốc';
  }
  if (name === 'CREATE_DOWNLOAD') {
    return 'Bước 6 - Tạo file zip tải ảnh xuống';
  }
  return name;
}
function formatStatus(status) {
  if (status === 'SUCCESS') {
    return 'Thành công';
  }
  if (status === 'ERROR') {
    return 'Lỗi';
  }
  return status;
}
export default function ({ anchor }) {
  const resp = useQuery(['system-tasks', anchor], () =>
    createStrapiBrowserClient().get('/system-tasks', {
      params: {
        filters: {
          anchorId: anchor,
        },
        sort: ['updatedDate:desc'],
      },
    }),
  );
  const alt = handleError(resp);
  if (alt) return alt;
  const { data, isLoading, refetch, error } = resp;
  const tasks = data.data.data;

  return (
    <Paper>
      <CardHeader
        avatar={<ListAlt fontSize="small" />}
        action={
          <Button onClick={() => refetch()} size="small" disabled={isLoading}>
            Tải lại
          </Button>
        }
        title="Lịch sử hệ thống"
      />
      <Divider />
      <Box style={{ maxHeight: 400, overflow: 'auto' }}>
        <List dense>
          {isLoading || error ? (
            <HandleError loading={isLoading} error={error} />
          ) : (
            tasks.map((task) => (
              <ListItem key={task.id} button>
                <ListItemText
                  primary={formatName(task.attributes.name)}
                  secondary={
                    <Typography variant="caption" color="textSecondary">
                      {task.attributes.name}
                      {' · '}
                      {fromNow(task.attributes.completionDate)}
                    </Typography>
                  }
                />
                <Chip
                  label={formatStatus(task.attributes.status)}
                  size="small"
                  variant="outlined"
                  disabled
                />
              </ListItem>
            ))
          )}
        </List>
      </Box>
    </Paper>
  );
}
