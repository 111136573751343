import gql from 'graphql-tag';

export const UserFragment = gql`
  fragment UserFragment on User {
    id
    shortId
    lid
    userName
    displayName
    email
    isAdmin
    roles
    avatar {
      id
      src
    }
  }
`;
