import React from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Link from '@ttg-media/components/build/Link';
import { useAuth } from '@ttg-media/components/build/auth';
import InputFormField from '@ttg-media/components/build/FormControls/InputFormField';
import { FormProvider, useForm } from 'react-hook-form';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { UserFragment } from './UserFragment';
import { mangaListUrl } from '../../../../urls';

const EDIT_USER = gql`
  mutation EditProfile($userId: String!, $user: UserInput!) {
    editProfile(userId: $userId, user: $user) {
      ...UserFragment
    }
  }
  ${UserFragment}
`;

function UserForm({ id, defaultValues }) {
  const form = useForm({ defaultValues });
  const { me: user } = useAuth();
  const [action] = useMutation(EDIT_USER);

  const onSubmit = (values) =>
    action({
      variables: {
        userId: id,
        user: {
          userName: values.userName,
          email: values.email,
          about: values.about,
          displayName: values.displayName,
        },
      },
    })
      .then(() => toast.success('Thành công'))
      .catch((error_) => toast.error(error_.message));

  const { handleSubmit } = form;
  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card>
          <CardContent>
            <InputFormField
              name="userName"
              type="text"
              label="Tên đăng nhập"
              required
            />
            <InputFormField
              name="displayName"
              type="text"
              label="Tên hiển thị"
              required
            />
            <InputFormField name="email" type="text" label="Email" required />
            <InputFormField
              label="Giới thiệu"
              name="about"
              type="text"
              cols={3}
            />
            <Box display="flex" justifyContent="flex-end">
              <Button
                variant="text"
                component={Link}
                to={mangaListUrl({ userName: user.userName })}
                name="btnMemberDetailsPage"
              >
                Trang cá nhân
              </Button>{' '}
              <Button variant="contained" color="primary" type="submit">
                Lưu
              </Button>
            </Box>
          </CardContent>
        </Card>
      </form>
    </FormProvider>
  );
}
// function validate(values) {
//   const errors = {};
//   if (!values.email) errors.email = 'Trường bắt buộc';
//   if (!values.userName) errors.userName = 'Trường bắt buộc';
//   if (!values.displayName) errors.displayName = 'Trường bắt buộc';
//   return errors;
// }
export default UserForm;
