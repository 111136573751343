import React from 'react';

import CssBaseline from '@material-ui/core/CssBaseline';
import { AuthProvider } from '@ttg-media/components/build/auth';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import { createStore } from 'redux';
import { Provider as ReduxProvider } from 'react-redux';
import { NotificationContextProvider } from '@ttg-media/components/build/hoc/NotificationContext';
import { QueryClientProvider, QueryClient } from 'react-query';
import { ToastContainer, toast } from 'react-toastify';
import NotFound from '@ttg-media/components/build/NotFound';
import { Routes, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import theme from './theme';
import rootReducer from './rootReducer';
import 'react-toastify/dist/ReactToastify.css';
import HomePage from './pages/home/HomePage';
import * as url from './urls';
import authRoutes from './pages/auth/authRoutes';
import AuthorListPage from './pages/authors/AuthorListPage';
import Layout from './components/Layout';
import miscRoutes from './pages/misc';
import userRoutes from './pages/user';
import translatorRoutes from './pages/translators';
import longStoryRoutes from './modules/longStory/routes';
import adminRoutes from './pages/admin';
import mangaRoutes from './pages/manga';
import TagListPage from './pages/tags/TagListPage';
import SearchPage from './pages/search/SearchPage';
import TagDetailsPage from './pages/tag/TagDetailsPage';
import AuthorDetailsPage from './pages/author/AuthorDetailsPage';
import AbTesting from './pages/ab-testing/AbTesting';

const client = new QueryClient({ defaultOptions: { queries: { retry: 0 } } });
const store = createStore(rootReducer);
function App() {
  return (
    <ReduxProvider store={store}>
      <NotificationContextProvider value={{ notify: toast }}>
        <QueryClientProvider client={client}>
          <AuthProvider appName="ttg:dating">
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <Layout>
                <Helmet>
                  <title>Truyện tranh Gay</title>
                </Helmet>
                <Routes>
                  <Route path={url.homePage} element={<HomePage />} />
                  {authRoutes}
                  <Route path={url.tagListPage} element={<TagListPage />} />
                  <Route
                    path={url.tagDetailsPage}
                    element={<TagDetailsPage />}
                  />
                  <Route
                    path={url.authorListPage}
                    element={<AuthorListPage />}
                  />
                  <Route
                    path={url.authorDetailsPage}
                    element={<AuthorDetailsPage />}
                  />
                  {mangaRoutes}
                  {adminRoutes}
                  {miscRoutes}
                  {userRoutes}
                  {translatorRoutes}
                  {longStoryRoutes}
                  <Route path={url.searchPage} element={<SearchPage />} />
                  <Route path={url.abTestingPage} element={<AbTesting />} />
                  <Route path="*" element={<NotFound />} />
                </Routes>
              </Layout>
              <ToastContainer />
            </ThemeProvider>
          </AuthProvider>
        </QueryClientProvider>
      </NotificationContextProvider>
    </ReduxProvider>
  );
}

export default App;
