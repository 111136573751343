import React from 'react';
import Alert from '@material-ui/lab/Alert';
import { ExperimentItem } from './AbTesting';

function ExperimentSwitcher({ experimentId }) {
  return (
    <Alert
      variant="outlined"
      severity="info"
      action={<ExperimentItem id={experimentId} showId={false} />}
    >
      Thử nghiệm giao diện mới
    </Alert>
  );
}
export default ExperimentSwitcher;
