import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Container from '@material-ui/core/Container';
import Link from '@ttg-media/components/build/Link';
import Pager from '@ttg-media/components/build/Pager';
import Box from '@material-ui/core/Box';
import LazyLoad from 'react-lazyload';
import Title from '@ttg-media/components/build/Title';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import { RequireAdmin } from '@ttg-media/components/build/auth';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import { useQuery } from '@apollo/client';
import { mangaListUrl, tagListUrl } from '../../urls';
import TagSearch from './TagSearch';
import HotTags from '../home/HotTags';
import { FETCH_TAG } from './tagGql';
import ButtonTransferTag from './ButtonTransferTag';
import ButtonDeleteTag from './ButtonDeleteTag';
import PageHead from '../../components/PageHead';
import handleError from '../../components/handleError';
import useQueryString from '../list/useQueryString';

export async function fetchTags(client, { page }) {
  const { data } = await client.query({
    query: FETCH_TAG,
    variables: { page },
  });
  return data;
}
function TagListPage() {
  const { page } = useQueryString();
  const { data, loading, error } = useQuery(FETCH_TAG, { variables: { page } });
  if (loading || error) return handleError({ loading, error });
  const { items: tags } = data.tags;
  return (
    <Container>
      <PageHead title="Danh sách Tag" />

      {page === 1 && (
        <Box mb={2}>
          <LazyLoad>
            <Title>Xem nhiều</Title>
            <HotTags />
          </LazyLoad>
        </Box>
      )}

      <Box mb={2} mt={3}>
        <Title>Toàn bộ</Title>
        <TagSearch />

        <List dense>
          {tags.map((tag) => (
            <ListItem
              key={tag.id}
              button
              component={Link}
              to={mangaListUrl({ tag: tag.id })}
            >
              <ListItemAvatar>
                {tag.covers?.length >= 1 && (
                  <LazyLoad>
                    <Avatar src={tag.covers[0]} variant="rounded" />
                  </LazyLoad>
                )}
              </ListItemAvatar>
              <ListItemText
                primary={tag.name}
                secondary={<small>{tag.mangasCount} truyện</small>}
              />
              <RequireAdmin>
                <ListItemSecondaryAction>
                  <ButtonDeleteTag tag={tag} />
                  <ButtonTransferTag tag={tag} />
                </ListItemSecondaryAction>
              </RequireAdmin>
            </ListItem>
          ))}
        </List>
      </Box>
      <Pager
        page={page}
        url={(p) => tagListUrl({ page: p })}
        totalPage={Math.ceil(data.tags.total / 20)}
      />
    </Container>
  );
}

export default TagListPage;
