import EditIcon from '@material-ui/icons/Edit';
import React from 'react';
import { RequireOwner } from '@ttg-media/components/build/auth';
import Link from '@ttg-media/components/build/Link';
import Fab from '@material-ui/core/Fab';
import { compileUrl, mangaStatusPage } from '../../../../urls';

export function EditMangaLinkButton({ id, createdBy }) {
  return (
    <RequireOwner id={createdBy.id}>
      <Fab
        style={{ position: 'fixed', zIndex: 999, bottom: 9, right: 9 }}
        component={Link}
        to={compileUrl(mangaStatusPage, { id })}
        variant="extended"
        color="primary"
        name="edit-manga-link"
      >
        <EditIcon />
        Quản lý
      </Fab>
    </RequireOwner>
  );
}
