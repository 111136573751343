import React from 'react';
import { Route } from 'react-router-dom';
import * as url from '../../urls';
import EditTranslatorPage from './edit/EditTranslatorPage';
import TranslatorsListPage from './list/TranslatorsListPage';

export default [
  <Route element={<EditTranslatorPage />} path={url.translatorRoute.edit} />,
  <Route element={<TranslatorsListPage />} path={url.translatorRoute.list} />,
];
