import withNotify from '@ttg-media/components/build/hoc/withNotify';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';
import { toast } from 'react-toastify';
import { TOAST_MESSAGES } from './toastify';

export const ButtonAction = withNotify()((props) => {
  const label = props.label || 'Lưu';
  const [action, { loading }] = props.mutation;
  const { icon } = props;
  const onClick = () => toast.promise(action(), TOAST_MESSAGES);
  const Component = props.component || Button;
  return (
    <Component
      onClick={onClick}
      size="sm"
      disabled={props.disabled || loading}
      startIcon={loading ? <CircularProgress size={20} /> : icon}
      variant={props.variant || 'outlined'}
      {...props}
    >
      {props.children || label}
    </Component>
  );
});
