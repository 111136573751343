import React from 'react';
import Container from '@material-ui/core/Container';
import PrivacyPolicy from './privacy-policy.md';

export default function () {
  return (
    <Container maxWidth="md">
      <PrivacyPolicy />
    </Container>
  );
}
