import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from 'react-sortable-hoc';
import React, { useState } from 'react';
import _find from 'lodash/find';
import _filter from 'lodash/filter';
import { useMutation } from '@apollo/client';
import TableContainer from '@material-ui/core/TableContainer';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Menu from '@material-ui/icons/Menu';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';
import CardMedia from '@material-ui/core/CardMedia';
import Avatar from '@material-ui/core/Avatar';
import Checkbox from '@material-ui/core/Checkbox';
import DoneIcon from '@material-ui/icons/CheckCircleOutline';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { elipsis } from '@ttg-media/components/build/SeoHeaders';
import { ImagePreview } from './ImagePreview';
import { ButtonAction } from '../../../../components/ButtonAction';
import { deleteMangaPagesGql } from '../gql';

const useStyles = makeStyles((theme) => ({
  coverRoot: { position: 'relative' },
  overlay: {
    position: 'absolute',
    width: '100%',
    display: 'block',
    background: '#000000',
    opacity: '70%',
    bottom: 0,
  },
  large: {
    width: theme.spacing(15),
    height: theme.spacing(15),
  },
}));
const DragHandle = sortableHandle(() => <Menu />);
const SortableItem = sortableElement(({ page, currentIndex, onSelect }) => {
  const classes = useStyles();

  return (
    <Grid item key={page.id} md={2}>
      <Card>
        <CardHeader
          title={<Typography noWrap>{page.fileName}</Typography>}
          action={<DragHandle />}
        />
        <CardMedia className={classes.coverRoot}>
          <Avatar src={page.src} variant="rounded" className={classes.large} />
          <div className={classes.overlay}>
            <Checkbox onChange={() => onSelect(page)} color="primary" />
            <Typography variant="caption">Trang {currentIndex + 1}</Typography>
            <ImagePreview image={page} />
            <DragHandle />
          </div>
        </CardMedia>
        <CardContent>
          <Typography variant="caption">
            {elipsis(page.fileName, 30)}
          </Typography>
          <Typography>{page.version ? <DoneIcon /> : <span />}</Typography>
        </CardContent>
      </Card>
    </Grid>
  );
});
export const SortableList = sortableContainer(({ mangaId, items }) => {
  const [selections, setSelections] = useState([]);
  const onSelect = (item) => {
    const found = _find(selections, (p) => p.id === item.id);
    if (found) {
      return setSelections(_filter([...selections], (p) => p.id !== item.id));
    }
    return setSelections([...selections, item]);
  };
  const deleteMutation = useMutation(deleteMangaPagesGql, {
    variables: {
      mangaId,
      pageIds: selections.map((s) => s.id),
    },
  });

  return (
    <TableContainer>
      <CardActions>
        <ButtonAction
          mutation={deleteMutation}
          variant="outlined"
          disabled={selections.length <= 0}
        >
          Xoá {selections.length} trang
        </ButtonAction>
      </CardActions>

      <CardContent>
        <Grid container spacing={1}>
          {items.map((page, index) => (
            <SortableItem
              key={`item-${page.id}`}
              index={index}
              page={page}
              currentIndex={index}
              mangaId={mangaId}
              onSelect={onSelect}
            />
          ))}
        </Grid>
      </CardContent>
    </TableContainer>
  );
});
