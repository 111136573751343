import React, { useState } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import ListItemText from '@material-ui/core/ListItemText';
import { useMutation, useQuery } from '@apollo/client';
import Button from '@material-ui/core/Button';
import SortableControl from '@ttg-media/components/build/FormControls/SortableControl';
import HandleError from '@ttg-media/components/build/HandleError';
import withNotify from '@ttg-media/components/build/hoc/withNotify';
import Link from '@ttg-media/components/build/Link';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import Box from '@material-ui/core/Box';
import {
  GET_LONG_STORY_FOR_EDIT,
  UPDATE_MANGA_ORDERS,
} from '../longStoryFragments';
import { compileUrl, mangaDetailsPage } from '../../../../urls';

const MangaOrderForm = withNotify()((props) => {
  const { id, exec } = props;
  const [items, setItems] = useState(props.items);
  const [mutation] = useMutation(UPDATE_MANGA_ORDERS);

  const onClick = () => {
    exec(
      mutation({
        variables: {
          id,
          mangas: items.map((i) => i.id),
        },
      }),
    );
  };
  return (
    <Card>
      <SortableControl
        onChange={setItems}
        items={items}
        renderItem={(manga) => (
          <ListItemText
            primary={
              <Link
                to={compileUrl(mangaDetailsPage, {
                  id: manga.id,
                  slug: manga.slug,
                })}
              >
                {manga.title}
              </Link>
            }
            secondary={
              <>
                <Typography variant="caption" color="textSecondary">
                  Chương {manga.chapter + 1}
                </Typography>{' '}
                {manga.isReviewed && (
                  <Chip color="secondary" label="Đợi duyệt" size="small" />
                )}
                {manga.isDeleted && <Chip label="Đã xoá" size="small" />}
              </>
            }
          />
        )}
      />
      <CardContent>
        <Box display="flex" justifyContent="flex-end">
          <Button onClick={onClick} color="primary" variant="contained">
            Lưu
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
});
function UpdateMangaOrder(props) {
  const { id } = props;

  const { data, loading, error } = useQuery(GET_LONG_STORY_FOR_EDIT, {
    variables: { id },
  });
  if (loading || error) return <HandleError loading={loading} error={error} />;
  const { mangas } = data.longStory;
  return <MangaOrderForm items={mangas} id={id} />;
}

export default UpdateMangaOrder;
