import React from 'react';
import { useParams } from 'react-router-dom';
import ManageMyManga from './ManageMyManga';
import UserLayout from '../../../components/Layout/UserLayout';
import { manageMyManga } from '../../../urls';

export default function () {
  const params = useParams();
  const { page } = params;
  return (
    <UserLayout path={manageMyManga}>
      <ManageMyManga page={Number.parseInt(page, 10) || 1} />
    </UserLayout>
  );
}
