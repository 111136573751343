import Link from '@ttg-media/components/build/Link';
import React, { useState } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import Apps from '@material-ui/icons/Apps';
import { admin, compileUrl, manageAdminMangaFilters } from '../../urls';

export default function () {
  const [anchorEl, setAnchorEl] = useState();
  const handleClick = (e) => setAnchorEl(e.target);
  const handleClose = () => setAnchorEl(null);
  return (
    <>
      <IconButton
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        size="small"
      >
        <Apps />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={handleClose}
          component={Link}
          to={compileUrl(admin.home)}
        >
          Admin home
        </MenuItem>
        <MenuItem
          onClick={handleClose}
          component={Link}
          to={compileUrl(admin.manageManga, {
            filter: manageAdminMangaFilters.unreview,
          })}
        >
          Truyện tranh
        </MenuItem>
        <MenuItem
          onClick={handleClose}
          component={Link}
          to={compileUrl(admin.listUser)}
        >
          Thành viên
        </MenuItem>
      </Menu>
    </>
  );
}
