import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@ttg-media/components/build/Link';
import makeStyles from '@material-ui/styles/makeStyles';
import Home from '@material-ui/icons/HomeRounded';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles((theme) => ({
  link: {
    display: 'flex',
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
}));

export default function (props) {
  const classes = useStyles();

  const { breadcrumbs, title, right, children } = props;
  return (
    <Box mb={4}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <div>
          {breadcrumbs && (
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              <IconButton
                size="small"
                component={Link}
                to="/"
                className={classes.link}
              >
                <Home />
              </IconButton>
              {breadcrumbs}
            </Breadcrumbs>
          )}
          <Typography component="h1" variant="h6" gutterBottom>
            {title}
          </Typography>
          <Divider />
        </div>
        {right}
      </Box>
      {children}
    </Box>
  );
}
