import React from 'react';
import { useQuery } from '@apollo/client';
import _take from 'lodash/take';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Link from '@ttg-media/components/build/Link';
import { Covers } from '../home/Covers';
import handleError from '../../components/handleError';
import GET_LONG_STORIES from '../list/GET_LONG_STORIES';

function TagCarouselItem({ url, title, filter }) {
  const { data, error, loading } = useQuery(GET_LONG_STORIES, {
    variables: {
      filter,
    },
  });
  const alt = handleError({ error, loading });
  if (alt) return alt;
  const firstMangas = _take(data.longStories.items, 3);
  const coverImgList = firstMangas.map((m) => m.cover.src);
  return (
    <Box pr={1}>
      <Card>
        <CardMedia>
          <Covers covers={coverImgList} />
        </CardMedia>
        <CardActionArea component={Link} to={url}>
          <CardContent>
            <Typography variant="h6" align="center">
              {title}
            </Typography>
            <Typography color="textSecondary" align="center">
              <small>Tiếng Việt</small> &middot;{' '}
              <small>{data.longStories.total} truyện</small>
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Box>
  );
}
export default TagCarouselItem;
