/* eslint-disable react/prop-types, jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events */
// @flow
import React from 'react';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';

export default function (props) {
  const { images, onChange } = props;
  function onClick(index) {
    const item = images[index];
    onChange(item.value);
  }

  return (
    <List style={{ maxHeight: 400, overflow: 'auto' }}>
      {images.map((image, index) => {
        const selection = props.value;
        const selected = selection === image.value;
        return (
          <ListItem
            key={image.src}
            onClick={() => onClick(index)}
            dense
            button
            selected={selected}
          >
            <ListItemAvatar>
              <Avatar
                src={image.src}
                alt=""
                onClick={() => onClick(index)}
                variant="rounded"
              />
            </ListItemAvatar>
            <ListItemText
              primary={`Trang ${index + 1}`}
              secondary={
                <Typography variant="caption" color="textSecondary">
                  {image.label}
                </Typography>
              }
            />
            <ListItemSecondaryAction>
              <Checkbox
                id={`checkbox-${index}`}
                edge="end"
                checked={selected}
                color="primary"
                onChange={() => onClick(index)}
                inputProps={{
                  'data-testid': `checkbox-${index}`,
                }}
              />
            </ListItemSecondaryAction>
          </ListItem>
        );
      })}
    </List>
  );
}
