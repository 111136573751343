import React from 'react';
import TextArea from '@ttg-media/components/build/FormControls/TextArea';
import { Field, reduxForm } from 'redux-form';
import Select from '@ttg-media/components/build/FormControls/Select';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import InputField from '@ttg-media/components/build/FormControls/InputField';

const errorTypes = [
  {
    key: 'loi-trang-truyen-mot-phan',
    label: 'Lỗi trang truyện (một phần)',
  },
  {
    key: 'loi-trang-truyen-toan-bo',
    label: 'Lỗi trang truyện (toàn bộ)',
  },
  { key: 'loi-thu-tu-trang', label: 'Lỗi thứ tự trang' },
  { key: 'truyen-trung', label: 'Truyện reup' },
  { key: 'khac', label: 'Khác' },
];
function Form(props) {
  const { handleSubmit, pristine, submitting } = props;
  return (
    <form onSubmit={handleSubmit} name="mangaReport">
      <Field
        name="errorType"
        label="Loại lỗi"
        required
        items={errorTypes}
        component={Select}
      />
      <Field name="description" label="Miêu tả lỗi" component={TextArea} />
      <Field
        name="email"
        label="Địa chỉ email"
        type="text"
        helperText="Nếu bạn đã đăng nhập, hệ thống sẽ tự động lấy email đã đăng nhập"
        component={InputField}
      />
      <Box display="flex" justifyContent="flex-end">
        <Button
          variant="contained"
          type="submit"
          color="primary"
          disabled={pristine || submitting}
          endIcon={submitting ? <CircularProgress size={20} /> : <span />}
        >
          Gửi
        </Button>
      </Box>
    </form>
  );
}

export default reduxForm({
  form: 'REPORT_MANGA_ERROR_FORM',
})(Form);
