import React from 'react';
import Container from '@material-ui/core/Container';
import { useQuery } from '@apollo/client';
import Box from '@material-ui/core/Box';
import _omitBy from 'lodash/omitBy';
import _isNil from 'lodash/isNil';
import Chip from '@material-ui/core/Chip';
import { useLocation } from 'react-router-dom';
import { parse } from 'query-string';
import { mangaListUrl } from '../../urls';
import PageHead from '../../components/PageHead';
import LongStoryListPage from './components/LongStoryListPage';
import GET_LONG_STORIES from './GET_LONG_STORIES';
import handleError from '../../components/handleError';
import { PAGE_SIZE } from '../../constants';

const detectUtmSource = (filter) => {
  if (filter.category) return 'category';
  if (filter.tag) return 'tag';
  if (filter.author) return 'author';
  if (filter.sort === 'hot') return 'hot';
  if (filter.status) return `status-${filter.status}`;
  return null;
};

function getUtmListing(filter, data, page) {
  const pagerInfo = {
    total_items: data.longStories.total,
    total_pages: Math.ceil(data.longStories.total / PAGE_SIZE),
    current_page: page,
    offset: (page - 1) * PAGE_SIZE + 1,
  };
  if (filter.author) {
    return {
      ...pagerInfo,
      listing_id: `author-${filter.author}`,
      listing_type: 'author',
      listing_item_id: filter.author,
    };
  }
  if (filter.tag) {
    return {
      ...pagerInfo,
      listing_id: `tag-${filter.tag}`,
      listing_type: 'tag',
      listing_item_id: filter.tag,
    };
  }
  return null;
}

function MangaListPage() {
  const location = useLocation();
  const query = parse(location.search);
  const { sort, status, translator, author, tag, userName, category } = query;
  const page = Number.parseInt(query.page, 10) || 1;
  const filter = _omitBy(
    {
      category,
      translator: parseInt(translator, 10) || null,
      author: parseInt(author, 10) || null,
      tag: parseInt(tag, 10) || null,
      status,
      sort,
      userName,
      page,
    },
    _isNil,
  );
  const { data, error, loading } = useQuery(GET_LONG_STORIES, {
    variables: {
      filter,
    },
  });

  return (
    handleError({ error, loading }) || (
      <Box>
        <Container>
          <PageHead title="Truyện tranh" />
          <Box mb={1}>
            {Object.keys(filter).map((key) => (
              <Box component="span" mr={0.5}>
                <Chip label={`${key} | ${filter[key]}`} size="small" />
              </Box>
            ))}
          </Box>
        </Container>
        <LongStoryListPage
          data={data}
          page={page}
          utmListing={getUtmListing(filter, data, page)}
          utmSource={detectUtmSource(filter)}
          url={(p) => mangaListUrl({ ...filter, page: p })}
        />
      </Box>
    )
  );
}

export default MangaListPage;
