import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';

export const TOAST_MESSAGES = {
  pending: 'Đang xử lý...',
  success: 'Thành công. Vui lòng tải lại trang để cập nhật',
  error: { render: ({ data }) => `Lỗi ${data.message}` },
};
export const toastify = (promise) => toast.promise(promise, TOAST_MESSAGES);
export const useToastMutation = (...args) => {
  const [originalMutation, ...rest] = useMutation(...args);
  const toastedMutation = (...mutationArgs) =>
    toastify(originalMutation(...mutationArgs));
  return [toastedMutation, ...rest];
};
