import _findIndex from 'lodash/findIndex';

const getPrevNextChapter = function (longStory, manga) {
  const index = _findIndex(longStory.mangas, (m) => m.lid === manga.lid);
  if (index > -1) {
    const nextChapter =
      index + 1 < longStory.mangas.length ? longStory.mangas[index + 1] : null;
    const prevChapter = index - 1 >= 0 ? longStory.mangas[index - 1] : null;
    return { prevChapter, nextChapter, chapter: index === -1 ? null : index };
  }
  return {};
};
export default getPrevNextChapter;
