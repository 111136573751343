import React from 'react';
import gql from 'graphql-tag';
import { admin, compileUrl } from '../../urls';
import history from '../../myHistory';
import { search } from '../user/post-manga/MangaForm/actions';
import { SearchBox } from '../../components/SearchBox';

const SEARCH_USERS = gql`
  query SearchUsers($term: String!) {
    items: usersSearch(term: $term) {
      id
      slug
      userName
      email
    }
  }
`;
const handleChange = ({ value: id }) =>
  history.push(compileUrl(admin.listUser, null, `?user=${id}`));
const onSearch = (term) =>
  search({
    term,
    query: SEARCH_USERS,
    mapper: (u) => ({
      value: u.id,
      label: `${u.userName} ${u.email}`,
    }),
  });

export function UserSearchBox() {
  return <SearchBox onChange={handleChange} onSearch={onSearch} />;
}
