import React from 'react';
import { Route } from 'react-router-dom';
import ToS from './terms-of-service';
import url from './miscUrls';
import { searchPage, searchPageEng } from '../../urls';
import Privacy from './privacy-policy';
import Dcma from './dcma-notice';
import ContactPage from './ContactPage';
import NotificationPage from './NotificationPage';
import SearchPage from '../search/SearchPage';

export default [
  <Route element={<SearchPage />} path={searchPageEng} />,
  <Route element={<SearchPage />} path={searchPage} />,
  <Route element={<ToS />} path={url.termOfService} />,
  <Route element={<Privacy />} path={url.privacyPolicy} />,
  <Route element={<Dcma />} path={url.dcmaNotice} />,
  <Route element={<ContactPage />} path={url.contactPage} />,
  <Route element={<NotificationPage />} path={url.notificationsPage} />,
];
