import React from 'react';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import HandleError from '@ttg-media/components/build/HandleError';
import { MangaParts } from '../../gql/fragments';
import MangaListGroup from '../../components/MangaListGroup/MangaListGroup';
import { MangaListGroupLoading } from '../../components/MangaListGroup/MangaListGroupLoading';

const REVIVED_MANGA = gql`
  query RevivedManga {
    mangas: revivedManga {
      total
      mangas {
        ...MangaParts
      }
    }
  }
  ${MangaParts}
`;
export default function () {
  const { data, loading, error } = useQuery(REVIVED_MANGA);
  if (loading || error)
    return (
      <HandleError
        loading={loading}
        error={error}
        placeholder={<MangaListGroupLoading />}
      />
    );
  return (
    <MangaListGroup
      mangaList={data.mangas}
      name="revived"
      variant="carousel"
      page={1}
      limit={12}
      utmSource="home_revived"
    />
  );
}
