import { compile } from 'path-to-regexp';
import querystring from 'query-string';
import _compact from 'lodash/compact';
import _pickBy from 'lodash/pickBy';
import longStoryUrls from './modules/longStory/routes/longStoryUrls';

export { default as miscUrls } from './pages/misc/miscUrls';

export const homePage = '/';
export const categoryPage = '/:slug(truyen-tranh-dam-my)';
export const mangaDetailsPage = `/truyen-tranh/chi-tiet/:id/:slug`;
// export const mangaDetailsPage = `/truyen-tranh/:slug-:id`;
export const mangaDetailsPageWithParts = `/truyen-tranh/chi-tiet/:id/:slug/:part`;
// export const mangaDetailsPageWithParts = `/truyen-tranh/:slug-:id(\\d+)/:part(\\d+)`;
export const CategorySlugs = {
  truyenTranhDamMy: 'truyen-tranh-dam-my',
};
export const editMangaPage = `/truyen-tranh/edit/:id/abc`;
export const mangaEditPage = `/truyen-tranh/edit/:id/edit`;
export const mangaStatusPage = `/truyen-tranh/edit/:id/status`;
export const mangaEditPageOrder = `/truyen-tranh/edit/:id/order-page`;
export const mangaAddPage = `/truyen-tranh/edit/:id/add-page`;
export const mangaEditCover = `/truyen-tranh/edit/:id/edit-cover`;
export const mangaPreview = `/truyen-tranh/edit/:id/preview`;
export const searchPage = '/tim-kiem';
export const abTestingPage = '/ab-testing';
export const searchPageEng = '/search';

export const mangaList = `/truyen-tranh/search`;
export const mangaHomeRoute = `/truyen-tranh/new/:page`;

export const authorListPage = `/tac-gia`;
export const authorDetailsPage = '/tac-gia/:id';
export const tagListPage = `/tag`;
export const tagDetailsPage = `/tag/:id`;
export const postMangaPage = '/dang-truyen';
export const ttdm = '/truyen-tranh-dam-my';

export const favouriteRoute = `/truyen-da-luu/:slug/:page`;

export const manageProfile = {
  changePassword: '/doi-mat-khau/:id',
  edit: '/quan-ly-tai-khoan/:id',
};
export const manageMyManga = `/user/my-manga/:page`;
export const managePageviews = `/user/pageview`;
export const admin = {
  home: '/admin',
  manageManga: `/admin/manage/manga/:filter/:page`,
  listUser: `/admin/list/u/page/:page`,
  listManga: '/admin/list/manga',
};
export const translatorRoute = {
  edit: '/nhom-dich/:id/sua-thong-tin',
  list: '/nhom-dich',
};
export const longStoryRoute = {
  create: longStoryUrls.create,
  edit: longStoryUrls.edit,
  details: longStoryUrls.details,
  list: longStoryUrls.list,
};
export const manageAdminMangaFilters = {
  all: 'all',
  reviewed: 'reviewed',
  unreview: 'unreview',
  deleted: 'deleted',
};
function queryStringify(query) {
  const sanitizedQuery = _pickBy(query, (v) => v !== null);
  return querystring.stringify(sanitizedQuery);
}
// eslint-disable-next-line default-param-last
export function compileUrlQuery(route, args = {}, query) {
  const params = {
    page: 1,
    ...args,
  };
  try {
    return `${compile(route)(params)}?${queryStringify(query)}`;
  } catch (error) {
    if (__DEV__) {
      // eslint-disable-next-line no-console
      console.log('Error when compile url', error.message, route, args);
      throw error;
    }
    return '#';
  }
}
export function tagListUrl({ page } = { page: 1 }) {
  return compileUrlQuery(tagListPage, null, { page });
}

export function authorListUrl({ page } = { page: 1 }) {
  return compileUrlQuery(authorListPage, null, { page });
}
// eslint-disable-next-line default-param-last
export function compileUrl(route, args = {}, plus) {
  const params = {
    page: 1,
    ...args,
  };
  try {
    return `${compile(route)(params)}${plus || ''}`;
  } catch (error) {
    if (process.env.NODE_END !== 'production') {
      error.message = `${
        error.message
      }. Route ${route}. Params ${JSON.stringify(args)}`;
      throw error;
    }
    return '#';
  }
}

export const mangaDetailsUrl = (manga, opts) => {
  const { utmSource, utmListing } = opts || {};
  const q = queryStringify({
    from: utmSource,
    listing_id: utmListing?.listing_id,
    experiment_id: utmListing?.experiment_id,
    variant: utmListing?.variant,
  });
  return _compact([`${compileUrl(mangaDetailsPage, manga)}`, q]).join('?');
};

export const datingWebsite = {
  homePage: 'https://ketban.truyentranhgay.com/',
  dating: (user) => {
    const datingDetailsRoute = `/user/:id/:userName`;
    const url = compileUrl(datingDetailsRoute, user);
    return `https://ketban.truyentranhgay.com${url}`;
  },
};

export const longStoryDetailsUrl = (longStory) =>
  compileUrl(longStoryRoute.details, longStory);
export const longStoryHomeUrl = (page) =>
  compileUrl(longStoryRoute.home, { page });

export function categoryPageUrl({ slug, page }) {
  return `${compileUrl(categoryPage, { slug })}?${queryStringify({ page })}`;
}
export function tagDetailsUrl(params, query) {
  return `${compileUrl(tagDetailsPage, params)}?${queryStringify(query)}`;
}

// const TAGS = {
//   shota: 311,
// };
export function mangaListUrl(opts) {
  if (opts.tag) {
    return tagDetailsUrl({ id: opts.tag });
  }

  return `${compileUrl(mangaList)}?${queryStringify(opts)}`;
}

export function authorDetailsUrl(params, query) {
  // if (params.id === 6)
  return `${compileUrl(authorDetailsPage, params)}?${queryStringify(query)}`;
  // return mangaListUrl({ author: params.id });
}
