import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import React from 'react';
import Link from '@ttg-media/components/build/Link';
import Typography from '@material-ui/core/Typography';
import { mangaListUrl } from '../../../../urls';

export function TagsList({ tags, variant }) {
  return (
    <Breadcrumbs maxItems={Number.MAX_VALUE}>
      {tags &&
        tags.map((tag) => (
          <Link
            key={tag.slug}
            to={mangaListUrl({ tag: tag.id })}
            label={tag.name}
            color="primary"
          >
            <Typography variant={variant}>{tag.name}</Typography>
          </Link>
        ))}
    </Breadcrumbs>
  );
}
