import React from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import HandleError from '@ttg-media/components/build/HandleError';
import ErrorBoundary from '@ttg-media/components/build/hoc/SentryBoundary';
import Title from '@ttg-media/components/build/Title';
import { MangaParts } from '../../../gql/fragments';
import ProfileWidget from '../../../components/ProfileWidget/ProfileWidget';

import MangaListGroup from '../../../components/MangaListGroup/MangaListGroup';

const GET_USER_RECOMMENDATIONS = gql`
  query userRecommendations($user: ID!) {
    userRecommendations(user: $user) {
      items {
        ...MangaParts
      }
    }
  }
  ${MangaParts}
`;

export default function ({ user }) {
  const { loading, data, error } = useQuery(GET_USER_RECOMMENDATIONS, {
    variables: { user: user.lid },
  });
  if (loading || error) return <HandleError loading={loading} error={error} />;
  return (
    <div>
      <Title>
        Truyện cùng thành viên <ProfileWidget user={user} />
      </Title>
      <ErrorBoundary>
        <MangaListGroup
          mangaList={{ mangas: data.userRecommendations.items }}
          utmSource="uploader-reco"
          utmListing={{
            total_items: data.userRecommendations.items.length,
            total_pages: 1,
            current_page: 1,
            offset: 1,
            listing_id: `uploader-reco-${user.id}`,
            listing_type: 'uploader-reco',
            listing_item_id: user.id,
          }}
        />
      </ErrorBoundary>
    </div>
  );
}
