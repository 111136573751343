// @flow

import { ApolloClient, from } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { HttpLink } from '@apollo/client/link/http';
import { setContext } from '@apollo/client/link/context';
import {
  getItem,
  removeItem,
} from '@ttg-media/components/build/auth/AuthContext/localStorageClient';
import createCache from './createCache';

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = getItem('ttg:auth')?.id_token;
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});
const link = from([
  onError(async ({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.map(({ message, locations, path }) =>
        console.warn(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        ),
      );

    if (networkError) {
      if (networkError.status === 401 || networkError.statusCode === 401) {
        removeItem('ttg:auth');
        removeItem('ttg:dating:v2:auth');
      }

      console.warn(`[Network error]: ${networkError}`);
    }
  }),
  authLink,
  new HttpLink({
    uri: '/graphql',
    credentials: 'include',
  }),
]);

const cache = createCache();

export default function createApolloClient() {
  return new ApolloClient({
    link,
    cache: cache.restore(),
    queryDeduplication: true,
    connectToDevTools: true,
  });
}
