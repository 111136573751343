import { convertSelectedToObj } from '../../../helpers';

export function convertFormValueToGqlInput(values) {
  return {
    title: values.title,
    // englishTitle: values.englishTitle,
    // chapter: Number.parseInt(values.chapter || 1, 10),
    description: values.description,
    // source: values.source,
    categorySlug: values.category,
    languageSlug: values.language,
    translator: convertSelectedToObj(values.translator),
    authors: values.authors?.map(convertSelectedToObj),
    tags: values.tags?.map(convertSelectedToObj),
    longStory: convertSelectedToObj(values.longStory),
  };
}
