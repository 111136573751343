import React from 'react';
import Typography from '@material-ui/core/Typography';
import Link from '@ttg-media/components/build/Link';
import { RequireOwner } from '@ttg-media/components/build/auth';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Markdown from '@ttg-media/components/build/Markdown';
import PageHead from '@ttg-media/components/build/PageHead';
import LongStory from './LongStory';
import {
  compileUrl,
  longStoryDetailsUrl,
  mangaListUrl,
  translatorRoute,
} from '../../../../urls';
import ProfileWidget from '../../../../components/ProfileWidget/ProfileWidget';
import { formatDate } from '../../../../utils';
import MangaStats from '../../../../components/MangaListGroup/MangaStats';
import { EditMangaLinkButton } from './EditMangaLinkButton';
import { Row } from './Row';
import { AuthorsList } from './AuthorsList';
import { TagsList } from './TagsList';

function MangaInfo(props) {
  const { manga, variant, longStory } = props;
  const isCompact = variant === 'compact';
  const {
    title,
    createdDate,
    source,
    tags,
    description,
    createdBy,
    id,
    authors,
    translator,
  } = manga;
  const displayHeader = !isCompact;
  return (
    <Container maxWidth="sm">
      <Box display="flex" flexDirection="column">
        {displayHeader && (
          <div>
            <PageHead
              title={title}
              secondary={
                <div>
                  <Typography variant="caption" color="textSecondary">
                    đăng bởi <ProfileWidget user={createdBy} /> cập nhật{' '}
                    {formatDate(createdDate)}
                  </Typography>
                  <div>
                    <EditMangaLinkButton id={id} createdBy={createdBy} />
                  </div>
                </div>
              }
              breadcrumbs={[
                longStory && (
                  <Link to={longStoryDetailsUrl(longStory)}>
                    {longStory.title}
                  </Link>
                ),
              ]}
            />
            <Box mb={1}>
              <Markdown source={description} />
              <MangaStats more hidePage manga={manga} />
            </Box>
          </div>
        )}
        <Box mb={2}>
          {translator && (
            <Row label="Nhóm dịch">
              <Link
                to={mangaListUrl({ translator: translator.id })}
                color="primary"
              >
                {translator.name}
              </Link>
              <RequireOwner id={createdBy.id}>
                <IconButton
                  size="small"
                  component={Link}
                  to={compileUrl(translatorRoute.edit, translator)}
                >
                  <EditIcon />
                </IconButton>
              </RequireOwner>
            </Row>
          )}
          {source && <Row label="Nguồn">{source}</Row>}
          {authors?.length > 0 && (
            <Row label="Tác giả">
              <AuthorsList authors={authors} />
            </Row>
          )}
          {tags?.length > 0 && (
            <Row label="Tag">
              <TagsList tags={tags} />
            </Row>
          )}
          {longStory && (
            <Row label="Truyện dài">
              <LongStory longStory={longStory} manga={manga} />
            </Row>
          )}
        </Box>
      </Box>
    </Container>
  );
}

export default MangaInfo;
