/* eslint-disable import/no-import-module-exports */
import { BrowserRouter } from 'react-router-dom';
import React from 'react';
import { hydrate } from 'react-dom';
import { ApolloProvider } from '@apollo/client';
// eslint-disable-next-line import/no-relative-packages
import createApolloClient from './core/createApolloClient';
import App from './App';

const client = createApolloClient();
hydrate(
  <BrowserRouter>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </BrowserRouter>,
  document.querySelector('#root'),
);

if (module.hot) {
  module.hot.accept();
}
