import React from 'react';
import gql from 'graphql-tag';
import Button from '@material-ui/core/Button';
import { useToastMutation } from '../../../../components/toastify';

export default function ({ id }) {
  const [mutate] = useToastMutation(
    gql`
      mutation UpdateLongStoryCache($id: ID!) {
        uploadLongStoriesCache(id: $id)
      }
    `,
    { variables: { id } },
  );
  return <Button onClick={mutate}>Cập nhật cache</Button>;
}
