import React from 'react';
import withError from '@ttg-media/components/build/hoc/withError';

import { GridLayout } from './layouts/GridLayout';
import LongStoryListItem from './LongStoryListItem';

function LongStoryListGroup(props) {
  const { items, utmSource, utmListing } = props;
  return (
    <GridLayout
      data={items}
      makeKey={(i) => i.id}
      render={(item) => (
        <LongStoryListItem
          longStory={item}
          utmSource={utmSource}
          utmListing={utmListing}
        />
      )}
    />
  );
}

const Component = withError()(LongStoryListGroup);
Component.fragments = {
  LongStoryParts: LongStoryListItem.fragments.LongStoryParts,
};
export default Component;
