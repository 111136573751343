// @flow
import React from 'react';
import { useMutation } from '@apollo/client';
import { ButtonAction } from '../../../../components/ButtonAction';
import { TriggerMangaPostedEvent } from '../gql';

export default function TriggerMangaPostedEventButton(props) {
  const mutation = useMutation(TriggerMangaPostedEvent, {
    variables: { manga: props.manga.id },
  });
  return <ButtonAction mutation={mutation}>Trigger posted event</ButtonAction>;
}
