import React from 'react';
import Link from '@ttg-media/components/build/Link';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import {
  compileUrl,
  longStoryDetailsUrl,
  mangaDetailsPage,
} from '../../../../urls';
import getPrevNextChapter from './getPrevNextChapter';

export default function LongStory(props) {
  const { longStory, manga } = props;
  const { nextChapter, prevChapter } = getPrevNextChapter(longStory, manga);
  return (
    <>
      <Link to={longStoryDetailsUrl(longStory)} color="primary">
        <Typography>{longStory.title}</Typography>
      </Link>
      <Breadcrumbs separator=" ">
        <Link
          to={prevChapter && compileUrl(mangaDetailsPage, prevChapter)}
          disabled={!prevChapter}
          color={prevChapter ? 'primary' : 'disabled'}
        >
          ❮ Chương trước
        </Link>
        <Link
          disabled={!nextChapter}
          to={nextChapter && compileUrl(mangaDetailsPage, nextChapter)}
          color={nextChapter ? 'primary' : 'disabled'}
        >
          Chương sau ❯
        </Link>
      </Breadcrumbs>
    </>
  );
}
