import React from 'react';
import Button from '@material-ui/core/Button';
import { useMutation } from '@apollo/client';
import withNotify from '@ttg-media/components/build/hoc/withNotify';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';

export default withNotify()((props) => {
  const Com = props.component || Button;
  const [mutate, { loading }] = useMutation(props.mutation, {
    variables: props.variables,
  });
  const onClick = () => props.exec(mutate());
  return (
    <Com {...props} onClick={onClick}>
      {props.children}
      {loading && (
        <Box ml={1}>
          <CircularProgress size={20} />
        </Box>
      )}
    </Com>
  );
});
