import gql from 'graphql-tag';

export const SEARCH_TRANSLATOR = gql`
  query TranslatorSearch($term: String!) {
    items: translatorSearch(term: $term) {
      value: id
      label: name
    }
  }
`;
