// @flow
import React, { useState } from 'react';
import { compose } from 'redux';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import withConfig from '@ttg-media/components/build/hoc/withConfig';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CircularProgress from '@material-ui/core/CircularProgress';
import withError from '@ttg-media/components/build/hoc/withError';
import { useLazyQuery } from '@apollo/client';
import PageItem from './PageItem';
import GET_MANGA_ALT_PAGES from '../gqlGetMangaAltPages';
import { ServerChooser } from './ServerChooser';
import { Empty } from './Empty';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingLeft: 2,
    paddingRight: 2,
  },
});

function PagesComponent(props) {
  const { manga } = props;
  const css = useStyles();

  const [pages, setPages] = useState(props.manga.pages);
  const [getPages, { loading }] = useLazyQuery(GET_MANGA_ALT_PAGES, {
    onCompleted: (data) => setPages(data.pages),
    fetchPolicy: 'no-cache',
  });
  const onImageServerChange = (server) => {
    getPages({
      variables: { manga: manga.id, server },
    });
  };

  if (props.manga.pages?.length === 0) return <Empty />;

  return (
    <div>
      <Box display="flex" justifyContent="space-around" alignItems="center">
        <span>
          <Typography variant="caption" color="textSecondary">
            Server ảnh
          </Typography>{' '}
          <ServerChooser
            onChange={onImageServerChange}
            options={[
              { value: 0, label: 'Tự động' },
              { value: 1, label: 'Server 1' },
              { value: 2, label: 'Server 2' },
            ]}
          />
        </span>
      </Box>
      {loading ? (
        <Box display="flex" justifyContent="space-around">
          <CircularProgress />
        </Box>
      ) : (
        <List>
          {pages.map((page) => (
            <ListItem key={page.order} className={css.container}>
              <PageItem page={page} title={manga.title} config={props.config} />
            </ListItem>
          ))}
        </List>
      )}
    </div>
  );
}

export default compose(withError(), withConfig())(PagesComponent);
