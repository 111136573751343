import React, { useState } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { useParams } from 'react-router-dom';
import HandleError from '@ttg-media/components/build/HandleError';
import ImagePicker from '../../../../components/ImagePicker';
import { ButtonAction } from '../../../../components/ButtonAction';
import EditMangaLayout from '../EditMangaLayout';
import { mangaEditCover } from '../../../../urls';
import useMangaForEdit from '../useMangaForEdit';

const changeMangaCover = gql`
  mutation changeMangaCover($mangaId: Int!, $pageId: String!) {
    changeCover(mangaId: $mangaId, pageId: $pageId) {
      id
    }
  }
`;
function ChangeMangaCoverComponent() {
  const [cover, setCover] = useState(null);
  const [pages, setPage] = useState([]);
  const params = useParams();
  const id = parseInt(params.id, 10);
  const { loading, error } = useMangaForEdit({
    onCompleted: (d) => {
      setCover(d.manga.cover);
      setPage(d.manga.pages);
    },
  });

  const mutation = useMutation(changeMangaCover, {
    variables: {
      mangaId: id,
      pageId: cover,
    },
  });

  if (loading || error) return <HandleError loading={loading} error={error} />;

  return (
    <EditMangaLayout route={mangaEditCover}>
      <Card>
        <CardContent>
          <Typography>Hãy chọn một trang làm ảnh bìa mới</Typography>
        </CardContent>
        <ImagePicker
          images={pages.map((page) => ({
            src: page.src,
            value: page.id,
          }))}
          value={cover}
          singleImage
          onChange={setCover}
        />
        <CardActions>
          <ButtonAction mutation={mutation} />
        </CardActions>
      </Card>
    </EditMangaLayout>
  );
}

export default ChangeMangaCoverComponent;
