import React from 'react';
import { useQuery } from '@apollo/client';
import Select from '@ttg-media/components/build/FormControls/Select';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import HandleError from '@ttg-media/components/build/HandleError';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { FormProvider, useForm } from 'react-hook-form';
import InputFormField from '@ttg-media/components/build/FormControls/InputFormField';
import AsyncCreatableSelectFormField from '@ttg-media/components/build/FormControls/AsyncCreatableSelectFormField';
import SelectFormField from '@ttg-media/components/build/FormControls/SelectFormField';
import fetchDataFormQuery from '../../../pages/user/post-manga/MangaForm/gql/fetchFormDataGql';
import {
  convertToOptions,
  searchAuthors,
  searchMangas,
  searchTags,
  searchTranslators,
} from '../../../pages/user/post-manga/MangaForm/actions';
import { convertSelectedToObj } from '../../../pages/helpers';

export function pick(i) {
  if (!i) return null;
  return {
    label: i.label,
    value: String(i.value),
  };
}

function LongStoryForm(props) {
  const { onSubmit, initialValues } = props;
  const form = useForm({ defaultValues: initialValues });
  const { data, loading, error } = useQuery(fetchDataFormQuery);
  if (loading || error) return <HandleError loading={loading} error={error} />;
  const { categories, languages } = data;
  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Box>
          <Box>
            <Typography gutterBottom variant="h6">
              Thông tin chung
            </Typography>
          </Box>
          <InputFormField name="id" type="text" label="Id" required />
          <InputFormField
            name="title"
            type="text"
            label="Tên truyện dài"
            required
          />
          <AsyncCreatableSelectFormField
            name="mangas"
            type="text"
            label="Danh sách chương"
            required
            multi
            loadOptions={searchMangas}
            disableCreate
            defaultOptions
          />
          <SelectFormField
            name="category"
            type="text"
            label="Thể loại"
            required
            loading={loading}
            inline
            items={convertToOptions(categories)}
            component={Select}
          />
          <SelectFormField
            name="language"
            type="text"
            label="Ngôn ngữ"
            required
            loading={loading}
            inline
            items={convertToOptions(languages)}
            component={Select}
          />
          <InputFormField
            name="description"
            label="Miêu tả nội dung truyện"
            multiline
            row={4}
          />
        </Box>
        <Box pt={2}>
          <Divider />
          <Box>
            <Typography variant="h6" gutterBottom>
              Phân loại
            </Typography>
          </Box>
          <Box>
            <AsyncCreatableSelectFormField
              name="translator"
              type="text"
              label="Nhóm dịch"
              required
              loadOptions={searchTranslators}
            />
            <AsyncCreatableSelectFormField
              name="authors"
              type="text"
              label="Tác giả"
              required
              multi
              loadOptions={searchAuthors}
            />

            <AsyncCreatableSelectFormField
              name="tags"
              label="Tag"
              required
              multi
              loadOptions={searchTags}
            />
          </Box>
        </Box>

        <Box display="flex" justifyContent="flex-end">
          <Button variant="contained" type="submit" color="primary">
            Lưu
          </Button>
        </Box>
      </form>
    </FormProvider>
  );
}

export const convertFromValues = (values) => ({
  title: values.title,
  otherTitles: (values.otherTitles || '').split('\n').map((t) => t.trim()),
  description: values.description,
  source: values.source,
  categorySlug: values.category,
  languageSlug: values.language,
  translator: convertSelectedToObj(values.translator),
  authors: values.authors?.map((a) => convertSelectedToObj(a)),
  tags: values.tags?.map((t) => convertSelectedToObj(t)),
  mangas: values.mangas?.map((m) => convertSelectedToObj(m)),
});

export const CreateLongStoryForm = LongStoryForm;
export const EditLongStoryForm = LongStoryForm;
export default LongStoryForm;
