import gql from 'graphql-tag';

export const MangaParts = gql`
  fragment MangaParts on Manga {
    id
    lid
    slug
    title
    displayName
    url
    reviewedDate
    isNew
    isDeleted
    isReviewed
    stats {
      viewsCount
      commentsCount
      pagesCount
      likesCount
      downloadsCount
    }
    longStory {
      id
      lid
      slug
      name
      isReviewed
      isDeleted
    }
    createdDate
    cover {
      src
    }
  }
`;

export const LongStoryParts = gql`
  fragment LongStoryParts on LongStory {
    id
    lid
    slug
    title
    displayName
    reviewedDate
    isNew
    createdDate
    cover {
      src
    }
    latestChapters {
      id
      slug
      chapter
      displayName
      format {
        id
        slug
        name
      }
      updatedDate
      stats {
        viewsCount
        commentsCount
        pagesCount
        likesCount
        downloadsCount
      }
    }
  }
`;
export const MangaDetailsParts = gql`
  fragment MangaDetailsParts on Manga {
    ...MangaParts
    lid
    description
    altTitles
    englishTitle
    language {
      id
      slug
      name
    }
    format {
      id
      slug
      name
    }
    pages {
      id
      order
      src
      fileName
      srcSet {
        id
        type
        srcSet
      }
    }
    createdBy {
      id
      lid
      displayName
      userName
      avatar {
        id
        src
      }
    }
    authors {
      id
      lid
      slug
      name
    }
    tags {
      id
      lid
      slug
      name
    }
    category {
      id
      slug
      name
    }
    translator {
      id
      slug
      name
      about
      homePage
      facebook
      email
      createdBy {
        id
        lid
      }
    }
    canDownload
    updatedDate
    longStory {
      id
      lid
      name
      slug
    }
  }
  ${MangaParts}
`;
