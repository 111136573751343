import React from 'react';
import Link from '@ttg-media/components/build/Link';
import Button from '@material-ui/core/Button';
import gql from 'graphql-tag';
import Box from '@material-ui/core/Box';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Container from '@material-ui/core/Container';
import Title from '@ttg-media/components/build/Title';
import LazyLoad from 'react-lazyload';
import Grid from '@material-ui/core/Grid';
import { useQuery } from '@apollo/client';
import HandleError from '@ttg-media/components/build/HandleError';

import Banners from '@ttg-media/blog/build/react/Banners';
import {
  authorListUrl,
  compileUrl,
  mangaHomeRoute,
  mangaListUrl,
  tagListUrl,
} from '../../urls';
import Cards from '../../components/Statuses/Cards';
import HotTags from './HotTags';
import HotAuthors from './HotAuthors';
import RevivedManga from './RevivedManga';
import Section from './Section';
import LoadingCard from './LoadingCard';
import { MangaListGroupLoading } from '../../components/MangaListGroup/MangaListGroupLoading';
import LongStoryListGroup from '../../components/MangaListGroup/LongStoryListGroup';

const FETCH_HOME_DATA = gql`
  query FetchHomePageData($filter: MangaFilter) {
    translatedManga: longStoryList(filter: $filter) {
      items {
        ...LongStoryParts
      }
    }
  }
  ${LongStoryListGroup.fragments.LongStoryParts}
`;

export async function fetchHomeData(client) {
  const { data } = await client.query({
    query: FETCH_HOME_DATA,
    variables: {
      filter: {
        page: 1,
        limit: 12,
      },
    },
  });
  return data;
}

function HomePage() {
  const { data, loading, error } = useQuery(FETCH_HOME_DATA, {
    variables: {
      filter: {
        page: 1,
        limit: 12,
      },
    },
  });

  if (loading || error) return <HandleError loading={loading} error={error} />;
  const { translatedManga } = data;
  return (
    <Box>
      <Cards
        active="tieng-viet"
        url={(status) => mangaListUrl({ status })}
        showCount
      />
      <Container>
        <Box mt={2}>
          <Container maxWidth="sm">
            <Banners tag="home" />
          </Container>
        </Box>
        <Box my={2}>
          <Box mb={2}>
            <Title>Truyện mới cập nhật</Title>
            <LongStoryListGroup
              items={translatedManga.items}
              utmSource="home_new"
            />
          </Box>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              component={Link}
              to={compileUrl(mangaHomeRoute, { page: 2 })}
              endIcon={<ChevronRight />}
              color="primary"
            >
              Xem thêm
            </Button>
          </div>
        </Box>
        <Section
          title="Tag nhiều người xem"
          right={
            <Button
              size="small"
              component={Link}
              to={tagListUrl()}
              endIcon={<ChevronRight />}
            >
              Toàn bộ
            </Button>
          }
        >
          <LazyLoad placeholder={<LoadingCard />}>
            <HotTags />
          </LazyLoad>
        </Section>

        <Section
          title="Tác giả nổi bật"
          right={
            <Button
              size="small"
              component={Link}
              to={authorListUrl()}
              endIcon={<ChevronRight />}
            >
              Toàn bộ
            </Button>
          }
        >
          <LazyLoad placeholder={<LoadingCard />}>
            <HotAuthors />
          </LazyLoad>
        </Section>
        <Section title="Truyện nên đọc">
          <LazyLoad placeholder={<MangaListGroupLoading />}>
            <RevivedManga />
          </LazyLoad>
        </Section>
        <Grid container spacing={2}>
          <Grid item md={4} sm={12}>
            <Box display="flex" justifyContent="space-around" mb={1}>
              <iframe
                title="discord"
                src="https://discord.com/widget?id=690867637621882930&theme=dark"
                width="350"
                height="500"
                allowtransparency="true"
                frameBorder="0"
                sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
              />
            </Box>
            <Button
              component="a"
              href="https://discord.gg/zxJ5RG4yZ4"
              target="_blank"
              color="primary"
              fullWidth
              endIcon={<ChevronRight />}
            >
              Tham gia server
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default HomePage;
