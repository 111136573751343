import React from 'react';
import Box from '@material-ui/core/Box';
import Carousel from '@ttg-media/components/build/Carousel';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import Link from '@ttg-media/components/build/Link';
import Title from '@ttg-media/components/build/Title';
import { tagDetailsUrl } from '../../urls';
import TagCarouselItem from './TagCarouselItem';

const CATEGORIES = {
  bara: 'Bara',
  shota: 'Shota',
  yaoi: 'Yaoi',
  furry: 'Furry',
};
const LANGUAGES = {
  'tieng-viet': 'Truyện dịch',
};

function DisplayFilter({ language, category, id }) {
  return language || category ? (
    <Box display="flex">
      <Box mr={1}>
        <Typography>
          Đang lọc theo {language && <Chip label={LANGUAGES[language]} />}{' '}
          {category && <Chip label={CATEGORIES[category]} />}
        </Typography>
      </Box>
      <Button
        size="small"
        component={Link}
        to={tagDetailsUrl({ id }, { page: 1 })}
        variant="outlined"
      >
        Bỏ lọc
      </Button>
    </Box>
  ) : (
    <div />
  );
}
function QuickFilters({ language, category, id, tag }) {
  return (
    <Box>
      <Title>Lọc nhanh</Title>
      <Box mb={1}>
        <DisplayFilter language={language} category={category} id={id} />
      </Box>
      <Carousel total={4}>
        <TagCarouselItem
          title={`${tag.name} + Bara`}
          url={tagDetailsUrl(
            { id },
            { page: 1, category: 'bara', language: 'tieng-viet' },
          )}
          filter={{ tag: id, category: 'bara', language: 'tieng-viet' }}
        />
        <TagCarouselItem
          title={`${tag.name} + Yaoi`}
          url={tagDetailsUrl(
            { id },
            { page: 1, category: 'yaoi', language: 'tieng-viet' },
          )}
          filter={{ tag: id, category: 'yaoi', language: 'tieng-viet' }}
        />
        <TagCarouselItem
          title={`${tag.name} + Furry`}
          url={tagDetailsUrl(
            { id },
            { page: 1, category: 'furry', language: 'tieng-viet' },
          )}
          filter={{ category: 'furry', language: 'tieng-viet', tag: id }}
        />
        <TagCarouselItem
          title={tag.name}
          url={tagDetailsUrl({ id }, { page: 1, language: 'tieng-viet' })}
          filter={{ language: 'tieng-viet', tag: id }}
        />
      </Carousel>
    </Box>
  );
}
export default QuickFilters;
